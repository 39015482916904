define("flood/helpers/p/map", ["exports", "ember-primer/helpers/p/map"], function (_exports, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _map.default;
    }
  });
  Object.defineProperty(_exports, "pMap", {
    enumerable: true,
    get: function get() {
      return _map.pMap;
    }
  });
});