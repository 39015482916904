define("flood/templates/projects/project/test-plans/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sh/Q3mui",
    "block": "{\"symbols\":[\"pane\"],\"statements\":[[8,\"flud-ui/app/page\",[[24,0,\"stream-editor\"]],[[\"@compactHeader\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"stream-editor/header\",[],[[\"@stream\",\"@deleteAction\",\"@inFlight\",\"@enableDesign\",\"@enableLaunch\"],[[32,0,[\"model\",\"stream\"]],[30,[36,0],[[32,0],\"deleteStream\"],null],[32,0,[\"inFlight\"]],[32,0,[\"enableTabs\"]],[32,0,[\"enableTabs\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"stream-editor\",[],[[\"@stream\",\"@tools\",\"@currentStepIndex\",\"@inFlight\",\"@enableLaunch\"],[[32,0,[\"model\",\"stream\"]],[32,0,[\"model\",\"tools\"]],[32,0,[\"currentStepIndex\"]],[32,0,[\"inFlight\"]],false]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"stream-editor/delete\",[],[[\"@stream\",\"@showWhen\"],[[32,0,[\"model\",\"stream\"]],[32,0,[\"isConfirmingDelete\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/test-plans/new.hbs"
    }
  });

  _exports.default = _default;
});