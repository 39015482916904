define("flood/components/account/integration-form/google-cloud/drop-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U08FdA5a",
    "block": "{\"symbols\":[],\"statements\":[[8,\"file-dropzone\",[],[[\"@name\",\"@multiple\",\"@ondrop\"],[\"account-key\",false,[30,[36,0],[[32,0],\"onFileAdd\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[8,\"file-upload\",[],[[\"@name\",\"@for\",\"@accept\",\"@multiple\",\"@onfileadd\"],[\"account-key\",\"upload-account-key\",\"application/json\",false,[30,[36,0],[[32,0],\"onFileAdd\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"upload-zone\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"material-icons md-64\"],[12],[2,\"cloud_upload\"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,1],[\"integrations.gcp.form.action.upload\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "flood/components/account/integration-form/google-cloud/drop-zone/template.hbs"
    }
  });

  _exports.default = _default;
});