define("flood/components/stream-editor/design/configure/uploader/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PZ4hjyBF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.options.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body2 advanced-configuration-description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"streamEditor.design.fields.options.description\"],[[\"tool\",\"htmlSafe\"],[[30,[36,2],[[32,0,[\"stream\",\"tool\"]],[30,[36,0],[[30,[36,1],[\"tools.\",[32,0,[\"stream\",\"tool\"]]],null]],null],[30,[36,0],[\"streamEditor.design.fields.options.defaultToolName\"],null]],null],true]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"flud-ui/text-field\",[],[[\"@label\",\"@value\",\"@onChange\",\"@disabled\"],[[30,[36,0],[\"streamEditor.design.fields.options.label\"],null],[32,0,[\"stream\",\"options\"]],[30,[36,3],[[32,0,[\"setValue\"]]],null],[30,[36,4],[\"modify stream\"],[[\"projectId\"],[[32,0,[\"stream\",\"project\",\"id\"]]]]]]],null],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--caption\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.options.hint\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"if\",\"fn\",\"cannot\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/configure/uploader/options/template.hbs"
    }
  });

  _exports.default = _default;
});