define("flood/templates/identity-verify/qtest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iUcalTT0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-ui/landing\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"model\",\"error\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"flud-ui/alert\",[],[[\"@type\"],[\"error\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[30,[36,1],[\"qtest.errors.\",[32,0,[\"model\",\"error\",\"code\"]]],null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"flud-ui/alert\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"qtest.verification.success\"],[[\"url\",\"htmlSafe\"],[[32,0,[\"model\",\"data\",\"qtest_url\"]],true]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/identity-verify/qtest.hbs"
    }
  });

  _exports.default = _default;
});