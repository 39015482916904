define("flood/templates/qtest/sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WM49fshs",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-ui/landing\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"isVerified\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"Container Container--center\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"task-completion-steps\",[],[[\"@tasks\"],[[32,0,[\"syncTasks\"]]]],null],[2,\"\\n\"],[6,[37,1],[[32,0,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"flud-ui/alert\",[],[[\"@type\"],[\"error\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[[30,[36,2],[\"qtest.errors.\",[30,[36,1],[[32,0,[\"error\",\"code\"]],[32,0,[\"error\",\"code\"]],\"default\"],null]],null]],[[\"detail\"],[[32,0,[\"error\",\"detail\"]]]]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"flud-ui/alert\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"qtest.verification.info\"],[[\"domain\",\"email\",\"htmlSafe\"],[[32,0,[\"domain\"]],[32,0,[\"email\"]],true]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "flood/templates/qtest/sync.hbs"
    }
  });

  _exports.default = _default;
});