define("flood/templates/projects/project/infrastructure/create/grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c4qZn10A",
    "block": "{\"symbols\":[\"pane\"],\"statements\":[[8,\"flud-ui/app/page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Launch New Grid\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"flud-ui/alert\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[2,\"Looking to run on Flood’s on-demand infrastructure? Start demand grids automatically from the \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"test-plans.index\",[32,0,[\"project\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"Stream editor\"]],\"parameters\":[]}]]],[2,\".\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"stacked-grid-form\",[],[[\"@grid\",\"@sizes\",\"@account\",\"@maxInstanceQuantity\",\"@nodesTotal\",\"@nodesActiveTotal\",\"@credentials\",\"@securityGroups\",\"@elasticIPs\",\"@availabilityZones\",\"@vpcs\",\"@isLoadingAWSAccount\",\"@loadAmazonAccount\",\"@onLaunch\"],[[32,0,[\"grid\"]],[32,0,[\"sizes\"]],[32,0,[\"account\"]],[32,0,[\"account\",\"nodesPerGridTotal\"]],[32,0,[\"account\",\"nodesPerGridTotal\"]],[32,0,[\"account\",\"nodesActiveTotal\"]],[34,0],[32,0,[\"securityGroups\"]],[32,0,[\"elasticIPs\"]],[32,0,[\"availabilityZones\"]],[32,0,[\"vpcs\"]],[32,0,[\"loadAmazonAccount\",\"isRunning\"]],[30,[36,1],[[32,0],\"loadAmazonAccount\"],null],[30,[36,1],[[32,0],\"launchGrid\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"credentials\",\"action\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/infrastructure/create/grid.hbs"
    }
  });

  _exports.default = _default;
});