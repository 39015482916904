define("flood/components/account/members-section/membership-zone/cell-items/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ljloo6XU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"data\",\"isAnOrg\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"account.orgs.org.members\",[32,0,[\"data\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"data\",\"name\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"account.orgs.org.teams.team.members\",[30,[36,0],[[32,0,[\"data\",\"orgId\"]],[32,0,[\"data\",\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"data\",\"name\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/account/members-section/membership-zone/cell-items/name/template.hbs"
    }
  });

  _exports.default = _default;
});