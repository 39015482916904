define("flood/components/disclosure-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D4kl9wKw",
    "block": "{\"symbols\":[\"&else\",\"&default\"],\"statements\":[[18,2,[[30,[36,1],null,[[\"isExpanded\",\"toggle\"],[[32,0,[\"isExpanded\"]],[30,[36,0],[[32,0],\"toggleExpanded\"],null]]]]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isExpanded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "flood/components/disclosure-container/template.hbs"
    }
  });

  _exports.default = _default;
});