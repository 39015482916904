define("flood/components/flood-explorer/project-awaiting-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f8xIUYW6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[30,[36,0],[\"explorer.project.awaiting-data\"],null]],[13],[2,\"\\n\"],[8,\"link-to\",[[24,0,\"Button\"]],[[\"@route\",\"@model\"],[\"projects.project.test-plans.new\",[32,0,[\"projectId\"]]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"explorer.project.awaiting-data-new-flood\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/project-awaiting-data/template.hbs"
    }
  });

  _exports.default = _default;
});