define("flood/components/account/danger-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ntoi5UcW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h3\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[31,[\"Card shadow-elevation-2dp \",[32,0,[\"data\",\"type\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content-container\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,0,[\"data\",\"label\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[32,0,[\"data\",\"description\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"button-container\"],[12],[2,\"\\n    \"],[8,\"flud-ui/button\",[[16,0,[30,[36,1],[[30,[36,2],[[32,0,[\"data\",\"type\"]],\"danger\"],null],\"danger\",\"warning\"],null]],[4,[38,4],[\"click\",[30,[36,3],[[32,0],[32,0,[\"data\",\"action\"]]],null]],null]],[[\"@style\"],[\"raised\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,0,[\"data\",\"btnName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"if\",\"eq\",\"action\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/account/danger-zone/template.hbs"
    }
  });

  _exports.default = _default;
});