define("flood/components/account/projects-section/project-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OYMElNJf",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,0,[\"context\"]],\"organization\"],null],\"account/projects-section/project-form/organization-context\",\"account/projects-section/project-form/team-context\"],null]],[[\"primaryButtonTitle\",\"org\",\"teams\",\"project\",\"permissionOptions\",\"selectedPermission\",\"selectedTeam\",\"projectValidations\",\"isCreating\",\"saveChanges\"],[[32,0,[\"primaryButtonTitle\"]],[32,0,[\"org\"]],[32,0,[\"teams\"]],[32,0,[\"project\"]],[32,0,[\"permissionOptions\"]],[32,0,[\"selectedPermission\"]],[32,0,[\"selectedTeam\"]],[32,0,[\"ProjectValidations\"]],[32,0,[\"isCreating\"]],[30,[36,0],[[32,0],\"saveChanges\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"eq\",\"if\",\"component\"]}",
    "meta": {
      "moduleName": "flood/components/account/projects-section/project-form/template.hbs"
    }
  });

  _exports.default = _default;
});