define("flood/components/insights/nav-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NgSa4X0Y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"Header Header--float Header--account\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[14,\"flex-1\",\"\"],[12],[2,\"\\n    \"],[10,\"section\"],[14,0,\"title\"],[12],[2,\"\\n      \"],[10,\"h5\"],[14,0,\"mdc-typography--headline5\"],[12],[1,[30,[36,0],[\"insights.title\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"flud-ui/button/link-to\",[[16,0,[30,[36,0],[\"insights.ask-a-question\"],null]]],[[\"@route\",\"@model\",\"@label\",\"@style\"],[\"insights.metabase\",\"question/new\",\"Ask a question\",\"raised\"]],null],[2,\"\\n    \"],[10,\"a\"],[14,0,\"guides mdc-button mdc-button--raised\"],[14,6,\"https://guides.flood.io/results-analysis/insights\"],[12],[1,[30,[36,0],[\"insights.guide\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"alert-box\"],[12],[2,\"\\n  \"],[8,\"flud-ui/alert\",[[24,0,\"outline\"]],[[\"@type\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Due to limited usage the Insights feature will be removed from Flood on 2nd December 2021. Please contact Support if you require assistance in migrating to another service.\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/insights/nav-bar/template.hbs"
    }
  });

  _exports.default = _default;
});