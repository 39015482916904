define("flood/components/stream-editor/launch/ramp-up/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ayKEOcTe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,0],[\"streamEditor.launch.fields.rampUp.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n  \"],[8,\"stream-editor/launch/control\",[],[[\"@sliderMin\",\"@sliderMax\",\"@sliderStep\",\"@numberMin\",\"@numberMax\",\"@numberStep\",\"@value\",\"@label\",\"@onChange\",\"@disabled\"],[[32,0,[\"min\"]],[32,0,[\"max\"]],[32,0,[\"sliderStep\"]],[32,0,[\"min\"]],[32,0,[\"max\"]],[32,0,[\"numberStep\"]],[32,0,[\"stream\",\"rampUpInMinutes\"]],[30,[36,0],[\"streamEditor.launch.fields.rampUp.label\"],null],[30,[36,1],[[32,0,[\"setValue\"]]],null],[32,0,[\"disabled\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/launch/ramp-up/template.hbs"
    }
  });

  _exports.default = _default;
});