define("flood/utils/transform-string", ["exports", "ember-primer/utils/transform-string"], function (_exports, _transformString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _transformString.default;
    }
  });
});