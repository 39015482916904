define("flood/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mwPyKUy/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"Header Header--float Header--account\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[14,\"flex-1\",\"\"],[12],[2,\"\\n    \"],[10,\"section\"],[14,0,\"title\"],[12],[2,\"\\n      \"],[10,\"h5\"],[14,0,\"mdc-typography--headline5\"],[12],[1,[32,0,[\"settingTitle\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"navigationData\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"section\"],[14,\"flex-1\",\"\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,0,[\"navigationData\",\"currentRoute\"]],\"organization\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[14,0,\"account-name\"],[12],[1,[32,0,[\"model\",\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[8,\"account/bread-crumbs\",[],[[\"@breadcrumbs\"],[[32,0,[\"breadcrumbs\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"section\"],[14,\"flex-2\",\"\"],[14,0,\"tabs\"],[12],[2,\"\\n        \"],[8,\"account/tabs-navigation\",[],[[\"@navigationData\"],[[32,0,[\"navigationData\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"Container Container--with-SideNav\"],[12],[2,\"\\n  \"],[8,\"account/side-nav\",[],[[],[]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"Container Container--main\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "flood/templates/account.hbs"
    }
  });

  _exports.default = _default;
});