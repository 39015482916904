define("flood/components/nine-box/nine-box-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xIQppRJ2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"app-button-layout\"],[12],[2,\"\\n  \"],[8,\"flud-ui/button\",[[16,0,[31,[\"app-button \",[30,[36,2],[[32,0,[\"data\",\"disabled\"]],\"disabled\"],null]]]],[4,[38,3],[\"click\",[30,[36,0],[[32,0],\"launchApp\"],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"logo \",[32,0,[\"data\",\"logoClassName\"]]]]],[12],[13],[2,\"\\n    \"],[10,\"span\"],[12],[1,[32,0,[\"data\",\"name\"]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"data\",\"showLock\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"lock-icon\"],[14,\"role\",\"button\"],[15,\"onmouseover\",[30,[36,0],[[32,0],\"switchLockTooltip\",true],null]],[15,\"onmouseout\",[30,[36,0],[[32,0],\"switchLockTooltip\",false],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[2,\"lock\"],[13],[2,\"\\n      \"],[8,\"attach-tooltip\",[],[[\"@class\",\"@renderInPlace\",\"@placement\"],[\"button-tooltip\",true,\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"qTestApplication.informationTooltip.noPermission\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showLockTooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"attach-tooltip\",[],[[\"@class\",\"@renderInPlace\",\"@placement\"],[\"button-tooltip\",true,\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"data\",\"tooltip\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"if\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "flood/components/nine-box/nine-box-button/template.hbs"
    }
  });

  _exports.default = _default;
});