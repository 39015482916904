define("flood/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w+uRB3nb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"Container Container--center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"Container Container--registration\"],[12],[2,\"\\n    \"],[8,\"login-form\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/login.hbs"
    }
  });

  _exports.default = _default;
});