define("flood/templates/projects/project/infrastructure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gnYn0r+w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"Header Header--float Header--infrastructure\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[12],[2,\"\\n    \"],[10,\"section\"],[14,\"flex-4\",\"\"],[14,0,\"navigation\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ButtonGroup Actions\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"Button Button--withIcon\"]],[[\"@route\",\"@title\",\"@current-when\"],[\"projects.project.infrastructure.grids\",\"Show Active Grids\",\"projects.project.infrastructure.grids projects.project.infrastructure.create.grid\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"infrastructure.grids.title\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"section\"],[14,0,\"global\"],[14,\"flex-3\",\"\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"padding-h-1-5 padding-v-1\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[14,\"center\",\"\"],[12],[1,[30,[36,2],[[30,[36,1],[\"header\"],null]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/infrastructure.hbs"
    }
  });

  _exports.default = _default;
});