define("flood/components/account/side-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RLIwFGaS",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"action\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"menu-item\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,[36,2],[[32,0],[32,1,[\"action\"]]],null]],null],[12],[2,\"\\n      \"],[8,\"flud-ui/icon\",[[24,0,\"menu-item__icon\"]],[[\"@name\"],[[32,1,[\"icon\"]]]],null],[2,\"\\n      \"],[10,\"span\"],[14,0,\"menu-item__title\"],[12],[1,[30,[36,0],[[32,1,[\"title\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,1,[\"route\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"menu-item\"]],[[\"@route\"],[[32,1,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"flud-ui/icon\",[[24,0,\"menu-item__icon\"]],[[\"@name\"],[[32,1,[\"icon\"]]]],null],[2,\"\\n      \"],[10,\"span\"],[14,0,\"menu-item__title\"],[12],[1,[30,[36,0],[[32,1,[\"title\"]]],null]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/account/side-nav/template.hbs"
    }
  });

  _exports.default = _default;
});