define("flood/components/account/members-section/invitation-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "urQP8kSl",
    "block": "{\"symbols\":[\"@dataSource\",\"@selectedInvitationIds\"],\"statements\":[[8,\"flood-ui/table\",[],[[\"@className\",\"@dataSource\",\"@selection\",\"@selectedItems\",\"@columns\"],[\"members-table\",[32,1],true,[32,2],[32,0,[\"columns\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/account/members-section/invitation-table/template.hbs"
    }
  });

  _exports.default = _default;
});