define("flood/components/flood-ui/sidesheet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NUcNKSMe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"show\"]]],[[\"class\"],[\"side-sheet-liquid\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,1,\"main-sheet\"],[12],[2,\"\\n    \"],[8,\"flud-ui/button/icon\",[[24,0,\"side-sheet-button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"hide\"],[[\"bubbles\"],[false]]]],null]],[[\"@name\"],[\"clear\"]],null],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"on\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/sidesheet/template.hbs"
    }
  });

  _exports.default = _default;
});