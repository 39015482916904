define("flood/components/account/integration-form/azure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n57ph3FL",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"flood-ui/form\",[],[[\"@data\",\"@onSubmit\"],[[30,[36,0],[[32,0,[\"credential\"]],[32,0,[\"AzureCredentialValidations\"]]],null],[30,[36,1],[[32,0],\"saveCredential\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\"],[\"azureTenantId\",[30,[36,2],[\"integrations.azure.form.tenantId.placeholder\"],null],[30,[36,2],[\"integrations.azure.form.tenantId.label\"],null]]],null],[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\"],[\"azureClientId\",[30,[36,2],[\"integrations.azure.form.clientId.placeholder\"],null],[30,[36,2],[\"integrations.azure.form.clientId.label\"],null]]],null],[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\",\"@type\"],[\"azureClientSecret\",[30,[36,2],[\"integrations.azure.form.clientSecret.placeholder\"],null],[30,[36,2],[\"integrations.azure.form.clientSecret.label\"],null],\"password\"]],null],[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\"],[\"azureSubscriptionId\",[30,[36,2],[\"integrations.azure.form.subscriptionId.placeholder\"],null],[30,[36,2],[\"integrations.azure.form.subscriptionId.label\"],null]]],null],[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\"],[\"description\",[30,[36,2],[\"integrations.azure.form.name.placeholder\"],null],[30,[36,2],[\"integrations.azure.form.name.label\"],null]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"Actions\"],[12],[2,\"\\n    \"],[8,[32,1,[\"submit\"]],[],[[\"@label\"],[\"Save\"]],null],[2,\"\\n    \"],[8,\"flud-ui/button\",[[4,[38,3],[\"click\",[30,[36,1],[[32,0],\"cancel\"],null]],null]],[[\"@label\"],[\"Cancel\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"changeset\",\"action\",\"t\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/account/integration-form/azure/template.hbs"
    }
  });

  _exports.default = _default;
});