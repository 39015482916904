define("flood/components/timeline-brush/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JzGjo7B0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"defs\"],[12],[2,\"\\n  \"],[10,\"pattern\"],[15,1,[31,[\"handle-pattern-\",[32,0,[\"elementId\"]]]]],[14,0,\"pattern-handle\"],[14,\"width\",\"1\"],[14,\"height\",\"1\"],[12],[2,\"\\n    \"],[10,\"rect\"],[14,0,\"border\"],[14,\"width\",\"11\"],[14,\"height\",\"22\"],[12],[13],[2,\"\\n    \"],[10,\"rect\"],[14,0,\"background\"],[14,\"x\",\"1\"],[14,\"y\",\"1\"],[14,\"width\",\"9\"],[14,\"height\",\"20\"],[14,\"rx\",\"2\"],[14,\"ry\",\"2\"],[12],[13],[2,\"\\n    \"],[10,\"rect\"],[14,0,\"line\"],[14,\"x\",\"3\"],[14,\"y\",\"3\"],[14,\"width\",\"1\"],[14,\"height\",\"16\"],[12],[13],[2,\"\\n    \"],[10,\"rect\"],[14,0,\"line\"],[14,\"x\",\"5\"],[14,\"y\",\"3\"],[14,\"width\",\"1\"],[14,\"height\",\"16\"],[12],[13],[2,\"\\n    \"],[10,\"rect\"],[14,0,\"line\"],[14,\"x\",\"7\"],[14,\"y\",\"3\"],[14,\"width\",\"1\"],[14,\"height\",\"16\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"g\"],[14,0,\"brush\"],[15,\"transform\",[31,[\"translate(\",[32,0,[\"left\"]],\",\",[32,0,[\"top\"]],\")\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/timeline-brush/template.hbs"
    }
  });

  _exports.default = _default;
});