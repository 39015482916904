define("flood/serializers/test-plan", ["exports", "flood/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      user: {
        serialize: false
      },
      account: {
        serialize: false
      },
      floods: {
        serialize: false
      },
      uuid: {
        serialize: false
      },
      createdAt: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      },
      scheduleRepeatDays: {
        serialize: false
      },
      scheduleEndDate: {
        serialize: false
      },
      scheduleStartAt: {
        serialize: false
      },
      steps: {
        serialize: true,
        deserialize: 'ids'
      },
      files: {
        serialize: true,
        deserialize: 'ids'
      },
      hasPropertyThreads: {
        serialize: false
      },
      hasPropertyDuration: {
        serialize: false
      },
      hasPropertyRampup: {
        serialize: false
      },
      estimatedTotalUsers: {
        serialize: false
      },
      estimatedVirtualUserMinutes: {
        serialize: false
      },
      estimatedDollarCost: {
        serialize: false
      },
      lastFlood: {
        serialize: false
      },
      numberOfFloods: {
        serialize: false
      },
      scenarioUuid: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});