define("flood/utils/curve-lookup", ["exports", "ember-primer/utils/curve-lookup"], function (_exports, _curveLookup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _curveLookup.default;
    }
  });
});