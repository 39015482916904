define("flood/transitions/sidesheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sidesheet;

  function sidesheet(transition, opts) {
    var _this = this;

    if (transition === 'toLeft' && this.newElement) {
      this.newElement.trigger('on-sidesheet-show');
    }

    return this.lookup(transition).apply(this, [opts || {}]).then(function () {
      if (transition === 'toRight' && _this.oldElement) {
        _this.oldElement.trigger('on-sidesheet-hide');
      }
    });
  }
});