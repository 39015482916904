define("flood/components/flood-options-menu/trigger-compact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UIqjefrU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"label\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[2,\"more_vert\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-options-menu/trigger-compact/template.hbs"
    }
  });

  _exports.default = _default;
});