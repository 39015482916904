define("flood/components/flud-ui/button/download/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ewXvd4Ta",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"flud-ui/button\",[[4,[38,3],[\"click\",[30,[36,2],[[32,0],\"clickDownload\"],null]],null]],[[\"@style\",\"@type\",\"@disabled\"],[[32,0,[\"style\"]],[34,1],[32,0,[\"disabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"flud-ui/icon\",[],[[\"@name\"],[\"cloud_download\"]],null],[2,\"\\n\"],[6,[37,4],[[32,0,[\"loading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"fludui.button.download.loading\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"type\",\"action\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/button/download/template.hbs"
    }
  });

  _exports.default = _default;
});