define("flood/components/usage-widget/vum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uwl2MQIb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@classNames\"],[\"account.usage\",\"icon-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[2,\"timelapse\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"account\",\"virtualUserMinutesBilledCents\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"label\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"attach-tooltip\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[\"header.account.usage.toolTip\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"price\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "flood/components/usage-widget/vum/template.hbs"
    }
  });

  _exports.default = _default;
});