define("flood/components/flood-ui/content-pane/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d3TOtdfv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"showHeader\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"header\"],[14,0,\"content-pane__header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-pane__header--left\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,1],null,[[\"headerLeft\"],[[30,[36,0],[\"flood-ui/blank-template\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-pane__header--right\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,1],null,[[\"headerRight\"],[[30,[36,0],[\"flood-ui/blank-template\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"content-pane__content\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"content\"],[[30,[36,0],[\"flood-ui/blank-template\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/content-pane/template.hbs"
    }
  });

  _exports.default = _default;
});