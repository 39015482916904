define("flood/utils/auth0-connection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRawTextCertificate = getRawTextCertificate;
  _exports.getFullProtocol = getFullProtocol;
  _exports.getShortedProtocol = getShortedProtocol;
  _exports.getDomainAliases = getDomainAliases;
  _exports.getDomainString = getDomainString;
  _exports.getFullConnectionName = getFullConnectionName;
  _exports.getFileNameFromPath = getFileNameFromPath;
  _exports.PROTOCOL_BINDING = _exports.ARGORITHM_DIGEST = _exports.REQUEST_ALGORITHM = _exports.PROTOCOL_PREFIX = _exports.CONNECTION_NAME_PREFIX = void 0;
  var CONNECTION_NAME_PREFIX = 'DoNotDelete';
  _exports.CONNECTION_NAME_PREFIX = CONNECTION_NAME_PREFIX;
  var PROTOCOL_PREFIX = 'urn:oasis:names:tc:SAML:2.0:bindings:';
  _exports.PROTOCOL_PREFIX = PROTOCOL_PREFIX;
  var REQUEST_ALGORITHM = ['', 'RSA-SHA256', 'RSA-SHA1'];
  _exports.REQUEST_ALGORITHM = REQUEST_ALGORITHM;
  var ARGORITHM_DIGEST = ['', 'SHA256', 'SHA1'];
  _exports.ARGORITHM_DIGEST = ARGORITHM_DIGEST;
  var PROTOCOL_BINDING = ['', 'HTTP-Redirect', 'HTTP-POST'];
  _exports.PROTOCOL_BINDING = PROTOCOL_BINDING;

  function getRawTextCertificate(strCertificate) {
    return strCertificate.replace('-----BEGIN CERTIFICATE-----', '').replace('-----END CERTIFICATE-----', '').trim();
  }

  function getFullProtocol(protocol) {
    return "".concat(PROTOCOL_PREFIX).concat(protocol);
  }

  function getShortedProtocol(fullProtocol) {
    return fullProtocol ? fullProtocol.replace(PROTOCOL_PREFIX, '') : fullProtocol;
  }

  function getDomainAliases(strDomains) {
    return strDomains ? strDomains.split(',').map(function (domain) {
      return domain.trim();
    }) : [];
  }

  function getDomainString(domains) {
    return domains ? domains.join(',') : domains;
  }

  function getFullConnectionName(accountId, name) {
    return "".concat(CONNECTION_NAME_PREFIX, "-").concat(accountId, "-").concat(name);
  }

  function getFileNameFromPath(path) {
    var splitPosition = path.lastIndexOf('\\');

    if (splitPosition) {
      return path.substring(splitPosition + 1);
    }

    return path ? path : '';
  }
});