define("flood/templates/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pr/uZr1Y",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isReady\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"flud-ui/loader/logo\",[],[[\"@className\"],[\"insights-loader\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"insights/nav-bar\",[],[[],[]],null],[2,\"\\n\\n\"],[8,\"insights/metabase-iframe\",[],[[\"@pathname\",\"@search\",\"@hash\",\"@onWaiting\",\"@onReady\",\"@onChangeInsightsLocation\"],[[32,0,[\"pathname\"]],[32,0,[\"search\"]],[32,0,[\"hash\"]],[32,0,[\"setWaiting\"]],[32,0,[\"setReady\"]],[32,0,[\"setInsightsLocation\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\"]}",
    "meta": {
      "moduleName": "flood/templates/insights.hbs"
    }
  });

  _exports.default = _default;
});