define("flood/components/flood-explorer/issues/inline/area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Te2F1RIF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"defs\"],[12],[2,\"\\n  \"],[10,\"linearGradient\"],[14,1,\"myGradient\"],[14,\"gradientTransform\",\"rotate(90)\"],[12],[2,\"\\n    \"],[10,\"stop\"],[14,\"offset\",\"0%\"],[14,\"stop-color\",\"rgba(255, 143, 115, 0.8)\"],[12],[13],[2,\"\\n    \"],[10,\"stop\"],[14,\"offset\",\"25%\"],[14,\"stop-color\",\"rgba(255, 196, 0, 0.8)\"],[12],[13],[2,\"\\n    \"],[10,\"stop\"],[14,\"offset\",\"100%\"],[14,\"stop-color\",\"rgba(87, 217, 163, 0.8)\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/issues/inline/area/template.hbs"
    }
  });

  _exports.default = _default;
});