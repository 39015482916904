define("flood/gql/queries/flood-sparklines.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "FloodSparkLines"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "floodId"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "measurementsQuery"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "FloodMeasurementsQuery"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "flood"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "id"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "floodId"
              }
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "uuid"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "measurements"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "query"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "measurementsQuery"
                  }
                }
              }],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "concurrency"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "sample"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "definition"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "measurement"
                        },
                        "value": {
                          "kind": "StringValue",
                          "value": "concurrency_sum",
                          "block": false
                        }
                      }, {
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "aggregation"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "MAX"
                        }
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "points"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "responseTime"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "sample"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "definition"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "measurement"
                        },
                        "value": {
                          "kind": "StringValue",
                          "value": "response_time",
                          "block": false
                        }
                      }, {
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "aggregation"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "MEAN"
                        }
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "points"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "failed"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "sample"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "definition"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "measurement"
                        },
                        "value": {
                          "kind": "StringValue",
                          "value": "failed",
                          "block": false
                        }
                      }, {
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "aggregation"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "RATE_PER_MINUTE"
                        }
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "points"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "transactionRate"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "sample"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "definition"
                    },
                    "value": {
                      "kind": "ObjectValue",
                      "fields": [{
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "measurement"
                        },
                        "value": {
                          "kind": "StringValue",
                          "value": "transaction_rate_sum",
                          "block": false
                        }
                      }, {
                        "kind": "ObjectField",
                        "name": {
                          "kind": "Name",
                          "value": "aggregation"
                        },
                        "value": {
                          "kind": "EnumValue",
                          "value": "RATE_PER_MINUTE"
                        }
                      }]
                    }
                  }],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "points"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 676
    }
  };
  var _default = doc;
  _exports.default = _default;
});