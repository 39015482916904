define("flood/mixins/global-resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this._handleResizeEvent = this._handleResizeEvent.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      window.addEventListener("resize", this._handleResizeEvent, {
        passive: true
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener("resize", this._handleResizeEvent);
    },
    _handleResizeEvent: function _handleResizeEvent(_event) {
      var _this = this;

      Ember.run(this, function () {
        return _this.didResize(event);
      });
    },
    // @ts-ignore
    didResize: function didResize(_event) {}
  });

  _exports.default = _default;
});