define("flood/utils/floods", ["exports", "flood/models/flood"], function (_exports, _flood) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOver = isOver;

  function isOver(status) {
    return [_flood.FINISHED_STATUS, _flood.STOPPED_STATUS, _flood.FAILED_STATUS, _flood.ARCHIVING_STATUS, _flood.PROBLEM_STATUS].includes(status);
  }
});