define("flood/components/stream-editor/design/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iqqeomSL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.name.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n  \"],[8,\"flud-ui/text-field\",[],[[\"@label\",\"@value\",\"@onChange\",\"@disabled\"],[[30,[36,0],[\"streamEditor.design.fields.name.label\"],null],[32,0,[\"stream\",\"name\"]],[30,[36,1],[[32,0,[\"setValue\"]]],null],[30,[36,3],[[32,0,[\"stream\",\"isLaunching\"]],[30,[36,2],[\"modify stream\"],[[\"projectId\"],[[32,0,[\"stream\",\"project\",\"id\"]]]]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"cannot\",\"or\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/name/template.hbs"
    }
  });

  _exports.default = _default;
});