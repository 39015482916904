define("flood/components/stream-editor/launch/grids/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "18JfRVVy",
    "block": "{\"symbols\":[\"grid\"],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,0],[\"streamEditor.launch.fields.grids.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"grids\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"power-select-multiple\",[[24,0,\"full-width\"]],[[\"@options\",\"@selected\",\"@placeholder\",\"@onChange\",\"@disabled\",\"@closeOnSelect\"],[[32,0,[\"gridOptions\"]],[32,0,[\"selectedGrids\"]],[30,[36,0],[\"streamEditor.launch.fields.grids.placeholder\"],null],[30,[36,1],[[32,0],\"changeHandler\"],null],[32,0,[\"disabled\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"id\"]],\"toggle-all\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"name\"]]],[2,\" (\"],[1,[32,1,[\"region\"]]],[2,\")\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"start-grid\"],[12],[2,\"\\n    \"],[8,\"flud-ui/button/link-to\",[],[[\"@label\",\"@style\",\"@route\",\"@model\",\"@query\"],[\"Start a grid\",\"raised\",\"projects.project.infrastructure.create.grid\",[32,0,[\"stream\",\"project\",\"id\"]],[30,[36,6],null,[[\"returnTo\"],[[30,[36,5],[\",\",[30,[36,4],[\"projects.project.test-plans.editor.launch\",[32,0,[\"stream\",\"project\",\"id\"]],[32,0,[\"stream\",\"id\"]],\"infrastructure=hosted\"],null]],null]]]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"eq\",\"if\",\"array\",\"join\",\"hash\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/launch/grids/template.hbs"
    }
  });

  _exports.default = _default;
});