define("flood/components/flood-ui/form/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BRDUD+DT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[8,\"input\",[[16,\"required\",[32,0,[\"required\"]]]],[[\"@type\",\"@id\",\"@name\",\"@checked\",\"@disabled\",\"@indeterminate\",\"@autofocus\"],[\"checkbox\",[32,0,[\"identifier\"]],[32,0,[\"name\"]],[30,[36,3],[[30,[36,2],[[32,0,[\"data\"]],[32,0,[\"propertyName\"]]],null]],null],[32,0,[\"disabled\"]],[32,0,[\"indeterminate\"]],[32,0,[\"autofocus\"]]]],null],[2,\"\\n\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[32,0,[\"tooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"tooltip-icon\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"material-icons md-16\"],[12],[2,\"help\"],[13],[2,\"\\n      \"],[8,\"attach-tooltip\",[],[[\"@renderInPlace\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[34,0]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,4],[[32,0,[\"hint\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"hint\"],[12],[1,[32,0,[\"hint\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"error\"],[12],[1,[32,0,[\"error\",\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"label\",\"get\",\"mut\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/form/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});