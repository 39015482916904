define("flood/serializers/trace", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var parseAssertion = function parseAssertion(str) {
    var _str$split = str.split('|'),
        _str$split2 = _slicedToArray(_str$split, 3),
        failed = _str$split2[0],
        assertionName = _str$split2[1],
        message = _str$split2[2];

    return {
      isFailure: String(failed) === 'true',
      message: message === 'null' ? null : message,
      assertionName: assertionName
    };
  };

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    attrs: {
      statusCode: {
        key: 'response_code'
      }
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var trace = payload.trace;
      var errors = [];
      var newTrace = {
        assertions: [],
        screenshots: [],
        errors: errors,
        id: id
      };
      Object.keys(trace).forEach(function (key) {
        if (key.startsWith('assertion_')) {
          newTrace.assertions.push(parseAssertion(trace[key]));
        } else if (key === 'assertion') {
          newTrace.assertions.push({
            message: trace[key].message,
            isFailure: true,
            assertionName: 'assert-equal'
          });
        } else if (key === 'screenshot') {
          newTrace.screenshots.push(trace[key]);
        } else {
          newTrace[_this.keyForAttribute(key)] = trace[key];
        }
      });
      return this._normalizeResponse(store, primaryModelClass, {
        trace: newTrace
      }, id, requestType, true);
    }
  });

  _exports.default = _default;
});