define("flood/components/flud-ui/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WWOTLEBP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"name\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[32,0,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/icon/template.hbs"
    }
  });

  _exports.default = _default;
});