define("flood/components/account/usage/vu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jphEZPtE",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[8,\"flud-ui/app/card\",[[24,0,\"usage-summary-vu\"]],[[\"@title\",\"@subtitle\"],[[30,[36,0],[\"account.usage.summary.vu.title\"],null],[32,0,[\"summarySubtitle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"account.usage.summary.vu.allowance\"],[[\"included\",\"htmlSafe\"],[[32,0,[\"includedVu\"]],true]]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/account/usage/vu/template.hbs"
    }
  });

  _exports.default = _default;
});