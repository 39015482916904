define("flood/components/flud-ui/text-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rmVrcmvL",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"input\"],[16,1,[30,[36,0],[[32,0,[\"elementId\"]],\"_input\"],null]],[24,0,\"mdc-text-field__input\"],[16,2,[34,2]],[16,\"placeholder\",[32,0,[\"placeholder\"]]],[16,\"disabled\",[32,0,[\"disabled\"]]],[17,1],[24,4,\"text\"],[4,[38,4],[\"input\",[30,[36,3],[[32,0,[\"handleChange\"]]],null]],null],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"mdc-notched-outline\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mdc-notched-outline__leading\"],[12],[13],[2,\"\\n\"],[6,[37,5],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"mdc-notched-outline__notch\"],[12],[2,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,[36,0],[[32,0,[\"elementId\"]],\"_input\"],null]],[14,0,\"mdc-floating-label\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"mdc-notched-outline__trailing\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"label\",\"value\",\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/text-field/template.hbs"
    }
  });

  _exports.default = _default;
});