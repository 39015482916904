define("flood/utils/price-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatPrice;

  function formatPrice(value) {
    var formatted = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol'
    });
    var withoutCode = formatted.replace(/^US/, '');
    return withoutCode;
  }
});