define("flood/components/flood-ui/messages/plan-upgrade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z6eMhLKf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],null,[[\"message\",\"showWhen\"],[[32,0,[\"message\"]],[32,0,[\"showWhen\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\\n  \"],[1,[30,[36,0],[\"plan.upgrade.fromLegacy.activate\"],null]],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.billing\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"plan.upgrade.fromLegacy.updatePaymentDetailsLink\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,0],[\"plan.upgrade.fromLegacy.learnMore\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"flood-ui/messages\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/messages/plan-upgrade/template.hbs"
    }
  });

  _exports.default = _default;
});