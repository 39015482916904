define("flood/components/flood-explorer/controls/grid-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EITI7IkS",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\",\"@allowClear\",\"@matchTriggerWidth\",\"@horizontalPosition\",\"@placeholder\",\"@selectedItemComponent\"],[[32,0,[\"options\"]],[34,0],[30,[36,1],[[32,0],\"select\"],null],false,false,\"auto-right\",[30,[36,2],[\"explorer.controls.grids.placeholder\"],null],\"flood-explorer/controls/grid-selector/selected-item\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,3],null,[[\"grid\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"action\",\"t\",\"flood-explorer/controls/grid-selector/option-item\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/controls/grid-selector/template.hbs"
    }
  });

  _exports.default = _default;
});