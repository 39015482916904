define("flood/components/flood-explorer/chart-control/list-compact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8R2sRJpZ",
    "block": "{\"symbols\":[\"control\"],\"statements\":[[10,\"div\"],[14,0,\"chart-control-compact\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"list\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"control\"],[12],[2,\"\\n      \"],[8,\"flood-explorer/chart-control/measurement-status\",[],[[\"@status\",\"@color\",\"@label\",\"@onStatusChange\"],[[32,1,[\"status\"]],[32,1,[\"color\"]],[32,1,[\"alias\"]],[30,[36,0],[[32,0],\"updateMeasurementPosition\",[32,1,[\"measurement\"]]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/chart-control/list-compact/template.hbs"
    }
  });

  _exports.default = _default;
});