define("flood/utils/maybe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDefined = isDefined;
  _exports.isUndefined = isUndefined;
  _exports.getOrElse = getOrElse;

  // Pulled from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-1-8.html
  function isDefined(thing) {
    return thing !== undefined && thing !== null;
  }

  function isUndefined(thing) {
    return thing === undefined || thing === null;
  }

  function getOrElse(thing, defaultValue) {
    return isDefined(thing) ? thing : defaultValue;
  }
});