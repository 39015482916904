define("flood/components/runtime-duration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EmFT548b",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"offsetDateA\",\"offsetDateB\"],[[30,[36,0],[[32,0,[\"offsetDateA\"]]],null],[30,[36,0],[[32,0,[\"offsetDateB\"]]],null]]]]]]],\"hasEval\":false,\"upvars\":[\"moment-calendar\",\"hash\"]}",
    "meta": {
      "moduleName": "flood/components/runtime-duration/template.hbs"
    }
  });

  _exports.default = _default;
});