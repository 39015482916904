define("flood/serializers/project", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    attrs: {
      lastFloodedAt: {
        serialize: false
      },
      lastFlood: {
        serialize: false
      },
      teams: {
        serialize: false
      },
      teamCount: {
        serialize: false
      },
      uuid: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});