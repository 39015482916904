define("flood/mixins/duration-util", ["exports", "d3-format"], function (_exports, _d3Format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDuration = formatDuration;
  _exports.default = void 0;
  var round = Math.round,
      pow = Math.pow;
  var day = 864e5,
      hour = 36e5,
      minute = 6e4,
      second = 1e3;

  function roundToN(x, n) {
    return n ? round(x * (n = pow(10, n))) / n : round(x);
  }

  function durationToString(milliseconds, formats, pluralize) {
    function format(milliseconds, unit) {
      return pluralize && milliseconds !== 1 ? "".concat(milliseconds + unit, "s") : milliseconds + unit;
    }

    if (milliseconds < 10e3) {
      var fmt = (0, _d3Format.format)(',d');
      return "".concat(fmt(milliseconds), " ms");
    } else {
      return milliseconds >= day ? format(round(milliseconds / day, 2), formats.d) : milliseconds >= hour ? format(round(milliseconds / hour, 2), formats.h) : milliseconds >= minute ? format(round(milliseconds / minute, 2), formats.m) : milliseconds >= second ? format(round(milliseconds / second, 2), formats.s) : format(milliseconds, formats.ms);
    }
  }

  function formatDuration(milliseconds) {
    return durationToString(roundToN(milliseconds), {
      d: ' d',
      h: ' h',
      m: ' m',
      s: ' s',
      ms: ' ms'
    });
  }

  var _default = Ember.Mixin.create({
    formatDuration: formatDuration,
    formatLongDuration: function formatLongDuration(milliseconds) {
      return durationToString(milliseconds, {
        d: ' day',
        h: ' hour',
        m: ' minute',
        s: ' second',
        ms: ' millisecond'
      }, true);
    }
  });

  _exports.default = _default;
});