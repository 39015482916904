define("flood/components/flood-ui/landing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qauCjr1j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"header\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/flood-logo-blue.svg\"],[14,\"alt\",\"TricentisFlood\"],[14,0,\"logo\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"main\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/landing/template.hbs"
    }
  });

  _exports.default = _default;
});