define("flood/components/account/projects-section/cell-items/teams/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IMa7FEbz",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"orgs.tables.counts.teams\"],[[\"count\",\"htmlSafe\"],[[32,0,[\"data\",\"teamCount\"]],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/account/projects-section/cell-items/teams/template.hbs"
    }
  });

  _exports.default = _default;
});