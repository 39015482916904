define("flood/components/log-file-viewer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSettings: Ember.inject.service(),
    classNameBindings: ['nightTheme'],
    headFirst: false,
    inFlight: false,
    entries: null,
    nightTheme: Ember.computed('userSettings.nightTheme', {
      get: function get() {
        return this.userSettings.getWithDefault('nightTheme', false);
      }
    }),
    menuOptions: Ember.computed('nightTheme', 'headFirst', {
      get: function get() {
        return [{
          title: 'Use dark theme',
          isActive: this.nightTheme,
          action: 'toggleDarkTheme'
        }, {
          title: 'Head first',
          isActive: this.headFirst,
          action: 'toggleHeadFirst'
        }];
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var options = (arguments.length <= 0 ? undefined : arguments[0]) || {};
      this.entries = options.entries || [];
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.element.addEventListener('wheel', function (event) {
        return Ember.run(_this, _this._handleScroll, event);
      });
      document.addEventListener('touchmove', function (event) {
        return Ember.run(_this, _this._handleScroll, event);
      });
      window.addEventListener('scroll', function (event) {
        return Ember.run(_this, _this._handleScroll, event);
      });
      this.loadNewer(500);
    },
    _handleScroll: function _handleScroll() {
      if (this.inFlight || this.isDestroyed) return;
      var atBottom = document.body.clientHeight + window.pageYOffset === document.body.scrollHeight;
      var atTop = window.pageYOffset === 0;
      var limit = 200;

      if (atTop) {
        this.loadOlder(limit);
      } else if (atBottom) {
        this.loadNewer(limit);
      }
    },
    loadNewer: function loadNewer() {},
    loadOlder: function loadOlder() {},
    toggleHeadFirst: function toggleHeadFirst() {},
    actions: {
      toggleHeadFirst: function toggleHeadFirst() {
        if (!this.isDestroyed) {
          this.toggleHeadFirst();
        }
      },
      toggleDarkTheme: function toggleDarkTheme() {
        var userSettings = this.userSettings;
        var active = userSettings.getWithDefault('nightTheme', false);
        userSettings.set('nightTheme', !active);
      },
      selectMenuItem: function selectMenuItem(item) {
        if (item.action) {
          this.send(item.action, item);
        }
      }
    }
  });

  _exports.default = _default;
});