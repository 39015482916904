define("flood/components/screenshots-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "edJVLkW5",
    "block": "{\"symbols\":[\"object\",\"file\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"filmstrip has-selection\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,1,[\"screenshots\"]]],null]],null]],[[\"key\"],[\"@index\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"Frame \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedObjectId\"]],[32,1,[\"id\"]]],null],\"selected\"],null]]]],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"role\",\"button\"],[15,0,[31,[\"screenshot-small \",[30,[36,1],[[30,[36,2],[[32,0,[\"loadedFiles\"]],[32,1,[\"id\"]]],null],\"is-loaded\",\"loading\"],null],\" \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedObjectId\"]],[32,1,[\"id\"]]],null],\"selected\"],null]]]],[15,\"src\",[32,2]],[14,\"alt\",\"Screenshot\"],[15,\"data-object-id\",[32,1,[\"id\"]]],[15,\"onload\",[30,[36,3],[[32,0],\"imageLoaded\",[32,1,[\"id\"]]],null]],[12],[13],[2,\"\\n        \"],[10,\"label\"],[12],[1,[30,[36,4],[[32,1,[\"timestamp\"]]],[[\"offset\"],[[32,0,[\"epoch\"]]]]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"included\",\"action\",\"format-duration\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/screenshots-list/template.hbs"
    }
  });

  _exports.default = _default;
});