define("flood/utils/format", ["exports", "d3-format"], function (_exports, _d3Format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatToPrecision = formatToPrecision;

  function formatToPrecision(value) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (precision <= 0) {
      return (0, _d3Format.format)(',d')(value);
    } else {
      return (0, _d3Format.format)(",.".concat(precision, "f"))(value);
    }
  }
});