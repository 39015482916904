define("flood/mixins/brush", ["exports", "d3-brush", "d3-selection"], function (_exports, _d3Brush, _d3Selection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function roundToSeconds(ms) {
    return Math.floor(ms / 1e3) * 1e3;
  }

  function roundToNearest() {
    var range = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1e3;
    return function round(ms) {
      return Math.round(ms / range) * range;
    };
  }

  function configureBrush(brush) {
    // let n = 22;
    // let a = (bottom - n) / 2;
    if (brush.node().__floodBrushConfigured) {
      return;
    }

    brush.select('rect.selection').attr('stroke', 'none'); // brush.selectAll('rect.handle')
    //   .attr('fill', `url(#handle-pattern-${elementId})`)
    //   // .attr('width', 11)
    //   // .attr('y', a)
    //   .style('visibility', 'visible');
    // brush.selectAll('rect.handle')
    //   .insert('line', ':first-child')
    //   .attr('x', 0.5)
    //   .attr('y1', top)
    //   .attr('x2', 0.5)
    //   .attr('y2', bottom);
    // brush.selectAll('rect.handle.handle--w').attr('x', -5);
    // brush.selectAll('rect.handle.handle--e').attr('x', -6);
    // brush.selectAll('rect.handle')
    //   .attr('rx', 2)
    //   .attr('ry', 2)
    //   .attr('height', 22);

    brush.node().__floodBrushConfigured = true;
  }

  function isEmptySelection(selection) {
    return !selection || selection[0] - selection[1] === 0;
  }

  function extentFromSelection(selection, scale) {
    var isEmpty = isEmptySelection(selection);
    var extent;

    if (isEmpty) {
      extent = scale.domain().map(function (date) {
        return date.getTime();
      });
    } else {
      extent = selection.map(scale.invert).map(roundToSeconds);
    }

    return extent;
  }

  function isFullSelection(selection, scale) {
    var extent = scale.range();
    var sel = selection.map(scale);
    return !isSubSelection(sel, extent);
  }

  function isSubSelection(extent1, extent2) {
    if (extent1 === null || extent2 === null) {
      return true;
    }

    return extent1[0] > extent2[0] || extent1[1] < extent2[1];
  }

  var _default = Ember.Mixin.create({
    clearBrushSelectionAfterBrush: true,
    snappingRange: 15e3,
    drawBrush: function drawBrush(xScale, newSelection) {
      var _this = this;

      if (!this.element) {
        return;
      }

      var brushElement = this.brushElement();
      var height = this.height;
      var width = this.width;
      var top = this.top;
      var clearBrushSelectionAfterBrush = this.clearBrushSelectionAfterBrush;
      var snappingRange = this.snappingRange;
      this.brush = (0, _d3Brush.brushX)().extent([[0, 0], [width, height]]).handleSize(11);
      brushElement.call(this.brush);
      configureBrush(brushElement, this.elementId, {
        bottom: height,
        top: top
      });

      if (!clearBrushSelectionAfterBrush) {
        if (isSubSelection(newSelection.map(xScale), xScale.range())) {
          this.brush.move(brushElement, newSelection.map(xScale));
        } else if (isFullSelection(newSelection, xScale)) {
          this.brush.move(brushElement, null);
        }
      }

      this.brush.on('brush', function () {
        try {
          var selection = _d3Selection.event.selection,
              sourceEvent = _d3Selection.event.sourceEvent;

          if (!sourceEvent || !selection) {
            return;
          }

          if (sourceEvent.constructor.name === 'BrushEvent') {
            return;
          }

          var brushExtent = extentFromSelection(selection, xScale);
          Ember.run.next(_this, _this.isBrushing, brushExtent, !selection);
        } catch (err) {// eslint-inline-disable no-empty-block
        }
      });
      this.brush.on('end', function () {
        try {
          var selection = _d3Selection.event.selection,
              sourceEvent = _d3Selection.event.sourceEvent;

          if (!sourceEvent) {
            return;
          }

          if (sourceEvent.constructor.name === 'BrushEvent' && !selection) {
            return;
          }

          var brushExtent = extentFromSelection(selection, xScale);

          if (selection && clearBrushSelectionAfterBrush) {
            _this.brush.move(brushElement, null);
          } else if (selection && snappingRange > 0) {
            var d0 = selection.map(xScale.invert);
            var d1 = d0.map(roundToNearest(snappingRange));
            brushElement.transition().call(_d3Selection.event.target.move, d1.map(xScale));
          }

          Ember.run.next(_this, _this.didBrush, brushExtent, !selection);
        } catch (err) {// eslint-inline-disable no-empty-block
        }
      });
    },
    isBrushing: function isBrushing() {},
    didBrush: function didBrush() {}
  });

  _exports.default = _default;
});