define("flood/components/account/teams-section/team-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WmXIQXg/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"label\"],[12],[1,[30,[36,1],[\"orgs.teams.form.name.label\"],null]],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"value\"],[12],[1,[32,0,[\"team\",\"name\"]]],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"label\"],[12],[1,[30,[36,1],[\"orgs.teams.form.description.label\"],null]],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"value\"],[12],[1,[32,0,[\"team\",\"description\"]]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[\"update team\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"flud-ui/button\",[[16,\"onclick\",[30,[36,2],[[32,0],\"editTeam\"],null]]],[[\"@style\"],[\"outline\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"orgs.teams.editBtn\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],null,[[\"show\"],[[32,0,[\"editingTeam\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"sheet\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[[32,0,[\"team\",\"name\"]]],[[\"length\"],[25]]]],[13],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,1],[\"orgs.teams.form.subTitle\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"primaryButtonTitle\",\"teams\",\"team\",\"arentTeamId\",\"onFinish\"],[[30,[36,1],[\"orgs.teams.form.saveButton.save\"],null],[32,0,[\"team\",\"descendantsAndSelf\"]],[32,0,[\"team\"]],[32,0,[\"team\",\"id\"]],[30,[36,2],[[32,0],\"hideSidesheet\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"shorten\",\"t\",\"action\",\"account/teams-section/team-form\",\"can\",\"if\",\"flood-ui/sidesheet\"]}",
    "meta": {
      "moduleName": "flood/components/account/teams-section/team-settings/template.hbs"
    }
  });

  _exports.default = _default;
});