define("flood/components/account/members-section/cell-items/role/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vMUouwuj",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[6,[37,1],[[32,0,[\"isUser\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"orgs.accounts.rolePrefix.account\"],null]],[2,\" \"],[1,[32,1,[\"role\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"isInvitation\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"orgs.accounts.rolePrefix.team\"],null]],[2,\" \"],[1,[32,1,[\"teamRole\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,1,[\"roleName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/account/members-section/cell-items/role/template.hbs"
    }
  });

  _exports.default = _default;
});