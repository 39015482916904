define("flood/components/flood-explorer/controls/measurement-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SwgpalD9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"indicator\"],[15,5,[32,0,[\"indicatorStyle\"]]],[12],[13],[2,\"\\n\"],[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/controls/measurement-label/template.hbs"
    }
  });

  _exports.default = _default;
});