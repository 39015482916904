define("flood/components/flood-ui/search-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIHLqxBs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"material-icons\"],[12],[2,\"search\"],[13],[2,\"\\n\"],[10,\"input\"],[15,\"placeholder\",[32,0,[\"placeholder\"]]],[15,\"oninput\",[30,[36,0],[[32,0],[32,0,[\"onInput\"]]],[[\"value\"],[\"target.value\"]]]],[14,4,\"text\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/search-input/template.hbs"
    }
  });

  _exports.default = _default;
});