define("flood/helpers/hash-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hashObject = hashObject;
  _exports.default = void 0;

  // hash seems to have bug when nested inside array helper, need to create a new one
  // https://github.com/emberjs/ember.js/issues/14738
  function hashObject(_, hash) {
    return Object.assign({}, hash);
  }

  var _default = Ember.Helper.helper(hashObject);

  _exports.default = _default;
});