define("flood/components/flood-ui/form/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YXUN6/ht",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[30,[36,2],[[27,[32,1]],[32,0,[\"label\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,\"for\",[32,0,[\"identifier\"]]],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"textarea\",[[16,\"placeholder\",[32,0,[\"placeholder\"]]],[16,\"required\",[32,0,[\"required\"]]]],[[\"@id\",\"@name\",\"@value\",\"@rows\",\"@cols\",\"@disabled\",\"@maxlength\",\"@wrap\",\"@readonly\",\"@autofocus\",\"@form\",\"@spellcheck\"],[[32,0,[\"identifier\"]],[32,0,[\"name\"]],[30,[36,4],[[30,[36,3],[[32,0,[\"data\"]],[32,0,[\"propertyName\"]]],null]],null],[32,0,[\"rows\"]],[32,0,[\"cols\"]],[32,0,[\"disabled\"]],[32,0,[\"maxlength\"]],[32,0,[\"wrap\"]],[32,0,[\"isReadonly\"]],[32,0,[\"autofocus\"]],[32,0,[\"form\"]],[32,0,[\"spellcheck\"]]]],null],[2,\"\"],[6,[37,1],[[32,0,[\"hint\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"hint\"],[12],[1,[32,0,[\"hint\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"error\"],[12],[1,[32,0,[\"error\",\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"or\",\"get\",\"mut\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/form/textarea/template.hbs"
    }
  });

  _exports.default = _default;
});