define("flood/components/stream-editor/launch/regions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QmpZg2OY",
    "block": "{\"symbols\":[\"region\"],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,0],[\"streamEditor.launch.fields.regions.title\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mdc-typography mdc-typography--subtitle2\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"streamEditor.launch.fields.regions.help\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n  \"],[8,\"power-select-multiple\",[[24,0,\"full-width\"]],[[\"@options\",\"@selected\",\"@placeholder\",\"@onChange\",\"@disabled\",\"@noMatchesMessage\",\"@closeOnSelect\"],[[32,0,[\"regions\"]],[32,0,[\"selectedRegions\"]],[30,[36,0],[\"streamEditor.launch.fields.regions.placeholder\"],null],[30,[36,1],[[32,0],\"changeHandler\"],null],[32,0,[\"disabled\"]],[30,[36,0],[\"streamEditor.launch.fields.regions.limitReached\"],[[\"count\"],[[32,0,[\"maxRegions\"]]]]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[[32,0,[\"account\",\"regions\",\"aws\"]],[32,1]],null]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"get\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/launch/regions/template.hbs"
    }
  });

  _exports.default = _default;
});