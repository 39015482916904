define("flood/helpers/scale-identity", ["exports", "ember-primer/helpers/scale-identity"], function (_exports, _scaleIdentity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scaleIdentity.default;
    }
  });
  Object.defineProperty(_exports, "scaleIdentity", {
    enumerable: true,
    get: function get() {
      return _scaleIdentity.scaleIdentity;
    }
  });
});