define("flood/components/account-menu/user-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sO8RrCp7",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"styleNamespace\"]]],[12],[2,\"\\n  \"],[8,\"ember-initials\",[],[[\"@name\",\"@seedText\",\"@fontSize\",\"@fontWeight\",\"@size\"],[[32,1,[\"fullName\"]],[32,1,[\"email\"]],52,100,56]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-wrapper\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"primary\"],[12],[1,[32,1,[\"fullName\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"nickname\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"nickname\"],[12],[2,\"“\"],[1,[32,1,[\"nickname\"]]],[2,\"”\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"p\"],[14,0,\"secondary\"],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/components/account-menu/user-details/template.hbs"
    }
  });

  _exports.default = _default;
});