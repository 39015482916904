define("flood/utils/series", ["exports", "flood/utils/internal-series"], function (_exports, _internalSeries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.createEmptySeries = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var partialSeries = function partialSeries(params) {
    return function (resolution) {
      return new _internalSeries.default({
        config: _objectSpread(_objectSpread({}, params), {}, {
          resolution: resolution
        })
      });
    };
  };

  var makeResolutionMapping = function makeResolutionMapping(_ref) {
    var _ref$resolutionInit = _slicedToArray(_ref.resolutionInit, 2),
        initial = _ref$resolutionInit[0],
        maximum = _ref$resolutionInit[1],
        params = _objectWithoutProperties(_ref, ["resolutionInit"]);

    var resolutions = new Map();
    var seriesForResolutionFn = partialSeries(params);
    resolutions.set(initial, seriesForResolutionFn(initial));

    if (initial !== 15) {
      resolutions.set(15, seriesForResolutionFn(15));
    }

    if (initial !== 1 && maximum === 1) {
      resolutions.set(1, seriesForResolutionFn(1));
    }

    return resolutions;
  };

  var proxyToInternalSeries = Ember.computed('_internalSeries.[]', {
    get: function get(key) {
      return Ember.get(this, "_internalSeries.".concat(key));
    }
  });
  var proxyToInternalSeriesMethodCall = Ember.computed('_internalSeries', {
    get: function get(key) {
      return Ember.get(this, "_internalSeries.".concat(key)).call(this._internalSeries);
    }
  });

  var proxyToInternalSeriesMethod = function proxyToInternalSeriesMethod(method) {
    return function () {
      var resolutions = this.resolutions,
          _activeResolution = this._activeResolution,
          config = this.config;
      var newResolutions = new Map();

      var _iterator = _createForOfIteratorHelper(resolutions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
              key = _step$value[0],
              val = _step$value[1];

          newResolutions.set(key, val[method].apply(val, arguments));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return Series.create({
        resolutions: newResolutions,
        _activeResolution: _activeResolution,
        config: config
      });
    };
  };

  var createEmptySeries = function createEmptySeries(seriesName, resolutionInit) {
    var isNumeric = !/traces|objects/.test(seriesName);
    var isRate = /passed|failed|transaction_rate/.test(seriesName);
    var sumAggregate = isRate || /concurrency|throughput/.test(seriesName);
    var config = {
      isRate: isRate,
      isNumeric: isNumeric,
      sumAggregate: sumAggregate,
      resolutionInit: resolutionInit
    };
    return Series.create({
      config: config
    });
  };

  _exports.createEmptySeries = createEmptySeries;
  var Series = Ember.Object.extend({
    /**
      An array containing two items: [initial, max]
      @type {Array}
    */
    resolutions: null,

    /**
       @type {Number}
      @private
     */
    _activeResolution: null,

    /**
     * Indicates this is a rate series, which changes all calculations to be based
     * on a 15 second time step per data point.
     *
     * @public
     * @type {Boolean}
     */
    isRate: false,
    config: null,
    init: function init() {
      this._super.apply(this, arguments);

      var options = (arguments.length <= 0 ? undefined : arguments[0]) || {};
      this.resolutions = options.resolutions || [];
      var config = this.config,
          _activeResolution = this._activeResolution;

      if (!_activeResolution) {
        this._activeResolution = config.resolutionInit[0];
        this.resolutions = makeResolutionMapping(config);
      }

      this.config = config;
      this._id = Ember.guidFor(this);
    },
    _internalSeries: Ember.computed('_activeResolution', 'resolutions', function () {
      var resolutions = this.resolutions;
      return resolutions.get(this._activeResolution);
    }),
    append: function append() {
      var rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var resolution = arguments.length > 1 ? arguments[1] : undefined;
      var internalSeries = this.resolutions.get(resolution) || new _internalSeries.default({
        config: _objectSpread(_objectSpread({}, this.config), {}, {
          resolution: resolution
        })
      });
      internalSeries.append(rows);
      this.resolutions.set(resolution, internalSeries);
      this.notifyPropertyChange('resolutions');
    },
    entries: proxyToInternalSeries,
    realValues: proxyToInternalSeries,
    labels: proxyToInternalSeries,
    size: Ember.computed('resolutions.[]', function () {
      var sizes = _toConsumableArray(this.resolutions.values()).map(function (_ref2) {
        var size = _ref2.size;
        return size;
      });

      return Math.max.apply(Math, _toConsumableArray(sizes.filter(function (s) {
        return s >= 0;
      })));
    }),
    isEmpty: proxyToInternalSeries,
    extent: proxyToInternalSeries,
    length: proxyToInternalSeries,
    domain: proxyToInternalSeries,
    duration: proxyToInternalSeries,
    durationInMs: proxyToInternalSeries,
    isNumeric: proxyToInternalSeries,
    slice: function slice(extent, resolution) {
      var resolutions = this.resolutions;
      var slicedResolutions = new Map();

      var _iterator2 = _createForOfIteratorHelper(resolutions),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _step2$value = _slicedToArray(_step2.value, 2),
              key = _step2$value[0],
              val = _step2$value[1];

          slicedResolutions.set(key, val.slice.apply(val, _toConsumableArray(extent)));
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      this.set('resolutions', slicedResolutions);
      this.set('_activeResolution', resolution);
    },
    filter: proxyToInternalSeriesMethod('filter'),
    filterBy: proxyToInternalSeriesMethod('filterBy'),

    /**
     * Nests all data points by a property such that each object contains
     * only values matching that element.
     *
     * @param  {String} property A property
     * @return {Map}    Nested map
     * @public
     */
    nestByLabel: function nestByLabel() {
      var _this = this;

      var nested = new Map();
      var labels = this._internalSeries.labels;

      var _iterator3 = _createForOfIteratorHelper(labels),
          _step3;

      try {
        var _loop = function _loop() {
          var label = _step3.value;
          nested.set(label, _this.filter(function (_ref3) {
            var l = _ref3.label;
            return l === label;
          }));
        };

        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      return nested;
    },
    sample: function sample() {
      var _this$_internalSeries;

      return (_this$_internalSeries = this._internalSeries).sample.apply(_this$_internalSeries, arguments);
    },
    quantile: function quantile() {
      var _this$_internalSeries2;

      return (_this$_internalSeries2 = this._internalSeries).quantile.apply(_this$_internalSeries2, arguments);
    },
    mean: proxyToInternalSeriesMethodCall,
    normalizedMean: proxyToInternalSeriesMethodCall,
    deviation: proxyToInternalSeriesMethodCall,
    percentile: proxyToInternalSeriesMethodCall,
    median: proxyToInternalSeriesMethodCall,
    sum: proxyToInternalSeriesMethodCall,
    max: proxyToInternalSeriesMethodCall,
    min: proxyToInternalSeriesMethodCall,
    last: proxyToInternalSeriesMethodCall
  });
  var _default = Series;
  _exports.default = _default;
});