define("flood/templates/account/orgs/org/teams/team/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bvkx5tF3",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/teams-section\",[],[[\"@org\",\"@teams\",\"@team\",\"@selection\"],[[32,0,[\"model\",\"org\"]],[32,0,[\"model\",\"teams\"]],[32,0,[\"model\",\"team\"]],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/account/orgs/org/teams/team/index.hbs"
    }
  });

  _exports.default = _default;
});