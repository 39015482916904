define("flood/components/account/projects-section/cell-items/permission/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xuo7N/MA",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"data\",\"permission\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"capitalize\"]}",
    "meta": {
      "moduleName": "flood/components/account/projects-section/cell-items/permission/template.hbs"
    }
  });

  _exports.default = _default;
});