define("flood/validations/user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UserDetailValidations = _exports.PasswordChangeValidations = _exports.default = void 0;
  var _default = {};
  _exports.default = _default;
  var PasswordChangeValidations = {
    newPassword: [(0, _validators.validateLength)({
      min: 8
    })],
    confirmNewPassword: (0, _validators.validateConfirmation)({
      on: 'newPassword',
      message: 'Doesn’t match new password'
    })
  };
  _exports.PasswordChangeValidations = PasswordChangeValidations;
  var UserDetailValidations = {
    fullName: [(0, _validators.validatePresence)(true)],
    email: [(0, _validators.validatePresence)(true)]
  };
  _exports.UserDetailValidations = UserDetailValidations;
});