define("flood/components/billing/coupon-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+5SMa3t8",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"flud-ui/alert\",[[17,1]],[[\"@icon\"],[\"card_giftcard\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"coupon\",\"name\"]],\"complimentary\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"billing.coupon.complimentary\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"billing.coupon.default\"],[[\"discount\",\"htmlSafe\"],[[32,0,[\"discount\"]],true]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/billing/coupon-details/template.hbs"
    }
  });

  _exports.default = _default;
});