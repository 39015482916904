define("flood/templates/app-shell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XWALCdVA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"global-header\",[],[[\"@currentUser\"],[null]],null],[2,\"\\n\"],[8,\"global-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"flood-ui/preloader\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/app-shell.hbs"
    }
  });

  _exports.default = _default;
});