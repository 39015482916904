define("flood/components/billing/account-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oIlOwTLw",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,[37,3],null,[[\"data\",\"onSubmit\"],[[30,[36,2],[[32,0,[\"account\"]]],null],[30,[36,1],[[32,0],\"saveChanges\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@label\"],[\"name\",\"Company Name\"]],null],[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@label\"],[\"email\",\"Billing email\"]],null],[2,\"\\n  \"],[8,[32,1,[\"submit\"]],[[24,0,\"secondary\"]],[[\"@label\"],[[30,[36,0],[\"billing.paymentDetails.accountInformation.call-to-action\"],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"changeset\",\"flood-ui/form\"]}",
    "meta": {
      "moduleName": "flood/components/billing/account-details/template.hbs"
    }
  });

  _exports.default = _default;
});