define("flood/components/flood-options-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q2A6QjQd",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"dotted-menu\"],[12],[2,\"\\n  \"],[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@allowClear\",\"@matchTriggerWidth\",\"@triggerClass\",\"@triggerComponent\",\"@onChange\"],[[32,0,[\"options\"]],[34,0],false,false,\"flood-menu-trigger\",[30,[36,1],[[32,0,[\"triggerComponentName\"]]],null],[30,[36,2],[[32,0],\"selectMenuItem\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[1,[32,1,[\"icon\"]]],[13],[2,\"\\n    \"],[1,[32,1,[\"title\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"component\",\"action\"]}",
    "meta": {
      "moduleName": "flood/components/flood-options-menu/template.hbs"
    }
  });

  _exports.default = _default;
});