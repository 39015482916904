define("flood/components/flood-explorer/issues/list/message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BMIwjw4I",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body1\"],[12],[2,\"\\n  \"],[1,[32,0,[\"title\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body2\"],[12],[2,\"\\n  \"],[1,[32,0,[\"description\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/issues/list/message/template.hbs"
    }
  });

  _exports.default = _default;
});