define("flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/extractable-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qZ8EPftl",
    "block": "{\"symbols\":[],\"statements\":[[11,\"span\"],[4,[38,0],[\"mouseenter\",[32,0,[\"onMouseEnter\"]]],null],[4,[38,0],[\"mouseleave\",[32,0,[\"onMouseLeave\"]]],null],[4,[38,0],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n  \"],[1,[34,1]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"value\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/extractable-header/template.hbs"
    }
  });

  _exports.default = _default;
});