define("flood/components/account-suspended/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gM6pTrIn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[1,[30,[36,0],[\"suspended.title\"],null]],[13],[2,\"\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"suspended.message\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/account-suspended/template.hbs"
    }
  });

  _exports.default = _default;
});