define("flood/utils/prefix-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prefixKeys = prefixKeys;

  function prefixKeys(props, prefix) {
    return Object.keys(props).reduce(function (memo, key) {
      memo[Ember.String.camelize("".concat(prefix).concat(key))] = props[key];
      return memo;
    }, {});
  }
});