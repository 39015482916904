define("flood/utils/subscribed-plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatSubscribedPlans = formatSubscribedPlans;

  function formatSubscribedPlans() {
    var plans = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return (plans || []).map(function (_ref) {
      var plan = _ref.plan,
          quantity = _ref.quantity;
      return "".concat(plan, ":").concat(quantity);
    });
  }
});