define("flood/utils/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMs = isMs;
  _exports.functor = functor;
  var EPOCH = new Date(2000, 1, 1).getTime();

  function isMs(value) {
    return value > EPOCH || value > 1000;
  }

  function functor(v) {
    if (typeof v === 'function') {
      return v;
    } else {
      return function () {
        return v;
      };
    }
  }
});