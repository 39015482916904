define("flood/components/account/organizations-section/cell-items/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZjouZ4eU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"account.orgs.org\",[32,0,[\"data\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,0,[\"data\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/account/organizations-section/cell-items/name/template.hbs"
    }
  });

  _exports.default = _default;
});