define("flood/components/usage-widget/free-trial/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2rOYO0A3",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@classNames\"],[\"account.usage\",\"icon-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[2,\"money_off\"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"header.account.usage.free\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/usage-widget/free-trial/template.hbs"
    }
  });

  _exports.default = _default;
});