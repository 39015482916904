define("flood/components/flood-ui/messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NiiflaE+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"showWhen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"flud-ui/alert\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"message\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"message\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/messages/template.hbs"
    }
  });

  _exports.default = _default;
});