define("flood/templates/sign-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9UlFAvQq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"sign-up-form\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/sign-up.hbs"
    }
  });

  _exports.default = _default;
});