define("flood/components/account/cloud-configuration/grid-hosting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mtWurzec",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"flud-ui/app/card\",[],[[\"@title\",\"@subtitle\"],[[30,[36,0],[\"account.cloud-configuration.grid-hosting.title\"],null],[30,[36,0],[\"account.cloud-configuration.grid-hosting.subtitle\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"flud-ui/alert\",[[24,0,\"outline\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"account.cloud-configuration.grid-hosting.hint\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"account/cloud-configuration/grid-hosting/aws\",[],[[\"@credentials\"],[[30,[36,2],[\"provider\",\"aws\",[35,1]],null]]],null],[2,\"\\n    \"],[8,\"account/cloud-configuration/grid-hosting/azure\",[],[[\"@credentials\"],[[30,[36,2],[\"provider\",\"azure\",[35,1]],null]]],null],[2,\"\\n    \"],[8,\"account/cloud-configuration/grid-hosting/gcp\",[],[[\"@credentials\"],[[30,[36,2],[\"provider\",\"gcp\",[35,1]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"credentials\",\"filter-by\"]}",
    "meta": {
      "moduleName": "flood/components/account/cloud-configuration/grid-hosting/template.hbs"
    }
  });

  _exports.default = _default;
});