define("flood/components/billing/cancel-subscription/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LdZrLHyu",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[8,\"flud-ui/app/card\",[],[[\"@title\",\"@titleClass\"],[[30,[36,0],[\"billing.cancellation.title\"],null],\"danger-zone\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[14,0,\"mdc-typography\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"billing.cancellation.warningMessage\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"flud-ui/button\",[[24,0,\"danger\"],[4,[38,3],[\"click\",[30,[36,2],[[32,0],\"showForm\"],null]],null]],[[\"@style\",\"@disabled\"],[\"raised\",[30,[36,1],[[32,0,[\"dependencyLoaded\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"billing.cancellation.buttonLabel\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"not\",\"action\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/billing/cancel-subscription/template.hbs"
    }
  });

  _exports.default = _default;
});