define("flood/components/flood-ui/form/submit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cl3f1TEt",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[8,\"flud-ui/button\",[[17,2],[16,4,[34,2]]],[[\"@style\",\"@disabled\"],[[32,0,[\"style\"]],[32,0,[\"isDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"inFlight\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"inFlightLabel\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"type\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/form/submit/template.hbs"
    }
  });

  _exports.default = _default;
});