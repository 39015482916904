define("flood/components/account/projects-section/project-form/permission-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9p5wd7wU",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,8],[[32,0,[\"options\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"li\"],[24,\"role\",\"button\"],[16,0,[30,[36,2],[[30,[36,1],[[32,1],[32,0,[\"selectedPermission\"]]],null],\"selected\"],null]],[4,[38,3],[[32,0],[32,0,[\"select\",\"actions\",\"choose\"]],[32,1]],null],[12],[2,\"\\n        \"],[10,\"b\"],[12],[1,[30,[36,4],[[32,1]],null]],[13],[2,\": \"],[1,[30,[36,0],[[30,[36,5],[\"orgs.projects.form.permission.options.\",[32,1]],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"orgs.projects.form.permission.loading\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"action\",\"capitalize\",\"concat\",\"-track-array\",\"each\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "flood/components/account/projects-section/project-form/permission-options/template.hbs"
    }
  });

  _exports.default = _default;
});