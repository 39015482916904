define("flood/validations/grid", ["exports", "ember-changeset-validations/validators", "flood/validators/max-grid-duration"], function (_exports, _validators, _maxGridDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GCPGridValidations = _exports.AWSValidations = _exports.AzureHostingConfigurationValidator = _exports.AWSCustomVPCValidations = _exports.DNSValidations = _exports.AWSDefaultVPCValidations = _exports.AWSClassicValidations = _exports.HostedValidations = _exports.default = _exports.GridLifecycleValidations = _exports.BasicGridValidations = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var BasicGridValidations = {
    region: (0, _validators.validatePresence)(true),
    instanceQuantity: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      integer: true,
      gte: 1 // lte: 90

    }), (0, _maxGridDuration.accountLimitValidation)(function (account, newValue) {
      var gridNodesLimit = account.getWithDefault('gridNodesLimit', 90);

      if (newValue > gridNodesLimit) {
        return "Your account is limited to ".concat(gridNodesLimit, " ").concat(gridNodesLimit === 1 ? 'node' : 'nodes');
      }

      return true;
    })],
    infrastructure: (0, _validators.validatePresence)(true)
  };
  _exports.BasicGridValidations = BasicGridValidations;
  var GridLifecycleValidations = {
    queueAtInHours: [(0, _validators.validateNumber)({
      gte: 0,
      lte: 48,
      allowBlank: false,
      message: 'must be within 0 and 48 hours'
    })],
    stopAfterInHours: [(0, _validators.validateNumber)({
      allowBlank: false,
      gte: 0.2,
      // lte: 48,
      message: 'must be within 0.2 and 48 hours'
    }), (0, _maxGridDuration.accountLimitValidation)(function (account, newValue) {
      if (account.getWithDefault('gridNodesLimit', 90) === 1) {
        return Number(newValue) <= 1 || 'Your account is limited to 1 hour tests';
      }

      return true;
    })]
  };
  _exports.GridLifecycleValidations = GridLifecycleValidations;
  var _default = BasicGridValidations;
  _exports.default = _default;

  var HostedValidations = _objectSpread(_objectSpread({}, BasicGridValidations), {}, {
    instanceType: (0, _validators.validatePresence)(true),
    awsSpotPrice: (0, _validators.validateNumber)({
      gt: 0,
      message: 'must be more than 0 cents',
      allowBlank: false
    })
  });

  _exports.HostedValidations = HostedValidations;
  var AWSClassicValidations = {
    credential: (0, _validators.validatePresence)(true)
  };
  _exports.AWSClassicValidations = AWSClassicValidations;

  var AWSDefaultVPCValidations = _objectSpread({}, AWSClassicValidations);

  _exports.AWSDefaultVPCValidations = AWSDefaultVPCValidations;
  var DNSValidations = {
    dns: (0, _validators.validatePresence)({
      presence: true,
      message: 'At least one DNS server is required'
    })
  };
  _exports.DNSValidations = DNSValidations;

  var AWSCustomVPCValidations = _objectSpread({
    // awsPlatform: validatePresence({ presence: true, message: 'Platform must be selected' }),
    // awsVpcSecurityGroup: validatePresence({ presence: true, message: 'Security Group must be selected' }),
    awsVpcIdentifier: (0, _validators.validatePresence)({
      presence: true,
      message: 'VPC must be selected'
    })
  }, DNSValidations);

  _exports.AWSCustomVPCValidations = AWSCustomVPCValidations;

  var AzureHostingConfigurationValidator = _objectSpread({}, DNSValidations);

  _exports.AzureHostingConfigurationValidator = AzureHostingConfigurationValidator;
  var AWSValidations = {};
  _exports.AWSValidations = AWSValidations;

  var GCPGridValidations = _objectSpread(_objectSpread({}, BasicGridValidations), {}, {
    gcpSubnet: (0, _validators.validatePresence)(true)
  });

  _exports.GCPGridValidations = GCPGridValidations;
});