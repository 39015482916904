define("flood/serializers/security-group", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    primaryKey: 'group_id',
    attrs: {
      name: {
        key: 'group_name'
      }
    }
  });

  _exports.default = _default;
});