define("flood/components/account/integration-form/dynatrace/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8L0Bpvp0",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"flood-ui/form\",[],[[\"@data\",\"@onSubmit\"],[[30,[36,0],[[32,0,[\"credentialInfo\"]],[32,0,[\"DynatraceCredentialValidations\"]]],null],[30,[36,1],[[32,0],\"saveCredential\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\"],[\"environmentId\",[30,[36,2],[\"integrations.dynatrace.form.environmentId.placeholder\"],null],[30,[36,2],[\"integrations.dynatrace.form.environmentId.label\"],null]]],null],[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\"],[\"genericApiKey\",[30,[36,2],[\"integrations.dynatrace.form.apiKey.placeholder\"],null],[30,[36,2],[\"integrations.dynatrace.form.apiKey.label\"],null]]],null],[2,\"\\n  \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@placeholder\",\"@label\"],[\"description\",[30,[36,2],[\"integrations.dynatrace.form.name.placeholder\"],null],[30,[36,2],[\"integrations.dynatrace.form.name.label\"],null]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"Actions\"],[12],[2,\"\\n    \"],[8,[32,1,[\"submit\"]],[],[[\"@label\"],[\"Save\"]],null],[2,\"\\n    \"],[8,\"flud-ui/button\",[[4,[38,3],[\"click\",[30,[36,1],[[32,0],\"cancel\"],null]],null]],[[\"@label\"],[\"Cancel\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"changeset\",\"action\",\"t\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/account/integration-form/dynatrace/template.hbs"
    }
  });

  _exports.default = _default;
});