define("flood/initializers/register-hljs-langauges", ["exports", "ember-code-block/initializers/register-hljs-langauges"], function (_exports, _registerHljsLangauges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _registerHljsLangauges.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _registerHljsLangauges.initialize;
    }
  });
});