define("flood/components/flood-explorer/issues/list/item/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Al9Cb91",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"mdc-typography mdc-typography--overline\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],[\"explorer.issue.category.\",[32,0,[\"issue\",\"category\"]],\".\",[32,0,[\"issue\",\"title\"]],\".title\"],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body1\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],[\"explorer.issue.category.\",[32,0,[\"issue\",\"category\"]],\".\",[32,0,[\"issue\",\"title\"]],\".\",[32,0,[\"issue\",\"description\"]]],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/issues/list/item/header/template.hbs"
    }
  });

  _exports.default = _default;
});