define("flood/components/expansion-panel/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q43hxTpx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isExpanded\"]]],null,[[\"default\"],[{\"statements\":[[10,\"content\"],[15,0,[32,0,[\"classNames\"]]],[12],[18,1,null],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/components/expansion-panel/content/template.hbs"
    }
  });

  _exports.default = _default;
});