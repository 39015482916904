define("flood/system/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.snapDown = snapDown;
  _exports.snapUp = snapUp;
  _exports.default = void 0;
  var _default = {
    round: function round(value) {
      var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      return parseFloat(value, 10).toFixed(precision);
    },
    snap: function snap(value) {
      var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      return Math.round(value / precision) * precision;
    },
    snapDown: function snapDown(value) {
      var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      return Math.floor(value / precision) * precision;
    },
    snapUp: function snapUp(value) {
      var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      return Math.ceil(value / precision) * precision;
    }
  };
  _exports.default = _default;

  function snapDown(value) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return Math.floor(value / precision) * precision;
  }

  function snapUp(value) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return Math.ceil(value / precision) * precision;
  }
});