define("flood/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PERMISSIONS = _exports.NO_PERMISSION = _exports.PERMISSION_READ = _exports.PERMISSION_WRITE = _exports.PERMISSION_ADMIN = _exports.ROLE_MEMBER = _exports.ROLE_MANAGER = _exports.ROLE_OWNER = _exports.EC2_CLASSIC = _exports.EC2_CUSTOM_VPC = _exports.EC2_DEFAULT_VPC = void 0;
  var EC2_DEFAULT_VPC = 'ec2_default_vpc';
  _exports.EC2_DEFAULT_VPC = EC2_DEFAULT_VPC;
  var EC2_CUSTOM_VPC = 'ec2_custom_vpc';
  _exports.EC2_CUSTOM_VPC = EC2_CUSTOM_VPC;
  var EC2_CLASSIC = 'ec2_classic'; // Organizational role

  _exports.EC2_CLASSIC = EC2_CLASSIC;
  var ROLE_OWNER = 'owner';
  _exports.ROLE_OWNER = ROLE_OWNER;
  var ROLE_MANAGER = 'manager';
  _exports.ROLE_MANAGER = ROLE_MANAGER;
  var ROLE_MEMBER = 'member'; // Operational permission

  _exports.ROLE_MEMBER = ROLE_MEMBER;
  var PERMISSION_ADMIN = 'admin';
  _exports.PERMISSION_ADMIN = PERMISSION_ADMIN;
  var PERMISSION_WRITE = 'write';
  _exports.PERMISSION_WRITE = PERMISSION_WRITE;
  var PERMISSION_READ = 'read';
  _exports.PERMISSION_READ = PERMISSION_READ;
  var NO_PERMISSION = '';
  _exports.NO_PERMISSION = NO_PERMISSION;
  var PERMISSIONS = [PERMISSION_ADMIN, PERMISSION_WRITE, PERMISSION_READ];
  _exports.PERMISSIONS = PERMISSIONS;
});