define("flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/xml", ["exports", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/const", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/divert"], function (_exports, _const, _divert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettyXML = prettyXML;
  _exports.initEventClickForXml = initEventClickForXml;

  function prettyXML(xml) {
    var formatted = '';
    xml = xml.replace(/(>)(<)(\/*)/g, '$1\r\n$2$3');
    var pad = 0;
    xml.split('\r\n').forEach(function (node) {
      var indent = 0;

      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad !== 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = '';

      for (var i = 0; i < pad; i++) {
        padding += '  ';
      }

      formatted += padding + node + '\r\n';
      pad += indent;
    });
    return formatted;
  }

  function isOpenTag(element) {
    return /<[a-zA-Z]+(>|.*?[^?]>)/.test(element.textContent);
  }

  function getPath(pathArr, element, rootName, mapKeyIndex) {
    var childNodes = element.childNodes;

    for (var i = 0; i < childNodes.length; i++) {
      var child = childNodes[i];
      var childNodeName = child.nodeName;
      var childNodeValue = child.nodeValue || child.childNodes;

      if (childNodeName === '#text') {
        continue;
      }

      var path = "".concat(rootName, "/").concat(childNodeName);

      if (mapKeyIndex[path] >= 1) {
        mapKeyIndex[path] += 1;
      } else {
        mapKeyIndex[path] = 1;
      }

      childNodeValue = childNodeValue.length === 1 && childNodeValue[0].nodeName === '#text' ? childNodeValue[0].data : childNodeValue;
      pathArr.push([childNodeName, "".concat(path, "[").concat(mapKeyIndex[path], "]"), childNodeValue]);

      if (child.childNodes && child.childNodes.length > 0) {
        getPath(pathArr, child, "".concat(path, "[").concat(mapKeyIndex[path], "]"), mapKeyIndex);
      }
    }
  }

  function mapXMLTags(pathArr, tagArr) {
    pathArr.map(function (item, index) {
      var xPath = item[1];
      var value = item[2];
      tagArr[index].setAttribute(_const.XML_PATH, xPath);
      tagArr[index].setAttribute(_const.XML_VALUE, value);
      var elmClass = tagArr[index].getAttribute(_const.CLASS_NAME);
      tagArr[index].setAttribute(_const.CLASS_NAME, "".concat(elmClass, " highlight-key"));
    });
  }

  function onClick(element, context) {
    var key = element.getAttribute(_const.XML_PATH);
    var value = element.getAttribute(_const.XML_VALUE);
    context.set(_const.CURRENT_KEY, key);
    context.set(_const.CURRENT_VALUE, value);
    context.set(_const.CURRENT_NODE_DATA, "<span class=\"modal-key\">".concat(key, "</span> = <span class=\"modal-value\">").concat((0, _divert.truncate)(value), "</span>"));
    context.set(_const.SELECTED_VARIABLES, {
      name: value
    });
    context.set(_const.ASSERT_DISABLE_FLAG, false);
    context.set(_const.SHOW_MODAL, true);
  }

  function initEventClickForXml(data, context) {
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(data, 'text/xml');
    var pathArr = [];
    var mapKeyIndex = {};

    for (var i = 0; i < xmlDoc.childNodes.length; i++) {
      var element = xmlDoc.childNodes[i];
      if (element.tagName === 'xml') continue;
      pathArr.push([element.nodeName, "/".concat(element.nodeName), element.childNodes]);
      getPath(pathArr, element, "/".concat(element.nodeName), mapKeyIndex);
    }

    setTimeout(function () {
      var markupTags = document.getElementsByClassName(_const.MARKUP_XML);

      if (markupTags && markupTags.length === pathArr.length * 2) {
        var tagArray = [];

        var _loop = function _loop(_i) {
          var elm = markupTags[_i];

          if (isOpenTag(elm)) {
            elm.addEventListener('click', function () {
              onClick(elm, context);
            });
            tagArray.push(elm);
          }
        };

        for (var _i = 0; _i < markupTags.length; _i++) {
          _loop(_i);
        }

        mapXMLTags(pathArr, tagArray);
      } else {
        Ember.debug('Xml elements does not render on screen');
      }

      (0, _divert.setLoadingMask)(false);
    }, 200);
  }
});