define("flood/utils/binary-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nearest = nearest;

  /**
   * Binary searches items for the nearest item to the searchSalue.
   *
   * @param items Items to search through. Must be sorted by the value returned by getter.
   * @param searchValue Value to search for the nearest item to.
   * @param getter Function to extract a value from an item.
   */
  function nearest(items, searchValue, getter) {
    if (items.length === 0) return;
    var start = 0;
    var end = items.length - 1;

    while (end - start > 1) {
      var index = start + Math.floor((end - start) / 2);
      var indexValue = getter(items[index]);

      if (indexValue === searchValue) {
        return items[index];
      } else if (indexValue > searchValue) {
        end = index;
      } else {
        start = index;
      }
    }

    var leftValue = getter(items[start]);
    var rightValue = getter(items[end]);

    if (searchValue - leftValue < rightValue - searchValue) {
      return items[start];
    } else {
      return items[end];
    }
  }
});