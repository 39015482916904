define("flood/components/infrastructure-list/grid-node/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JuMt7fMG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"g\"],[12],[2,\"\\n  \"],[10,\"rect\"],[14,\"width\",\"16\"],[14,\"height\",\"16\"],[15,\"fill\",[32,0,[\"fillColor\"]]],[14,\"y\",\"0\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/infrastructure-list/grid-node/template.hbs"
    }
  });

  _exports.default = _default;
});