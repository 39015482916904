define("flood/components/flud-ui/app/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gv9mn4T/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[32,0,[\"title\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"card__header \",[32,0,[\"headerClass\"]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card__headline\"],[12],[2,\"\\n      \"],[10,\"h2\"],[15,0,[31,[\"card__title mdc-typography mdc-typography--headline6 \",[32,0,[\"titleClass\"]]]]],[12],[1,[32,0,[\"title\"]]],[13],[2,\"\\n\"],[6,[37,4],[[32,0,[\"subtitle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[15,0,[31,[\"card__subtitle mdc-typography mdc-typography--subtitle2 \",[32,0,[\"subtitleClass\"]]]]],[12],[1,[32,0,[\"subtitle\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[18,1,[[30,[36,3],null,[[\"header\"],[[30,[36,2],[\"flud-ui/blank-template\"],[[\"tagName\",\"class\"],[\"div\",[30,[36,1],[\" \",[30,[36,0],[\"card__header-content\",[32,0,[\"headerContentClass\"]]],null]],null]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,[[30,[36,3],null,[[\"header\"],[[30,[36,2],[\"flud-ui/blank-template\"],[[\"tagName\",\"class\"],[\"div\",[30,[36,1],[\" \",[30,[36,0],[\"card__header\",[32,0,[\"headerClass\"]]],null]],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,[[30,[36,3],null,[[\"content\"],[[30,[36,2],[\"flud-ui/blank-template\"],[[\"tagName\",\"class\"],[\"div\",[30,[36,1],[\" \",[30,[36,0],[\"mdc-card__primary card__primary\",[32,0,[\"contentClass\"]]],null]],null]]]]]]]]],[2,\"\\n\"],[18,1,[[30,[36,3],null,[[\"footer\"],[[30,[36,2],[\"flud-ui/blank-template\"],[[\"tagName\",\"class\"],[\"div\",[30,[36,1],[\" \",[30,[36,0],[\"card__footer\",[32,0,[\"footerClass\"]]],null]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"join\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/app/card/template.hbs"
    }
  });

  _exports.default = _default;
});