define("flood/components/expansion-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7qPAsbGd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[32,0,[\"isExpanded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"changeset\"]],[30,[36,2],null,[[\"isExpanded\",\"header\",\"content\",\"footer\",\"navigateToNextSection\",\"saveAndContinue\",\"save\",\"discardChanges\"],[[32,0,[\"isExpanded\"]],[30,[36,1],[\"expansion-panel/header\"],[[\"isExpanded\",\"isCollapsible\",\"stepNumber\",\"action\",\"changeset\"],[[32,0,[\"isExpanded\"]],[32,0,[\"isCollapsible\"]],[32,0,[\"stepNumber\"]],[30,[36,0],[[32,0],\"toggleExpanded\",[32,0,[\"changeset\"]]],null],[32,0,[\"changeset\"]]]]],[30,[36,1],[\"expansion-panel/content\"],[[\"isExpanded\",\"changeset\"],[[32,0,[\"isExpanded\"]],[32,0,[\"changeset\"]]]]],[30,[36,1],[\"expansion-panel/footer\"],[[\"isExpanded\",\"changeset\",\"saveAndContinue\",\"discardChanges\"],[[32,0,[\"isExpanded\"]],[32,0,[\"changeset\"]],[30,[36,0],[[32,0],\"saveAndContinue\",[32,0,[\"changeset\"]]],null],[30,[36,0],[[32,0],\"discardChanges\",[32,0,[\"changeset\"]]],null]]]],[30,[36,0],[[32,0],\"navigateToNextSection\",[32,0,[\"changeset\"]]],null],[30,[36,0],[[32,0],\"saveAndContinue\",[32,0,[\"changeset\"]]],null],[30,[36,0],[[32,0],\"save\",[32,0,[\"changeset\"]]],null],[30,[36,0],[[32,0],\"discardChanges\",[32,0,[\"changeset\"]]],null]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"changeset\"]],[30,[36,2],null,[[\"isExpanded\",\"header\"],[[32,0,[\"isExpanded\"]],[30,[36,1],[\"expansion-panel/header\"],[[\"isExpanded\",\"isCollapsible\",\"stepNumber\",\"action\",\"changeset\"],[[32,0,[\"isExpanded\"]],[32,0,[\"isCollapsible\"]],[32,0,[\"stepNumber\"]],[30,[36,0],[[32,0],\"toggleExpanded\",[32,0,[\"changeset\"]]],null],[32,0,[\"changeset\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/expansion-panel/template.hbs"
    }
  });

  _exports.default = _default;
});