define("flood/templates/eol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZWRouASS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"eol-notice\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        Tricentis Flood will no longer be available after \"],[10,\"strong\"],[12],[2,\"June 30, 2024\"],[13],[2,\". Please read the Tricentis\\n        Support Hub\\n        \"],[10,\"a\"],[14,6,\"https://support-hub.tricentis.com/open?id=post&number=NEW0001385\"],[12],[2,\"newsroom post\"],[13],[2,\" for more\\n        information.\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/eol.hbs"
    }
  });

  _exports.default = _default;
});