define("flood/components/infrastructure/metrics-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HAqR7sPr",
    "block": "{\"symbols\":[\"metrics\",\"name\"],\"statements\":[[6,[37,6],[[32,0,[\"hydrometerIsDisabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"padding-v-1\"],[12],[2,\"\\n\"],[6,[37,5],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"padding-h-2\"],[14,\"center\",\"\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Loading \"],[1,[32,0,[\"type\"]]],[2,\" usage...\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[32,0,[\"data\",\"usage\",\"cpu\",\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"padding-h-1\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[14,\"center\",\"\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"color\"],[\"black\"]]]],[2,\" \"],[10,\"p\"],[14,0,\"secondary\"],[12],[2,\"Waiting for Hydrometer agent to report in...\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"metrics\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],null,[[\"metricName\",\"seriesMetrics\",\"cursorPosition\"],[[32,2],[32,1],[32,0,[\"cursorData\"]]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"infrastructure/metrics-timeline\",\"-each-in\",\"each\",\"flood-spinner\",\"eq\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "flood/components/infrastructure/metrics-details/template.hbs"
    }
  });

  _exports.default = _default;
});