define("flood/components/account/usage/history/cells/flood-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GNCHF0ga",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"flood.timeline\",[32,0,[\"data\",\"vum\",\"flood\",\"uuid\"]]]],[[\"default\"],[{\"statements\":[[1,[32,0,[\"data\",\"vum\",\"flood\",\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/account/usage/history/cells/flood-link/template.hbs"
    }
  });

  _exports.default = _default;
});