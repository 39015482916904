define("flood/components/flud-ui/button/link-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b7sDYE2c",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"flud-ui/icon\",[[24,0,\"mdc-button__icon\"],[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\"],[[32,0,[\"icon\"]],\"sm\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"span\"],[14,0,\"mdc-button__label\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"label\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/button/link-to/template.hbs"
    }
  });

  _exports.default = _default;
});