define("flood/validations/registration", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ConfirmPasswordValidations = _exports.EmailSignUpValidations = _exports.SocialSignUpValidations = _exports.RegistrationValidations = void 0;
  var RegistrationValidations = {
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email',
      message: 'Must be a valid email address'
    })]
  };
  _exports.RegistrationValidations = RegistrationValidations;
  var SocialSignUpValidations = {
    fullName: [(0, _validators.validatePresence)(true)],
    acceptTerms: [(0, _validators.validateInclusion)({
      list: [true],
      message: 'You must accept the terms of service and privacy policy.'
    })]
  };
  _exports.SocialSignUpValidations = SocialSignUpValidations;
  var EmailSignUpValidations = {
    fullName: [(0, _validators.validatePresence)(true)],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: '^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{10,}$',
      message: 'Password must be minimum 10 characters in length and contain a combination of mixed case alphabetic, numeric, and special characters.'
    })],
    acceptTerms: [(0, _validators.validateInclusion)({
      list: [true],
      message: 'You must accept the terms of service and privacy policy.'
    })]
  };
  _exports.EmailSignUpValidations = EmailSignUpValidations;
  var ConfirmPasswordValidations = {
    password: [(0, _validators.validatePresence)(true)],
    acceptTerms: [(0, _validators.validateInclusion)({
      list: [true],
      message: 'You must accept the terms of service and privacy policy.'
    })]
  };
  _exports.ConfirmPasswordValidations = ConfirmPasswordValidations;
});