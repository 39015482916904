define("flood/components/account-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRDacYUV",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[8,\"power-select\",[],[[\"@options\",\"@allowClear\",\"@matchTriggerWidth\",\"@triggerClass\",\"@triggerComponent\",\"@beforeOptionsComponent\",\"@onChange\"],[[32,0,[\"options\"]],false,false,\"account-menu-trigger icon-button\",[30,[36,0],[\"account-menu/trigger\"],[[\"user\"],[[32,0,[\"session\",\"currentUser\"]]]]],[30,[36,0],[\"account-menu/user-details\"],[[\"user\"],[[32,0,[\"session\",\"currentUser\"]]]]],[30,[36,1],[[32,0],\"selectMenuItem\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,\"data-intercom-target\",[31,[\"profile-\",[32,1,[\"key\"]]]]],[12],[2,\"\\n    \"],[8,\"flud-ui/icon\",[],[[\"@name\"],[[32,1,[\"icon\"]]]],null],[2,\" \"],[1,[32,1,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"action\"]}",
    "meta": {
      "moduleName": "flood/components/account-menu/template.hbs"
    }
  });

  _exports.default = _default;
});