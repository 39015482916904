define("flood/templates/account/orgs/org/teams/team/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yvv4CXhH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/projects-section/team-context\",[],[[\"@org\",\"@team\",\"@teamProjects\"],[[32,0,[\"model\",\"org\"]],[32,0,[\"model\",\"team\"]],[30,[36,0],[\"isNew\",false,[32,0,[\"model\",\"team\",\"teamProjects\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filter-by\"]}",
    "meta": {
      "moduleName": "flood/templates/account/orgs/org/teams/team/projects.hbs"
    }
  });

  _exports.default = _default;
});