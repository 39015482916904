define("flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/divert", ["exports", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/json", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/xml", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/html", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/const"], function (_exports, _json, _xml, _html, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isJSON = isJSON;
  _exports.isXML = isXML;
  _exports.isHTML = isHTML;
  _exports.truncate = truncate;
  _exports.setLoadingMask = setLoadingMask;
  _exports.initClickEvent = initClickEvent;

  function isJSON(contentType) {
    return /application\/(?:json)/.test(contentType);
  }

  function isXML(contentType) {
    return /(application|text)\/(?:xml)/.test(contentType);
  }

  function isHTML(contentType) {
    return /text\/(?:html)/.test(contentType);
  }

  function truncate(text, limit) {
    if (!text) return '';
    if (!limit) limit = _const.MAX_LENGTH_VALUE;
    if (limit < text.length) return "".concat(text.trim().substr(0, limit), "...");else return text;
  }

  function setLoadingMask(isShow) {
    setTimeout(function () {
      var codeBlockBody = document.getElementById('codeBlockBody');

      if (codeBlockBody) {
        var currentClass = codeBlockBody.getAttribute('class');

        if (isShow) {
          currentClass += ' loading-mask';
        } else {
          currentClass = currentClass.replace('loading-mask', '').trim();
        }

        codeBlockBody.setAttribute('class', currentClass);
      }
    });
  }

  function initClickEvent(rawData, context) {
    if (rawData) {
      try {
        setLoadingMask(true);
        var _rawData$response = rawData.response,
            response = _rawData$response === void 0 ? {} : _rawData$response;
        var _response$headers = response.headers,
            headers = _response$headers === void 0 ? {} : _response$headers,
            _response$body = response.body,
            body = _response$body === void 0 ? '' : _response$body;
        var contentType = headers['Content-Type'];

        if (isJSON(contentType)) {
          (0, _json.initEventClickForJson)(body, context);
        } else if (isXML(contentType)) {
          (0, _xml.initEventClickForXml)(body, context);
        } else if (isHTML(contentType)) {
          (0, _html.initEventClickForHtml)(body, context);
        } else {
          setLoadingMask(false);
        }
      } catch (err) {
        setLoadingMask(false);
        Ember.debug('Error: ' + err);
      }
    }
  }
});