define("flood/components/flud-ui/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kRX+rC2l",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[\"mdc-checkbox\",[30,[36,0],[[32,0,[\"disabled\"]],\" mdc-checkbox--disabled\"],null]],null]],[12],[2,\"\\n  \"],[11,\"input\"],[24,0,\"mdc-checkbox__native-control\"],[16,1,[30,[36,1],[[32,0,[\"elementId\"]],\"_input\"],null]],[16,3,[32,0,[\"name\"]]],[16,\"disabled\",[32,0,[\"disabled\"]]],[16,\"checked\",[32,0,[\"checked\"]]],[17,2],[24,4,\"checkbox\"],[4,[38,3],[\"change\",[30,[36,2],[[32,0,[\"handleChange\"]]],null]],null],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mdc-checkbox__background\"],[12],[2,\"\\n    \"],[10,\"svg\"],[14,0,\"mdc-checkbox__checkmark\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[2,\"\\n      \"],[10,\"path\"],[14,0,\"mdc-checkbox__checkmark-path\"],[14,\"fill\",\"none\"],[14,\"d\",\"M1.73,12.91 8.1,19.28 22.79,4.59\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mdc-checkbox__mixedmark\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"label\"],[15,\"for\",[30,[36,1],[[32,0,[\"elementId\"]],\"_input\"],null]],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});