define("flood/components/account/members-section/cell-items/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKBXHFRi",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isUser\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[32,0,[\"data\",\"fullName\"]]],[2,\" \"],[1,[30,[36,0],[[30,[36,2],[[30,[36,1],[[32,0,[\"data\"]],[32,0,[\"currentUser\"]]],null],[30,[36,1],[[32,0,[\"data\",\"email\"]],[32,0,[\"currentUser\",\"email\"]]],null]],null],\"(you)\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isInvitation\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[32,0,[\"data\",\"fullName\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"data\",\"user\",\"fullName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"or\"]}",
    "meta": {
      "moduleName": "flood/components/account/members-section/cell-items/name/template.hbs"
    }
  });

  _exports.default = _default;
});