define("flood/components/flood-explorer/controls/relative-time-window/selected-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/HOJNSp",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"option\"]],\"project\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],[\"explorer.controls.relativeTimeWindows.placeholder\"],null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  Last \"],[1,[30,[36,1],[[30,[36,0],[\"explorer.controls.relativeTimeWindows.\",[32,0,[\"option\"]]],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/controls/relative-time-window/selected-item/template.hbs"
    }
  });

  _exports.default = _default;
});