define("flood/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cE4uTAAI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error-page\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"error\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"flud-ui/alert\",[],[[\"@type\",\"@message\"],[\"error\",[32,0,[\"error\",\"message\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"flud-ui/alert\",[],[[\"@type\",\"@message\"],[\"error\",[30,[36,0],[\"errors.unrecoverable\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/error.hbs"
    }
  });

  _exports.default = _default;
});