define("flood/utils/generate-navigation-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateNavigationData = _exports.generateBreadcrumbs = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var NAVIGATION_ROUTES = {
    organizations: {
      teams: 'account.orgs.org.teams',
      projects: 'account.orgs.org.projects',
      members: 'account.orgs.org.members',
      settings: 'account.orgs.org.settings'
    },
    team: {
      teams: 'account.orgs.org.teams.team.index',
      projects: 'account.orgs.org.teams.team.projects',
      members: 'account.orgs.org.teams.team.members',
      settings: 'account.orgs.org.teams.team.settings'
    },
    breadcrumbRoute: {
      organizations: 'account.orgs.org.teams.index',
      team: 'account.orgs.org.teams.team.members'
    },
    account: {
      organizations: 'account.orgs.index',
      members: 'account.orgs.members'
    }
  };

  var createBreadcrumb = function createBreadcrumb(model, currentRoute) {
    return {
      route: NAVIGATION_ROUTES.breadcrumbRoute[currentRoute],
      data: model
    };
  };

  var parseTeamHierarchyToBreadcrumbs = function parseTeamHierarchyToBreadcrumbs(teams, hierarchy, currentRoute) {
    return hierarchy.split('.').map(function (id) {
      var team = teams.findBy('id', id);
      return createBreadcrumb(team, currentRoute);
    });
  }; // Breadcrumbs should look like: Org_name > Team_name > Child_team_name > Child_team_name > ...


  var generateBreadcrumbs = function generateBreadcrumbs(_ref, currentRoute) {
    var _ref2 = _slicedToArray(_ref, 2),
        org = _ref2[0],
        team = _ref2[1];

    var breadcrumbs = [//first breadcrumb will always be org
    createBreadcrumb(org, 'organizations')];

    if (currentRoute === 'team') {
      breadcrumbs.push.apply(breadcrumbs, _toConsumableArray(parseTeamHierarchyToBreadcrumbs(org.teams, team.hierarchy, currentRoute)));
    }

    return breadcrumbs;
  };

  _exports.generateBreadcrumbs = generateBreadcrumbs;

  var generateNavigationData = function generateNavigationData(_ref3, currentRoute) {
    var _ref4 = _slicedToArray(_ref3, 3),
        account = _ref4[0],
        org = _ref4[1],
        team = _ref4[2];

    return {
      data: team || org || account,
      currentRoute: currentRoute,
      route: NAVIGATION_ROUTES[currentRoute]
    };
  };

  _exports.generateNavigationData = generateNavigationData;
});