define("flood/utils/unsorted-closest-search", ["exports", "ember-primer/utils/unsorted-closest-search"], function (_exports, _unsortedClosestSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _unsortedClosestSearch.default;
    }
  });
});