define("flood/helpers/p/sample", ["exports", "ember-primer/helpers/p/sample"], function (_exports, _sample) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sample.default;
    }
  });
  Object.defineProperty(_exports, "pSample", {
    enumerable: true,
    get: function get() {
      return _sample.pSample;
    }
  });
});