define("flood/utils/map-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mapBy;

  function mapBy(objects, prop, initialMap) {
    var objectsMap = initialMap || new Map();
    (false && !(Array.isArray(objects)) && Ember.assert('objects must be an array', Array.isArray(objects)));
    (false && !(prop) && Ember.assert('prop is required', prop));
    objects.filter(Boolean).forEach(function (obj) {
      var key = obj[prop];
      objectsMap.set(key, obj);
    });
    return objectsMap;
  }
});