define("flood/components/stream-editor/launch/activate-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AlFMX36n",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sign-up-message\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"mdc-typography mdc-typography--headline6\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"streamEditor.launch.activateAccount.marketing.title\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body1\"],[12],[1,[30,[36,0],[\"streamEditor.launch.activateAccount.marketing.message\"],[[\"allowance\",\"htmlSafe\"],[[32,0,[\"freeTierVUHLimit\"]],true]]]],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,6,\"https://guides.flood.io/pricing/how-much-does-it-cost\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[30,[36,0],[\"streamEditor.launch.activateAccount.marketing.learnMore.link\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,0],[\"streamEditor.launch.activateAccount.marketing.learnMore.description\"],null]],[2,\"\\n    \"],[8,\"flud-ui/button\",[[24,0,\"anchor\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"intercom\",\"show\"]]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"streamEditor.launch.activateAccount.marketing.contactUs.link\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,0],[\"streamEditor.launch.activateAccount.marketing.contactUs.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"activation-form\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"mdc-typography mdc-typography--headline6\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"streamEditor.launch.activateAccount.paymentDetails\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,3],null,[[\"submitLabel\",\"afterSumbit\"],[[30,[36,0],[\"streamEditor.launch.activateAccount.button\"],null],[32,0,[\"afterSumbit\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"on\",\"billing/credit-card\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/launch/activate-account/template.hbs"
    }
  });

  _exports.default = _default;
});