define("flood/templates/accept-invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J0ldqHSv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-ui/landing\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"result\",\"errors\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"invitation-result\",[],[[\"@isExpired\"],[true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"invitation-form\",[],[[\"@tokenType\",\"@data\",\"@registrationInfo\",\"@invitationToken\"],[[32,0,[\"model\",\"result\",\"type\"]],[32,0,[\"model\",\"result\",\"data\"]],[32,0,[\"model\",\"registrationInfo\"]],[32,0,[\"token\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/accept-invitation.hbs"
    }
  });

  _exports.default = _default;
});