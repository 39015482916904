define("flood/mixins/transition-timing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember$run = Ember.run,
      later = _Ember$run.later,
      cancel = _Ember$run.cancel;
  /**
    Knows how long the next render animation should take.
  
    Animations, particularly those programmed with d3, use an imperitive render
    process that’s triggered by observing property changes. If a property changes
    too often (many times a second), attempting to animate every change slows down
    other renders, is no longer a useful visual aid, and generally increases the
    Jank Factor™ of the UI.
  
    This mixin provides a `nextTransitionDuration` method that should be called
    every render to get the approriate animation duration. If the last render was
    not long enough ago, the transition duration will be 0 (should not animate).
  
    @class TransitionTimingMixin
    @extends Ember.Mixin
  */

  var _default = Ember.Mixin.create({
    /**
      How long animations should take *if* they animate, in milliseconds.
       @property defaultTransitionDuration
      @type Number
    */
    defaultTransitionDuration: 300,

    /**
      Gets the animation duration for the next transitions.
       If the last draw was less than `defaultTransitionDuration` ago, the returned
      duration will be 0 (should not animate). Otherwise it will return
      `defaultTransitionDuration`.
       @method nextTransitionDuration
      @return {Number} The duration for the transition animation in milliseconds.
    */
    nextTransitionDuration: function nextTransitionDuration() {
      var defaultDuration = this.defaultTransitionDuration;
      var timer = later(this, function () {
        this.set('_shouldAnimateTransition', true);
      }, defaultDuration);
      cancel(this._transitionTimer);
      this.set('_transitionTimer', timer);
      var duration = 0;

      if (this._shouldAnimateTransition) {
        duration = defaultDuration;
      }

      this.set('_shouldAnimateTransition', false);
      return duration;
    },

    /**
      @property _transitionTimer
    */
    _transitionTimer: null,

    /**
      Should the next draw animate transitions? This is intended to be entirely
      be managed by `nextTransitionDuration`.
       @property _shouldAnimateTransition
      @type Boolean
    */
    _shouldAnimateNextTransition: false,
    willDestroy: function willDestroy() {
      cancel(this._transitionTimer);

      this._super();
    }
  });

  _exports.default = _default;
});