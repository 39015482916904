define("flood/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6kaNuerV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"fullscreen\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"global-header\",[],[[\"@projects\",\"@currentProjectId\"],[[32,0,[\"projects\"]],[32,0,[\"currentProjectId\"]]]],null],[2,\"\\n\\n  \"],[8,\"global-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"flud-ui/snackbar\",[],[[\"@show\",\"@message\"],[[32,0,[\"notification\",\"show\"]],[32,0,[\"notification\",\"message\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/application.hbs"
    }
  });

  _exports.default = _default;
});