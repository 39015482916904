define("flood/templates/account/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nC017v7U",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"account\",\"isSubscribed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"billing/current-plan\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"billing/payment-details\",[],[[],[]],null],[2,\"\\n\\n\"],[8,\"billing/invoices\",[],[[\"@invoices\"],[[34,1]]],null],[2,\"\\n\\n\"],[6,[37,0],[[30,[36,2],[\"cancel subscription\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"billing/cancel-subscription\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"model\",\"can\"]}",
    "meta": {
      "moduleName": "flood/templates/account/billing.hbs"
    }
  });

  _exports.default = _default;
});