define("flood/components/usage-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8iZDHWfX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"account\",\"isTrial\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"usage-widget/free-trial\",[[24,\"data-intercom-target\",\"usage\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"usage-widget/vum\",[[24,\"data-intercom-target\",\"usage\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/components/usage-widget/template.hbs"
    }
  });

  _exports.default = _default;
});