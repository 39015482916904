define("flood/templates/change-user-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N/k5+Ovx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-ui/landing\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"isValidToken\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"confirm-update-email\",[],[[\"@token\"],[[32,0,[\"model\",\"token\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"invalid-link\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/change-user-email.hbs"
    }
  });

  _exports.default = _default;
});