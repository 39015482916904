define("flood/templates/invitation-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rLjLhGG+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"invitation-link-popup\",[],[[\"@data\",\"@showConfirmDlg\",\"@showExpiredDlg\",\"@redirectUrl\"],[[32,0,[\"model\",\"data\"]],[32,0,[\"model\",\"otherAccount\"]],[32,0,[\"model\",\"expired\"]],[32,0,[\"model\",\"redirectUrl\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/invitation-link.hbs"
    }
  });

  _exports.default = _default;
});