define("flood/helpers/sentence", ["exports", "ember-cli-string-helpers/helpers/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sentence = sentence;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var assign = Object.assign; // Based on ActiveSupport to_sentence

  function sentence(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1),
        parts = _ref2[0];

    var opts = assign({
      wordsConnector: ', ',
      twoWordsConnector: ' and ',
      lastWordConnector: ', and ',
      humanize: true
    }, options);
    var final = '';

    switch (parts.length) {
      case 1:
        final = parts[0];
        break;

      case 2:
        final = "".concat(parts[0]).concat(opts.twoWordsConnector).concat(parts[1]);
        break;

      default:
        if (typeof parts.slice !== 'function') {
          break;
        }

        final = "".concat(parts.slice(0, parts.length - 1).join(opts.wordsConnector)).concat(opts.lastWordConnector).concat(parts[parts.length - 1]);
        break;
    }

    return opts.humanize ? (0, _humanize.humanize)([final]) : final;
  }

  var _default = Ember.Helper.helper(sentence);

  _exports.default = _default;
});