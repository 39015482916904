define("flood/utils/metric-format-helpers", ["exports", "d3-format", "flood/helpers/format", "flood/mixins/duration-util"], function (_exports, _d3Format, _format, _durationUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bytesToHumanSize = bytesToHumanSize;
  _exports.bytesToString = bytesToString;
  _exports.default = transformValue;
  _exports.valueTransforms = _exports.MEAN_REGEXP = _exports.SUM_SUFFIX = _exports.MEAN_SUFFIX = void 0;

  function bytesToHumanSize(bytes) {
    var perSecond = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var fmt = (0, _d3Format.format)('.0f');

    if (bytes < 1024) {
      return fmt(bytes) + (perSecond ? ' bits/s' : ' bytes');
    } else if (bytes < 1024 * 1024) {
      return fmt(bytes / 1024) + (perSecond ? 'kb/s' : ' KB');
    } else if (bytes < 1024 * 1024 * 1024) {
      return fmt(bytes / 1024 / 1024) + (perSecond ? 'Mb/s' : ' MB');
    } else {
      return fmt(bytes / 1024 / 1024 / 1024) + (perSecond ? 'Gb/s' : ' GB');
    }
  }

  function bytesToString(bytes) {
    return bytesToHumanSize(bytes, true);
  }

  var MEAN_SUFFIX = '_mean';
  _exports.MEAN_SUFFIX = MEAN_SUFFIX;
  var SUM_SUFFIX = '_sum';
  _exports.SUM_SUFFIX = SUM_SUFFIX;
  var MEAN_REGEXP = new RegExp("".concat(MEAN_SUFFIX, "|").concat(SUM_SUFFIX, "$"));
  _exports.MEAN_REGEXP = MEAN_REGEXP;
  var valueTransforms = {
    apdex: function apdex(value) {
      return (0, _format.format)([value], {
        format: '0.2f'
      });
    },
    response_time: function response_time(value) {
      return (0, _durationUtil.formatDuration)(value);
    },
    latency: function latency(value) {
      return (0, _durationUtil.formatDuration)(value);
    },
    throughput: function throughput(value) {
      return bytesToString(value);
    },
    net: function net(value) {
      return bytesToString(value);
    },
    transaction_rate: function transaction_rate(value) {
      return (0, _format.format)([value], {
        format: ',d',
        suffix: 'rpm',
        ignoreSmallValues: true
      });
    },
    concurrency: function concurrency(value) {
      return (0, _format.format)([value], {
        format: ',d',
        suffix: 'users'
      });
    },
    failed: function failed(value) {
      return (0, _format.format)([value], {
        format: ',d',
        suffix: 'rpm'
      });
    },
    passed: function passed(value) {
      return (0, _format.format)([value], {
        format: ',d',
        suffix: 'rpm'
      });
    }
  };
  _exports.valueTransforms = valueTransforms;

  function transformValue(metricName, value) {
    metricName = Ember.String.underscore((metricName || '').replace(/Sum$/, 'Mean'));

    if (MEAN_REGEXP.test(metricName)) {
      metricName = metricName.split(MEAN_SUFFIX, 2)[0];
    }

    if (!isFinite(parseFloat(value))) {
      return '-';
    }

    var fn = valueTransforms[metricName];

    if (fn) {
      return fn(value);
    } else {
      return value;
    }
  }
});