define("flood/helpers/time-range", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeRange = timeRange;
  _exports.default = void 0;

  function timeRange(_params, options) {
    var start = options.start || _luxon.DateTime.local();

    var end = options.end || _luxon.DateTime.local();

    if (typeof start === 'string') start = _luxon.DateTime.fromISO(start);
    if (typeof end === 'string') end = _luxon.DateTime.fromISO(end);

    try {
      var formattedStart = start.toLocaleString(_luxon.DateTime.DATE_MED);
      var formattedEnd = end.toLocaleString(_luxon.DateTime.DATE_MED);
      return "".concat(formattedStart, "\u2013").concat(formattedEnd);
    } catch (e) {
      return '';
    }
  }

  var _default = Ember.Helper.helper(timeRange);

  _exports.default = _default;
});