define("flood/components/flood-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUMvA92p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClassNames\",\"@translucentOverlay\",\"@hasOverlay\",\"@clickOutsideToClose\",\"@attachment\",\"@onClose\"],[[32,0,[\"containerClassNames\"]],[32,0,[\"translucentOverlay\"]],[32,0,[\"hasOverlay\"]],true,\"center center\",[30,[36,1],[[32,0],\"closeAction\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"Title\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[14,\"center-center\",\"\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[1,[34,0]],[13],[2,\"\\n      \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"close\"],[4,[38,1],[[32,0],\"closeAction\"],null],[12],[2,\"×\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"Content\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,3],null,[[\"close\"],[[30,[36,1],[[32,0],\"closeAction\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"action\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "flood/components/flood-modal/template.hbs"
    }
  });

  _exports.default = _default;
});