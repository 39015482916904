define("flood/components/account/usage/history/cells/usage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OiLhWL70",
    "block": "{\"symbols\":[],\"statements\":[[10,\"strong\"],[14,0,\"hours\"],[12],[1,[30,[36,0],[\"account.usage.vum.hours\"],[[\"hours\",\"htmlSafe\"],[[32,0,[\"vuh\"]],true]]]],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"breakdown\"],[12],[1,[30,[36,0],[\"account.usage.vum.derivation\"],[[\"users\",\"minutes\"],[[32,0,[\"users\"]],[32,0,[\"minutes\"]]]]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/account/usage/history/cells/usage/template.hbs"
    }
  });

  _exports.default = _default;
});