define("flood/serializers/vpc", ["exports", "active-model-adapter", "@ember-data/serializer/rest"], function (_exports, _activeModelAdapter, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'vpc_id',
    attrs: {
      subnets: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});