define("flood/templates/account/orgs/org/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wpn9m9hh",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/organizations-section/organization-settings\",[],[[\"@org\"],[[32,0,[\"model\",\"org\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/account/orgs/org/settings.hbs"
    }
  });

  _exports.default = _default;
});