define("flood/components/stream-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sdPBCue8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"enableDesign\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"stream-editor/design\",[],[[\"@stream\",\"@tools\",\"@currentStepIndex\"],[[32,0,[\"stream\"]],[32,0,[\"tools\"]],[32,0,[\"currentStepIndex\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"enableLaunch\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"stream-editor/launch\",[],[[\"@stream\",\"@grids\",\"@launch\"],[[32,0,[\"stream\"]],[32,0,[\"grids\"]],[32,0,[\"launch\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/template.hbs"
    }
  });

  _exports.default = _default;
});