define("flood/transitions", ["exports", "flood/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var sideSheetDuration = 200;

  function getDuration() {
    return _environment.default.environment === 'test' ? 1 : 175;
  }

  function _default() {
    this.setDefault({
      duration: getDuration()
    });
    this.transition(this.hasClass('form'), // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toValue(function (toValue, fromValue) {
      return toValue && fromValue && toValue > fromValue;
    }), this.hasClass('tabs'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.hasClass('timeline-container'), this.use('fade', {
      duration: getDuration()
    }));
    this.transition(this.hasClass('side-sheet-liquid'), this.toValue(true), this.use('sidesheet', 'toLeft', {
      duration: sideSheetDuration
    }), this.reverse('sidesheet', 'toRight', {
      duration: sideSheetDuration
    }));
  }
});