define("flood/templates/projects/project/test-plans/editor/slos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0L918f61",
    "block": "{\"symbols\":[],\"statements\":[[8,\"console-page\",[],[[\"@consolePath\",\"@height\"],[[34,0,[\"consolePath\"]],\"calc(100vh - 2rem - 68px - 1px - 4rem)\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/test-plans/editor/slos.hbs"
    }
  });

  _exports.default = _default;
});