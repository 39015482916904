define("flood/serializers/auth0-connection", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _jsonApi.default.extend({
    attrs: {
      provisioningTicketUrl: {
        serialize: false
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments); // This is a legacy code for WAAD Integration, right now we keep it to make sure that the feature still work fine
      // It should be updated for the whole strategy


      if (json.data.attributes.strategy === 'waad') {
        json.data.attributes.options = {
          client_id: json.data.attributes.client_id,
          client_secret: json.data.attributes.client_secret,
          tenant_domain: json.data.attributes.tenant_domain,
          domain: json.data.attributes.tenant_domain,
          domain_aliases: json.data.attributes.domain_aliases,
          useCommonEndpoint: json.data.attributes.useCommonEndpoint,
          identity_api: json.data.attributes.identity_api,
          waad_protocol: json.data.attributes.waad_protocol,
          app_id: json.data.attributes.app_id
        };
      }

      delete json.data.attributes.client_id;
      delete json.data.attributes.client_secret;
      delete json.data.attributes.tenant_domain;
      delete json.data.attributes.domain;
      delete json.data.attributes.domain_aliases;
      delete json.data.attributes.useCommonEndpoint;
      delete json.data.attributes.waad_common_endpoint;
      delete json.data.attributes.identity_api;
      delete json.data.attributes.waad_protocol;
      delete json.data.attributes.app_id;
      return json;
    },
    keyForAttribute: function keyForAttribute(key) {
      // 3 special cases not following JSON-API convention
      if (key === 'useCommonEndpoint') return key;
      if (key === 'enableSyncUser') return Ember.String.dasherize(key);
      if (key === 'provisioningTicketUrl') return Ember.String.dasherize(key);
      return Ember.String.underscore(key);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload.data.length && payload.data.forEach(function (item, index) {
        // This is a legacy code for WAAD Integration, right now we keep it to make sure that the feature still work fine
        // It should be updated for the whole strategy
        if (payload.data[index].attributes.strategy === 'waad') {
          payload.data[index].attributes = _objectSpread(_objectSpread({}, payload.data[index].attributes.options), payload.data[index].attributes);
          delete payload.data[index].attributes.options;
        }
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});