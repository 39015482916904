define("flood/components/stream-editor/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j7dEYHRl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"showWhen\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"primary\",\"secondary\"],[[30,[36,1],[[32,0],\"delete\"],null],[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"showWhen\"]]],null],false],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"Are you sure you want to delete this Stream?\"],[13],[2,\"\\n\\n    \"],[10,\"ul\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"Stream will be deleted,\"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"Any scheduled Floods from this Stream will be canceled,\"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"Existing Floods created from this Stream will NOT BE deleted.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"danger-modal\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/delete/template.hbs"
    }
  });

  _exports.default = _default;
});