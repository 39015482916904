define("flood/helpers/duration-time-format", ["exports", "flood/utils/format-duration"], function (_exports, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.durationTimeFormat = durationTimeFormat;
  _exports.default = void 0;

  function durationTimeFormat(params, hash) {
    return function (date) {
      return (0, _formatDuration.formatAxisTimestamp)(date, new Date(hash.offset));
    };
  }

  var _default = Ember.Helper.helper(durationTimeFormat);

  _exports.default = _default;
});