define("flood/validations/credential", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GcpCredentialValidations = _exports.AwsStsCredentialWithS3Validations = _exports.AwsProgrammaticCredentialWithS3Validations = _exports.AwsStsCredentialValidations = _exports.AwsProgrammaticCredentialValidations = _exports.WaadValidations = _exports.AzureCredentialValidations = _exports.DatadogCredentialValidations = _exports.DynatraceCredentialValidations = _exports.AppDynamicsCredentialValidations = _exports.NewRelicCredentialValidations = _exports.SAMLConnectionlValidations = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DefaultAwsValidations = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Account Name must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Account Name is too long (maximum is 255 characters)'
    })],
    arn: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Account ID must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Account ID is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'Account ID must not have spaces'
    })]
  };
  var DefaultAwsS3Validation = {
    s3BucketName: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'S3 Bucket name must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'S3 Bucket name is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'S3 Bucket name must not have spaces'
    })],
    s3BucketRegion: [(0, _validators.validatePresence)({
      presence: true,
      message: 'S3 Bucket region must be present'
    })]
  };
  var SAMLConnectionlValidations = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Connection Name must be present'
    }), (0, _validators.validateLength)({
      max: 128,
      message: 'Name is too long (maximum is 128 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^[a-zA-Z0-9](-[a-zA-Z0-9]|[a-zA-Z0-9])*$',
      message: "The name of the connection must start and end with an alphanumeric character and can only contain alphanumeric characters and '-' (no blank spaces allowed)"
    })],
    domainAliases: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'IdP Domains must be present'
    })],
    signInEndpoint: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Sign In URL must be present'
    }), (0, _validators.validateFormat)({
      regex: '^(http|https)://',
      message: 'Invalid Sign In URL'
    })],
    signingCert: [(0, _validators.validatePresence)({
      presence: true,
      message: 'X509 Signing Certificate must be present'
    })],
    signOutEndpoint: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Sign Out URL must be present'
    }), (0, _validators.validateFormat)({
      regex: '^(http|https)://',
      message: 'Invalid Sign Out URL'
    })],
    selectedOrg: [(0, _validators.validatePresence)({
      presence: true,
      message: 'An organization must be selected'
    })],
    selectedTeam: [(0, _validators.validatePresence)({
      presence: true,
      message: 'A team must be selected'
    })]
  };
  _exports.SAMLConnectionlValidations = SAMLConnectionlValidations;
  var NewRelicCredentialValidations = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Description must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Description is too long (maximum is 255 characters)'
    })],
    genericApiKey: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'License Key must be present'
    }), (0, _validators.validateLength)({
      min: 9,
      max: 255,
      message: 'License Key must have at least 9 characters and not more than 255 characters'
    }), (0, _validators.validateFormat)({
      regex: '^(\\d|\\w)+$',
      message: 'License Key must not have spaces and special characters'
    })]
  };
  _exports.NewRelicCredentialValidations = NewRelicCredentialValidations;
  var AppDynamicsCredentialValidations = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Description must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Description is too long (maximum is 255 characters)'
    })]
  };
  _exports.AppDynamicsCredentialValidations = AppDynamicsCredentialValidations;
  var DynatraceCredentialValidations = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Description must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Description is too long (maximum is 255 characters)'
    })],
    genericApiKey: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'API Token must be present'
    }), (0, _validators.validateLength)({
      min: 9,
      max: 255,
      message: 'API Token must have at least 9 characters and not more than 255 characters'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'API Token must not have spaces'
    })],
    environmentId: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Environment ID must be present'
    }), (0, _validators.validateLength)({
      min: 6,
      max: 255,
      message: 'Environment ID must have at least 6 characters and not more than 255 characters'
    })]
  };
  _exports.DynatraceCredentialValidations = DynatraceCredentialValidations;
  var DatadogCredentialValidations = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Description must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Description is too long (maximum is 255 characters)'
    })],
    genericApiKey: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'API Key must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'API Key is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'API Key must not have spaces'
    })]
  };
  _exports.DatadogCredentialValidations = DatadogCredentialValidations;
  var AzureCredentialValidations = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Description must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Description is too long (maximum is 255 characters)'
    })],
    azureTenantId: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Tenant ID must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Tenant ID is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'Tenant ID must not have spaces'
    })],
    azureClientId: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'App ID must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'App ID is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'App ID must not have spaces'
    })],
    azureSubscriptionId: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Subscription must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Subscription is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'Subscription must not have spaces'
    })],
    azureClientSecret: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Password must be present'
    })]
  };
  _exports.AzureCredentialValidations = AzureCredentialValidations;
  var WaadValidations = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validateFormat)({
      regex: '^[a-zA-Z0-9](-[a-zA-Z0-9]|[a-zA-Z0-9])*$',
      message: "The name of the connection must start and end with an alphanumeric character and can only contain alphanumeric characters and '-' (no blank spaces allowed)"
    })],
    clientId: [(0, _validators.validatePresence)(true)],
    clientSecret: [(0, _validators.validatePresence)(true)],
    tenantDomain: [(0, _validators.validatePresence)(true)]
  };
  _exports.WaadValidations = WaadValidations;

  var AwsProgrammaticCredentialValidations = _objectSpread(_objectSpread({}, DefaultAwsValidations), {}, {
    accessKeyId: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Access Key ID must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Access Key ID is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'Access Key ID must not have spaces'
    })],
    secretAccessKey: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Secret Key must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Secret Key is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'Secret Key must not have spaces'
    })]
  });

  _exports.AwsProgrammaticCredentialValidations = AwsProgrammaticCredentialValidations;
  var AwsStsCredentialValidations = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Description must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Name is too long (maximum is 255 characters)'
    })],
    awsRoleArn: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Role ARN must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Role ARN is too long (maximum is 255 characters)'
    }), (0, _validators.validateFormat)({
      regex: '^\\S+$',
      message: 'Role ARN must not have spaces'
    })],
    awsRoleExternalId: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Role External ID must be present'
    })]
  };
  _exports.AwsStsCredentialValidations = AwsStsCredentialValidations;

  var AwsProgrammaticCredentialWithS3Validations = _objectSpread(_objectSpread({}, AwsProgrammaticCredentialValidations), DefaultAwsS3Validation);

  _exports.AwsProgrammaticCredentialWithS3Validations = AwsProgrammaticCredentialWithS3Validations;

  var AwsStsCredentialWithS3Validations = _objectSpread(_objectSpread({}, AwsStsCredentialValidations), DefaultAwsS3Validation);

  _exports.AwsStsCredentialWithS3Validations = AwsStsCredentialWithS3Validations;
  var GcpCredentialValidations = {
    accountName: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Account Name must be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'The Account Name is too long (maximum is 255 characters)'
    })]
  };
  _exports.GcpCredentialValidations = GcpCredentialValidations;
});