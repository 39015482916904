define("flood/components/invalid-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WHtHq/wh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"layout\",\"\"],[14,0,\"padding-h-4\"],[12],[2,\"\\n  \"],[8,\"flud-ui/alert\",[],[[\"@type\"],[\"error\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.invalidLink\"],[[\"time\",\"htmlSafe\"],[[32,0,[\"timeLeft\"]],true]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/invalid-link/template.hbs"
    }
  });

  _exports.default = _default;
});