define("flood/components/flood-explorer/controls/grid-selector/option-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CPoqUBjY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"grid\",\"uuid\"]],\"all\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,0,[\"grid\",\"region\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[30,[36,0],[[32,0,[\"regions\"]],[32,0,[\"grid\",\"provider\"]]],null],[32,0,[\"grid\",\"region\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,\"secondary\",\"\"],[12],[1,[32,0,[\"grid\",\"name\"]]],[2,\" — \"],[1,[30,[36,4],[[32,0,[\"grid\",\"instanceQuantity\"]],\"node\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"t\",\"eq\",\"if\",\"pluralize\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/controls/grid-selector/option-item/template.hbs"
    }
  });

  _exports.default = _default;
});