define("flood/utils/header-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseHeaders;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Generic HTTP header parser. Nothing too complicated here, parts are based on
   * Chromium’s HTTP Request parsing code.
   */
  var headersExp = new RegExp('[!-9;-~]+:(.|\\r\\n[\\t ])*\\n', 'g');
  var headerExp = /^([^: \t]+):[ \t]*((?:.*[^ \t])|)/;
  var headerContinueExp = /^[ \t]+(.*[^ \t])/;

  function parseHeader(line) {
    var headers = [];
    var match = headerExp.exec(line);
    var k = match && match[1];

    if (k) {
      // skip empty string (malformed header)
      headers.push(k);
      headers.push(match[2]);
    } else {
      var matchContinue = headerContinueExp.exec(line);

      if (matchContinue && headers.length) {
        if (headers[headers.length - 1]) {
          headers[headers.length - 1] += ' ';
        }

        headers[headers.length - 1] += matchContinue[1];
      }
    }

    return headers;
  }
  /**
   * Parses a string of HTTP headers form JMeter and returns an object.
   *
   * @param  {String} lines Header lines
   *
   * @return {Object}       Headers object.
   * @public
   */


  function parseHeaders(lines) {
    var statusLine = '';
    var parsedHeaders = [];

    if (lines.split(/\n/).length == 1) {
      // support non standard formats from Gatling
      lines = lines.replace(/=/g, ': ').replace(/;/g, '\n');
    } else if (lines.split(/\r\n/).length > 0) {
      // Flood Chrome headers use correct line returns
      lines = lines.replace(/\r\n/g, '\n');
    } else {
      statusLine = lines.split(/\n/)[0];

      if (!headerExp.test(statusLine)) {
        parsedHeaders.push({
          name: 'status',
          value: statusLine.trim()
        });
      }
    }

    var match = lines.match(headersExp);

    if (match && match[0]) {
      match.forEach(function (line) {
        var _parseHeader = parseHeader(line),
            _parseHeader2 = _slicedToArray(_parseHeader, 2),
            name = _parseHeader2[0],
            value = _parseHeader2[1];

        parsedHeaders.push({
          name: name,
          value: value.trim()
        });
      });
      return parsedHeaders;
    } else {
      return [];
    }
  }
});