define("flood/utils/explorer/regions", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flatten = flatten;
  _exports.detectCollision = detectCollision;
  _exports.invert = invert;
  _exports.buildRegions = buildRegions;
  _exports.length = length;
  _exports.boundsRegion = boundsRegion;
  _exports.findGaps = findGaps;
  _exports.pad = pad;
  _exports.trim = trim;
  _exports.clip = clip;
  _exports.sort = sort;
  _exports.pointInside = pointInside;
  _exports.fromFlood = fromFlood;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * We have the following. Imagine them as start and end times of
   * floods that ran, sometimes overlapping.
   *
   * 0     6
   * |-----|
   *                 15 18
   *                 |--|
   *     4      11
   *     |-------|
   *                         23  28
   *                         |----|
   *               13    20
   *               |------|
   *
   * And we want the following
   *
   * 0          11 13    20  23  28
   * |-----------| |------|  |----|
   */
  function flatten(regions) {
    // Sort them by start time so we can make assumptions that a prior region
    // always starts at or before a subsequent one.
    regions = sort(regions);
    var flattened = [];

    while (regions.length) {
      var _regions$ = _slicedToArray(regions[0], 2),
          currentStart = _regions$[0],
          currentEnd = _regions$[1]; // We need the value of i once the loop breaks/finishes so declare it
      // Start at 1 because 0 is what we start with as the covered region


      var i = 1;

      for (; i < regions.length; i++) {
        var _regions$i = _slicedToArray(regions[i], 2),
            nextStart = _regions$i[0],
            nextEnd = _regions$i[1];

        var nextTouchesEnd = nextStart === currentEnd;
        var nextStraddlesEnd = nextStart < currentEnd && nextEnd > currentEnd;
        var nextFullyNested = nextStart <= currentEnd && nextEnd <= currentEnd;

        if (nextTouchesEnd) {
          currentEnd = nextEnd;
        } else if (nextStraddlesEnd) {
          currentEnd = nextEnd;
        } else if (nextFullyNested) {
          continue;
        } else {
          break;
        }
      } // Remove the regions we dealt with, i is the count of regions we
      // need to remove from the beginning of the list


      regions.splice(0, i); // Write the current region to the flattened list

      flattened.push([currentStart, currentEnd]);
    }

    return flattened;
  }

  function detectCollision(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 2),
        a1 = _ref3[0],
        a2 = _ref3[1];

    var _ref4 = _slicedToArray(_ref2, 2),
        b1 = _ref4[0],
        b2 = _ref4[1];

    return a1 <= b1 + (b2 - b1) && a1 + (a2 - a1) >= b1;
  }

  function invert(regions) {
    var bounds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : boundsRegion(regions);
    if (regions.length === 0) return [[bounds[0], bounds[1]]];
    var inverted = [];
    var first = regions[0];
    var last = regions[regions.length - 1];

    if (first[0] !== bounds[0]) {
      inverted.push([bounds[0], first[0]]);
    }

    for (var i = 0; i < regions.length - 1; i++) {
      var current = regions[i];
      var next = regions[i + 1];
      if (!next) continue;
      inverted.push([current[1], next[0]]);
    }

    if (last[1] !== bounds[1]) {
      inverted.push([last[1], bounds[1]]);
    }

    return flatten(inverted);
  }

  function buildRegions(values) {
    var regions = [];

    for (var i = 0; i < values.length; i++) {
      if (values[i]) {
        var start = i;
        var end = i + 1;
        var j = i + 1;

        for (; j < values.length; j++) {
          if (values[j]) {
            end = j + 1;
          } else {
            break;
          }
        }

        i = j;
        regions.push([start, end]);
      }
    }

    return regions;
  }

  function length(region) {
    return region[1] - region[0];
  }

  function boundsRegion(regions) {
    return [regions[0][0], regions[regions.length - 1][1]];
  }

  function findGaps(regions, maximum, minFilledSize) {
    var bounds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : boundsRegion(regions);
    var filled = flatten(regions).slice().filter(function (region) {
      return length(region) >= minFilledSize;
    });
    var gaps = invert(filled, bounds);

    var _loop = function _loop() {
      // Find the smallest filled
      // Find it’s 2 surrounding gaps
      // Splice them from the array, replacing with a region that covers both (use bounds)
      // splice the smallest filled from the array
      var smallestFilled = 0;
      var prunableFilleds = filled.filter(function (region) {
        return region[0] !== bounds[0] && region[1] !== bounds[1];
      });

      for (var i = 1; i < prunableFilleds.length; i++) {
        if (length(prunableFilleds[i]) < length(prunableFilleds[smallestFilled])) {
          smallestFilled = i;
        }
      }

      var _prunableFilleds$smal = _slicedToArray(prunableFilleds[smallestFilled], 2),
          smallestStart = _prunableFilleds$smal[0],
          smallestEnd = _prunableFilleds$smal[1];

      var left = gaps.find(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
            _start = _ref6[0],
            end = _ref6[1];

        return end === smallestStart;
      });
      var right = gaps.find(function (_ref7) {
        var _ref8 = _slicedToArray(_ref7, 2),
            start = _ref8[0],
            _end = _ref8[1];

        return start === smallestEnd;
      });
      var index = void 0;
      var deleteCount = void 0;
      var replacement = void 0;

      if (left && right) {
        index = gaps.indexOf(left);
        deleteCount = 2;
        replacement = boundsRegion([left, right]);
      } else if (!left) {
        index = 0;
        deleteCount = 1;
        replacement = [bounds[0], right[1]];
      } else if (!right) {
        index = gaps.length - 1;
        deleteCount = 1;
        replacement = [left[0], bounds[1]];
      }

      gaps.splice(index, deleteCount, replacement);
      filled.splice(filled.indexOf(prunableFilleds[smallestFilled]), 1);
    };

    while (gaps.length > maximum) {
      _loop();
    }

    return gaps;
  }

  function pad(regions, amount) {
    var edges = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var flattened = flatten(regions);
    var padded;

    if (edges) {
      padded = flattened.map(function (_ref9) {
        var _ref10 = _slicedToArray(_ref9, 2),
            start = _ref10[0],
            end = _ref10[1];

        return [start - amount, end + amount];
      });
    } else {
      var min = Math.min.apply(Math, _toConsumableArray(flattened.map(function (_ref11) {
        var _ref12 = _slicedToArray(_ref11, 2),
            start = _ref12[0],
            _end = _ref12[1];

        return start;
      })));
      var max = Math.max.apply(Math, _toConsumableArray(flattened.map(function (_ref13) {
        var _ref14 = _slicedToArray(_ref13, 2),
            _start = _ref14[0],
            end = _ref14[1];

        return end;
      })));
      padded = flattened.map(function (_ref15) {
        var _ref16 = _slicedToArray(_ref15, 2),
            start = _ref16[0],
            end = _ref16[1];

        return [start === min ? start : start - amount, end === max ? end : end + amount];
      });
    }

    return flatten(padded);
  }

  function trim(regions, amount) {
    return regions.map(function (_ref17) {
      var _ref18 = _slicedToArray(_ref17, 2),
          start = _ref18[0],
          end = _ref18[1];

      return [start + amount, end - amount];
    }).filter(function (_ref19) {
      var _ref20 = _slicedToArray(_ref19, 2),
          start = _ref20[0],
          end = _ref20[1];

      return start < end;
    });
  }

  function clip(regions, clipRegions) {
    clipRegions = flatten(clipRegions);
    var clipped = [];

    for (var i = 0; i < regions.length; i++) {
      var region = regions[i];
      var didClipAnything = false;

      for (var j = 0; j < clipRegions.length; j++) {
        var clipRegion = clipRegions[j];

        if (detectCollision(region, clipRegion)) {
          clipped.push([Math.max(region[0], clipRegion[0]), Math.min(region[1], clipRegion[1])]);
        } else {
          if (didClipAnything) {
            break;
          }
        }
      }
    }

    return clipped;
  }

  function sort(regions) {
    return regions.slice().sort(function (_ref21, _ref22) {
      var _ref23 = _slicedToArray(_ref21, 1),
          aStart = _ref23[0];

      var _ref24 = _slicedToArray(_ref22, 1),
          bStart = _ref24[0];

      return aStart - bStart;
    });
  }

  function pointInside(value, _ref25) {
    var _ref26 = _slicedToArray(_ref25, 2),
        start = _ref26[0],
        end = _ref26[1];

    return start <= value && value <= end;
  }

  function fromFlood(flood) {
    if (flood.firstResultAt && flood.lastResultAt) {
      return [_luxon.DateTime.fromISO(flood.firstResultAt).toMillis(), _luxon.DateTime.fromISO(flood.lastResultAt).toMillis()];
    }
  }
});