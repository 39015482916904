define("flood/components/flood-explorer/project-floods-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VEoWYdRl",
    "block": "{\"symbols\":[\"floodAndStats\"],\"statements\":[[10,\"table\"],[14,0,\"flood-table DataTable\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"limitedFloodsAndStats\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[14,0,\"flood-table-row DataTable-cell--non-numeric\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,0,\"DataTable-cell--non-numeric\"],[12],[2,\"\\n          \"],[1,[30,[36,2],null,[[\"flood\",\"stats\",\"measurements\",\"state\",\"onMouseEnter\",\"onMouseLeave\",\"onExpand\",\"fetchSparklines\"],[[32,1,[\"flood\"]],[32,1,[\"stats\"]],[32,0,[\"measurements\"]],[30,[36,1],[[32,0,[\"expandedFloods\"]],[32,1,[\"flood\",\"uuid\"]]],null],[30,[36,0],[[32,0],[32,0,[\"onFloodSelection\"]],[32,1,[\"flood\"]]],null],[30,[36,0],[[32,0],[32,0,[\"onFloodSelection\"]]],null],[32,0,[\"onFloodExpand\"]],[32,0,[\"fetchSparklines\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"get\",\"flood-explorer/flood-summary\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/project-floods-summary/template.hbs"
    }
  });

  _exports.default = _default;
});