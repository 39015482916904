define("flood/utils/format-duration", ["exports", "d3-time-format"], function (_exports, _d3TimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDuration = formatDuration;
  _exports.formatAxisTimestamp = formatAxisTimestamp;
  _exports.timeFormats = void 0;
  var timeFormats = [['.%L', function (d) {
    return d < 1000;
  }], ['%-Ss', function (d) {
    return d < 60e3;
  }], ['%-Mm %-Ss', function (d) {
    return d < 60e3 * 60;
  }], ['%-Hh %-Mm %-Ss', function () {
    return true;
  }]];
  _exports.timeFormats = timeFormats;

  function formatDuration(duration) {
    var i = 0,
        formatsLength = timeFormats.length;
    var result = null;

    while (++i < formatsLength && !result) {
      if (timeFormats[i][1](duration)) {
        result = (0, _d3TimeFormat.utcFormat)(timeFormats[i][0])(new Date(duration));
      }
    }

    return result;
  }

  function formatAxisTimestamp(date, offset) {
    var duration = date.getTime() - offset.getTime();
    var result = formatDuration(duration);

    if (duration < 0) {
      return "-".concat(result);
    } else {
      return result;
    }
  }
});