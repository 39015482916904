define("flood/templates/projects/project/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T6EH2dgF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-explorer/project-awaiting-data\",[],[[\"@projectId\"],[[32,0,[\"projectId\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/index.hbs"
    }
  });

  _exports.default = _default;
});