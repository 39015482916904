define("flood/templates/projects/project/flood/grid/timeline/slos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QcSbzK31",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"console-page\",[],[[\"@consolePath\"],[[34,0,[\"consolePath\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/flood/grid/timeline/slos.hbs"
    }
  });

  _exports.default = _default;
});