define("flood/components/stacked-grid-form/dns-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uyiqKHOB",
    "block": "{\"symbols\":[\"dns\",\"dns\"],\"statements\":[[10,\"h3\"],[14,\"role\",\"label\"],[12],[1,[30,[36,0],[\"infrastructure.launchGrid.customHostedInputs.dns\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"Field\"],[12],[2,\"\\n  \"],[8,\"power-select-multiple-with-create\",[],[[\"@triggerId\",\"@searchEnabled\",\"@search\",\"@selected\",\"@onCreate\",\"@onChange\",\"@onInput\"],[\"dns-creating-and-selecting\",true,[30,[36,1],[[32,0],[32,0,[\"searchDNS\"]]],null],[30,[36,2],[[32,0,[\"changeset\",\"dns\"]],\",\"],null],[30,[36,1],[[32,0],[32,0,[\"addDNS\"]]],null],[30,[36,1],[[32,0],[32,0,[\"changeDNSs\"]]],null],[30,[36,1],[[32,0],[32,0,[\"onInput\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"invalidDNS\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"error\"],[12],[1,[30,[36,0],[\"infrastructure.launchGrid.customHostedInputs.invalidDNSFormat\"],[[\"dns\"],[[32,1]]]]],[13],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"split-string\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/stacked-grid-form/dns-input/template.hbs"
    }
  });

  _exports.default = _default;
});