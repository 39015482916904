define("flood/validations/organizations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OrganizationProfileValidations = _exports.AssignProjectsValidations = _exports.GlobalProjectValidationsForTeamManager = _exports.GlobalProjectValidations = _exports.ProjectValidations = _exports.TeamValidations = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TeamValidations = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Team name should be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Team name is too long (maximum is 255 characters)'
    })]
  };
  _exports.TeamValidations = TeamValidations;
  var ProjectValidations = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Project name should be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Project name is too long (maximum is 255 characters)'
    })]
  };
  _exports.ProjectValidations = ProjectValidations;

  var GlobalProjectValidations = _objectSpread(_objectSpread({}, ProjectValidations), {}, {
    organization: [(0, _validators.validatePresence)({
      presence: true,
      message: 'An organization must be selected'
    })]
  });

  _exports.GlobalProjectValidations = GlobalProjectValidations;

  var GlobalProjectValidationsForTeamManager = _objectSpread(_objectSpread({}, GlobalProjectValidations), {}, {
    team: [(0, _validators.validatePresence)({
      presence: true,
      message: 'A team must be selected'
    })]
  });

  _exports.GlobalProjectValidationsForTeamManager = GlobalProjectValidationsForTeamManager;
  var AssignProjectsValidations = {
    team: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Please select a team to assign project(s)'
    })]
  };
  _exports.AssignProjectsValidations = AssignProjectsValidations;
  var OrganizationProfileValidations = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Organization name should be present'
    }), (0, _validators.validateLength)({
      max: 255,
      message: 'Organization name is too long (maximum is 255 characters)'
    })],
    email: [(0, _validators.validateFormat)({
      type: 'email',
      allowBlank: true
    })],
    url: [(0, _validators.validateFormat)({
      type: 'url',
      allowBlank: true
    })]
  };
  _exports.OrganizationProfileValidations = OrganizationProfileValidations;
});