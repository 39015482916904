define("flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/html", ["exports", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/const", "pretty", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/divert"], function (_exports, _const, _pretty, _divert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettyHTML = prettyHTML;
  _exports.initEventClickForHtml = initEventClickForHtml;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function prettyHTML(htmlDoc) {
    return (0, _pretty.default)(htmlDoc);
  }

  function getPathTo(element) {
    if (element.nodeName === '#document') {
      return '';
    }

    if (element === document.body) {
      return element.nodeName;
    }

    if (element.id && element.id !== '') {
      return "//*[@id=\"".concat(element.id, "\"]");
    }

    var mapXPathIdx = {};
    var siblings = element.parentNode.childNodes;

    for (var i = 0; i < siblings.length; i++) {
      var sibling = siblings[i];
      if (sibling.nodeType === 10) continue;
      var tagName = sibling.nodeName.toLowerCase();

      if (!mapXPathIdx[tagName]) {
        mapXPathIdx[tagName] = 1;
      } else {
        var currentIdx = mapXPathIdx[tagName];
        mapXPathIdx[tagName] = currentIdx + 1;
      }

      if (sibling === element) {
        var xPath = "".concat(getPathTo(element.parentNode), "/").concat(element.nodeName.toLowerCase());
        return "".concat(xPath, "[").concat(mapXPathIdx[tagName], "]");
      }
    }
  }

  function isOpenTag(innerText) {
    return /<[a-zA-Z]+(>|.*?[^?]+(>|"))/.test(innerText);
  }

  function onClick(element, context) {
    var key = element.getAttribute(_const.HTML_PATH);
    var value = element.getAttribute(_const.HTML_VALUE);
    context.set(_const.CURRENT_KEY, key);
    context.set(_const.CURRENT_VALUE, value);
    context.set(_const.CURRENT_NODE_DATA, "<span class=\"modal-key\">".concat(key, "</span> = <span class=\"modal-value\">").concat((0, _divert.truncate)(value), "</span>"));
    context.set(_const.SELECTED_VARIABLES, {
      name: value
    });
    context.set(_const.ASSERT_DISABLE_FLAG, false);
    context.set(_const.SHOW_MODAL, true);
  }

  function mapHtmlToElements(filteredTags, elements, context) {
    filteredTags.map(function (elm, index) {
      var elmClass = elm.getAttribute(_const.CLASS_NAME);
      elm.setAttribute(_const.CLASS_NAME, "".concat(elmClass, " highlight-key"));
      elm.setAttribute(_const.HTML_PATH, elements[index].getAttribute('xPath'));
      elm.setAttribute(_const.HTML_VALUE, elements[index].textContent);
      elm.addEventListener('click', function () {
        onClick(elm, context);
      });
    });
  }

  function initEventClickForHtml(data, context) {
    setTimeout(function () {
      var parser = new DOMParser();
      var htmlDoc = parser.parseFromString(data, 'text/html');
      var elms = htmlDoc.querySelectorAll('*');

      var _iterator = _createForOfIteratorHelper(elms),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var elm = _step.value;
          elm.setAttribute('xPath', getPathTo(elm));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var markupTags = document.getElementsByClassName(_const.MARKUP_HTML);
      var filteredTags = [];

      var _iterator2 = _createForOfIteratorHelper(markupTags),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var tagElm = _step2.value;

          if (isOpenTag(tagElm.innerText) && tagElm.parentNode.nodeName.toLowerCase() === 'code') {
            filteredTags.push(tagElm);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      if (filteredTags.length !== elms.length) {
        Ember.debug('Html does not render on screen');
        (0, _divert.setLoadingMask)(false);
        return;
      }

      mapHtmlToElements(filteredTags, elms, context);
      (0, _divert.setLoadingMask)(false);
    }, 1000);
  }
});