define("flood/utils/time-window", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.equals = equals;
  _exports.relativeTimeRange = relativeTimeRange;
  _exports.clampTimeWindow = clampTimeWindow;
  _exports.length = length;
  _exports.rightAligned = rightAligned;
  _exports.leftAligned = leftAligned;
  _exports.toString = toString;
  _exports.toRegion = toRegion;
  _exports.log = log;
  _exports.maxSelectionWindow = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function equals(a, b) {
    return a.start.equals(b.start) && a.end.equals(b.end);
  }

  function relativeTimeRange(value, unit) {
    var initial = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _luxon.DateTime.local();
    var end = initial;
    var start = end.minus(_defineProperty({}, unit, value));
    return {
      start: start,
      end: end
    };
  }

  var maxSelectionWindow = _luxon.Duration.fromObject({
    months: 12
  });

  _exports.maxSelectionWindow = maxSelectionWindow;

  function clampTimeWindow(timeWindow, scopeTarget) {
    var firstResultAt = scopeTarget && _luxon.DateTime.fromISO(scopeTarget.firstResultAt);

    var lastResultAt = scopeTarget && _luxon.DateTime.fromISO(scopeTarget.lastResultAt);

    var start = timeWindow.start,
        end = timeWindow.end; // Ensure TimeWindow is within bounds

    if (firstResultAt && start < firstResultAt) {
      start = firstResultAt;
    }

    if (lastResultAt && end > lastResultAt) {
      end = lastResultAt;
    } // Clamp TimeWindow to max selection


    var clampedTimeWindow = {
      start: start,
      end: end
    };

    if (end.diff(start) > maxSelectionWindow) {
      clampedTimeWindow.start = clampedTimeWindow.end.minus(maxSelectionWindow);
    }

    return clampedTimeWindow;
  }

  function length(timeWindow) {
    return timeWindow.end.toMillis() - timeWindow.start.toMillis();
  }

  function rightAligned(a, b) {
    return a.end.equals(b.end);
  }

  function leftAligned(a, b) {
    return a.start.equals(b.start);
  }

  function toString(timeWindow) {
    var start = timeWindow.start,
        end = timeWindow.end;
    return {
      start: start.toUTC().toISO(),
      end: end.toUTC().toISO()
    };
  }

  function toRegion(timeWindow) {
    return [timeWindow.start.toMillis(), timeWindow.end.toMillis()];
  }

  function log() {
    var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    var _ref = arguments.length > 1 ? arguments[1] : undefined,
        start = _ref.start,
        end = _ref.end;

    console.log("".concat(prefix, "start: ").concat(start.toUTC().toISO()));
    console.log("".concat(prefix, "end:   ").concat(end.toUTC().toISO()));
  }
});