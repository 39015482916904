define("flood/components/expansion-panel/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jG/CfDNE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"button\"],[[30,[36,1],[\"expansion-panel/footer-button\"],null]]]]]],[2,\"\\n\\n\"],[6,[37,3],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"expansion-panel/footer-button\",[],[[\"@label\",\"@type\",\"@action\"],[\"Cancel\",\"secondary flat\",[30,[36,0],[[32,0],[32,0,[\"discardChanges\"]]],null]]],null],[2,\"\\n  \"],[8,\"expansion-panel/footer-button\",[],[[\"@label\",\"@type\",\"@action\"],[\"Continue\",\"primary flat\",[30,[36,0],[[32,0],[32,0,[\"saveAndContinue\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\",\"unless\"]}",
    "meta": {
      "moduleName": "flood/components/expansion-panel/footer/template.hbs"
    }
  });

  _exports.default = _default;
});