define("flood/components/flud-ui/app/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "47wPktz1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"header\"],[[30,[36,2],[\"flud-ui/blank-template\"],[[\"tagName\",\"class\"],[\"header\",[30,[36,1],[\"page__header \",[30,[36,0],[[32,0,[\"compactHeader\"]],\"compact\"],null]],null]]]]]]]]],[2,\"\\n\"],[18,1,[[30,[36,3],null,[[\"content\"],[[30,[36,2],[\"flud-ui/blank-template\"],[[\"tagName\",\"class\"],[\"div\",[30,[36,1],[\"page__content\",[30,[36,0],[[32,0,[\"compactContent\"]],\"compact\"],null]],null]]]]]]]]],[2,\"\\n\"],[18,1,[[30,[36,3],null,[[\"footer\"],[[30,[36,2],[\"flud-ui/blank-template\"],[[\"tagName\",\"class\"],[\"footer\",[30,[36,1],[\"page__footer \",[30,[36,0],[[32,0,[\"compactFooter\"]],\"compact\"],null]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/app/page/template.hbs"
    }
  });

  _exports.default = _default;
});