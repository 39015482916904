define("flood/mixins/dimensions", ["exports", "d3-selection"], function (_exports, _d3Selection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ceil = Math.ceil;

  var _default = Ember.Mixin.create({
    /**
     * If we’re able to render, this will be set to a d3 selection of our chart
     * element. Otherwise it will be null.
     *
     * @type {D3[Element]}
     * @public
     */
    chart: null,

    /**
     * Set to true once the first measurement has been made. Used to avoid
     * rendering with pre-measurement values
     */
    measured: false,

    /**
     * Represents the width of the chart
     *
     * @type {Number}
     * @public
     */
    plotWidth: 200,

    /**
     * Represents the height of the chart
     *
     * @type {Number}
     * @public
     */
    plotHeight: 100,

    /**
     * A hook to schedule future renders from once we have the chart ready.
     * @public
     */
    didInsertChart: function didInsertChart() {},
    scheduleRedraw: function scheduleRedraw() {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var callBack = function callBack() {
        if (_this.element) {
          _this.redraw.apply(_this, args);
        }
      };

      Ember.run.scheduleOnce('render', this, callBack, false);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();

      var callBack = function callBack() {
        if (_this2.element) {
          _this2.measureDimensions();

          Ember.set(_this2, 'chart', (0, _d3Selection.select)(_this2.chartElement()));
          _this2.selection = _this2.chart;

          _this2.didInsertChart();
        }
      };

      Ember.run.scheduleOnce('afterRender', this, callBack);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.set(this, 'chart', null);
    },
    chartElement: function chartElement() {
      if (!this.element) {
        return null;
      }

      return this.element.querySelector('svg');
    },
    measureDimensions: function measureDimensions() {
      if (!this.element) {
        return null;
      }

      var _this$element$getBoun = this.element.getBoundingClientRect(),
          width = _this$element$getBoun.width,
          height = _this$element$getBoun.height;

      this.setProperties({
        plotWidth: ceil(width),
        plotHeight: ceil(height),
        measured: true
      });
    }
  });

  _exports.default = _default;
});