define("flood/components/stream-editor/design/configure/builder/steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "10FxM0E1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,1],[\"streamEditor.design.fields.steps.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n\"],[6,[37,7],[[32,0,[\"stream\",\"steps\"]]],[[\"use\"],[\"crossFade\"]],[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"stream-editor/design/configure/builder/steps/step-editor\",[],[[\"@stream\",\"@currentStepIndex\"],[[34,4],[34,6]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[14,\"center\",\"\"],[12],[2,\"\\n      \"],[8,\"flud-ui/button\",[[4,[38,3],[[32,0],\"addStep\"],null]],[[\"@style\",\"@label\",\"@disabled\"],[\"raised\",[30,[36,1],[\"streamEditor.design.fields.addStep.label\"],null],[34,2]]],null],[2,\"\\n\"],[6,[37,5],[[35,4,[\"inFlight\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"color\"],[\"inherit\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flood-spinner\",\"t\",\"disabledAddStep\",\"action\",\"stream\",\"if\",\"currentStepIndex\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/configure/builder/steps/template.hbs"
    }
  });

  _exports.default = _default;
});