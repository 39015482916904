define("flood/components/billing/invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5KbUw3Fy",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[8,\"flud-ui/app/card\",[[24,0,\"past-invoices\"]],[[\"@title\"],[[30,[36,0],[\"billing.previousInvoices.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"invoices\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"flood-ui/table\",[],[[\"@dataSource\",\"@columns\"],[[32,0,[\"rows\"]],[32,0,[\"columns\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mdc-typography empty\"],[12],[1,[30,[36,0],[\"billing.previousInvoices.empty\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/billing/invoices/template.hbs"
    }
  });

  _exports.default = _default;
});