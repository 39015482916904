define("flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/const", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ASSERT_DISABLE_FLAG = _exports.EXTRACT_DISABLE_FLAG = _exports.SELECTED_VARIABLES = _exports.MAX_LENGTH_VALUE = _exports.CLASS_NAME = _exports.ERRORS = _exports.VARIABLE_NAME = _exports.EXTRACT_HEADER = _exports.SHOW_MODAL = _exports.CURRENT_NODE_DATA = _exports.CURRENT_VALUE = _exports.CURRENT_KEY = _exports.ID = _exports.MARKUP_HTML = _exports.HTML_VALUE = _exports.HTML_PATH = _exports.MARKUP_XML = _exports.XML_VALUE = _exports.XML_PATH = _exports.MARKUP_JSON = _exports.JSON_VALUE = _exports.JSON_PATH = void 0;
  var JSON_PATH = 'jsonPath';
  _exports.JSON_PATH = JSON_PATH;
  var JSON_VALUE = 'jsonValue';
  _exports.JSON_VALUE = JSON_VALUE;
  var MARKUP_JSON = 'hljs-attr';
  _exports.MARKUP_JSON = MARKUP_JSON;
  var XML_PATH = 'xmlPath';
  _exports.XML_PATH = XML_PATH;
  var XML_VALUE = 'xmlValue';
  _exports.XML_VALUE = XML_VALUE;
  var MARKUP_XML = 'hljs-tag';
  _exports.MARKUP_XML = MARKUP_XML;
  var HTML_PATH = 'htmlPath';
  _exports.HTML_PATH = HTML_PATH;
  var HTML_VALUE = 'htmlValue';
  _exports.HTML_VALUE = HTML_VALUE;
  var MARKUP_HTML = 'hljs-tag';
  _exports.MARKUP_HTML = MARKUP_HTML;
  var ID = 'id';
  _exports.ID = ID;
  var CURRENT_KEY = 'currentKey';
  _exports.CURRENT_KEY = CURRENT_KEY;
  var CURRENT_VALUE = 'currentValue';
  _exports.CURRENT_VALUE = CURRENT_VALUE;
  var CURRENT_NODE_DATA = 'currentNodeData';
  _exports.CURRENT_NODE_DATA = CURRENT_NODE_DATA;
  var SHOW_MODAL = 'showModal';
  _exports.SHOW_MODAL = SHOW_MODAL;
  var EXTRACT_HEADER = 'extractHeader';
  _exports.EXTRACT_HEADER = EXTRACT_HEADER;
  var VARIABLE_NAME = 'data.varName';
  _exports.VARIABLE_NAME = VARIABLE_NAME;
  var ERRORS = 'data.errors';
  _exports.ERRORS = ERRORS;
  var CLASS_NAME = 'class';
  _exports.CLASS_NAME = CLASS_NAME;
  var MAX_LENGTH_VALUE = 30;
  _exports.MAX_LENGTH_VALUE = MAX_LENGTH_VALUE;
  var SELECTED_VARIABLES = 'variableNames';
  _exports.SELECTED_VARIABLES = SELECTED_VARIABLES;
  var EXTRACT_DISABLE_FLAG = 'extractDisabled';
  _exports.EXTRACT_DISABLE_FLAG = EXTRACT_DISABLE_FLAG;
  var ASSERT_DISABLE_FLAG = 'assertDisabled';
  _exports.ASSERT_DISABLE_FLAG = ASSERT_DISABLE_FLAG;
});