define("flood/components/task-completion-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pN+gR4/6",
    "block": "{\"symbols\":[\"task\",\"@tasks\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"step \",[30,[36,0],[[32,1,[\"runner\",\"last\",\"isError\"]],\"error\"],null],\" \",[30,[36,0],[[32,1,[\"runner\",\"last\",\"isSuccessful\"]],\"complete\"],null]]]],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body1\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],[\"taskCompletionSteps.\",[32,1,[\"name\"]]],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"runner\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"stroke\",\"size\",\"strokeWidth\"],[\"#4285f4\",16,3]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,1],[[32,1,[\"runner\",\"performCount\"]],1],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"runner\",\"lastSuccessful\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"material-icons md-16\"],[12],[2,\"check\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"material-icons md-16\"],[12],[2,\"warning\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"simple-spinner\",\"concat\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/task-completion-steps/template.hbs"
    }
  });

  _exports.default = _default;
});