define("flood/templates/components/file-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lYqqo2B4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"file-picker__dropzone\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"file-picker__preview\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"progress\"],[15,2,[34,1]],[14,\"max\",\"100\"],[14,0,\"file-picker__progress\"],[12],[1,[34,2]],[2,\" %\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"file-picker__progress\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"file-picker__progress__value\"],[15,5,[34,0]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,11],null,[[\"type\",\"value\",\"accept\",\"multiple\",\"class\"],[\"file\",[35,10],[35,9],[35,8],\"file-picker__input\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"progressStyle\",\"progressValue\",\"progress\",\"isProgressSupported\",\"if\",\"showProgress\",\"dropzone\",\"preview\",\"multiple\",\"accept\",\"file\",\"input\"]}",
    "meta": {
      "moduleName": "flood/templates/components/file-picker.hbs"
    }
  });

  _exports.default = _default;
});