define("flood/validations/test-plan-step", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VariableNameValidation = void 0;
  var VariableNameValidation = {
    varName: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'Variable Name must be present'
    }), (0, _validators.validateFormat)({
      regex: '^[a-zA-Z_$][a-zA-Z_$0-9]*$',
      message: 'Variable name is not valid'
    })]
  };
  _exports.VariableNameValidation = VariableNameValidation;
});