define("flood/serializers/flood", ["exports", "flood/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      uuid: {
        serialize: false
      },
      grids: {
        serialize: true
      },
      files: {
        serialize: true
      },
      archives: {
        serialize: false
      },
      project: {
        serialize: true
      },
      status: {
        serialize: false
      },
      streamId: {
        serialize: false,
        key: 'stream'
      },
      isSharable: {
        serialize: false
      },
      sequenceId: {
        serialize: false
      },
      account: {
        serialize: false
      },
      remoteErrors: {
        serialize: false
      },
      user: {
        serialize: false
      },
      apdex: {
        serialize: false
      },
      permalink: {
        serialize: false
      },
      startedAt: {
        serialize: false
      },
      createdAt: {
        serialize: false
      },
      stoppedAt: {
        serialize: false
      },
      queuedAt: {
        serialize: false
      },
      lastSyncAt: {
        serialize: false
      },
      lastResultAt: {
        serialize: false
      },
      firstResultAt: {
        serialize: false
      },
      maxConcurrentUsers: {
        serialize: false
      },
      meanErrorRate: {
        serialize: false
      },
      meanTransactionRate: {
        serialize: false
      },
      meanResponseTime: {
        serialize: false
      },
      meanThroughput: {
        serialize: false
      },
      aggregationPeriod: {
        serialize: false
      },
      summary: {
        serialize: false
      }
    } // serializeAttribute(snapshot, json, key, attributes) {
    //   if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
    //     this._super(snapshot, json, key, attributes);
    //   }
    // }

  });

  _exports.default = _default;
});