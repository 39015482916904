define("flood/components/flood-explorer/chart-control/measurement-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GtAnsfAq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"icon\"],[15,5,[32,0,[\"style\"]]],[12],[13],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"label\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/chart-control/measurement-status/template.hbs"
    }
  });

  _exports.default = _default;
});