define("flood/templates/accept-magic-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y3b97uKO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"model\",\"emailTaken\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"invitation-link-popup\",[],[[\"@showExpiredDlg\"],[true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"join-team-magic-link-form\",[],[[\"@registrationInfo\",\"@invitedBy\",\"@invitedTeam\",\"@token\",\"@fullName\",\"@email\",\"@verify\"],[[32,0,[\"model\",\"registrationInfo\"]],[32,0,[\"model\",\"invitedBy\"]],[32,0,[\"model\",\"invitedTeam\"]],[32,0,[\"model\",\"token\"]],[32,0,[\"model\",\"fullName\"]],[32,0,[\"model\",\"email\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/accept-magic-link.hbs"
    }
  });

  _exports.default = _default;
});