define("flood/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vQ4p7Q+0",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[10,\"main\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"brand\"],[12],[2,\"\\n    \"],[8,\"logo/flood-hexagon\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h3\"],[12],[2,\"Sign in to \"],[10,\"strong\"],[12],[2,\"Tricentis Flood\"],[13],[13],[2,\"\\n\\n\"],[6,[37,1],null,[[\"data\",\"onSubmit\"],[[32,0,[\"data\"]],[30,[36,0],[[32,0],\"authenticate\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@type\",\"@label\",\"@placeholder\",\"@autofocus\",\"@class\"],[\"identification\",\"email\",\"Email\",\"user@company.com\",true,\"required-field\"]],null],[2,\"\\n    \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@type\",\"@label\",\"@placeholder\",\"@class\"],[\"password\",\"password\",\"Password\",\"•••••••••••\",\"required-field\"]],null],[2,\"\\n    \"],[8,[32,1,[\"submit\"]],[],[[\"@label\",\"@inFlightLabel\"],[\"Sign in\",\"Signing in\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[10,\"a\"],[14,6,\"https://flood.io/users/password/new\"],[14,0,\"password-reset-link\"],[12],[2,\"Forgot your password?\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"footer\"],[12],[2,\"\\n\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@classNames\"],[\"sign-up\",\"registration-link\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Don’t have an account? \"],[10,\"span\"],[14,0,\"cta\"],[12],[2,\"Sign Up\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"flood-ui/form\"]}",
    "meta": {
      "moduleName": "flood/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});