define("flood/components/expansion-panel-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "110pYCM2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"maxStepNumber\",\"panel\"],[[32,0,[\"maxStepNumber\"]],[30,[36,1],[\"expansion-panel\"],[[\"expandedPanelId\",\"model\",\"disabled\",\"_expand\",\"_collapse\",\"_register\",\"_deregister\",\"_next\",\"_save\"],[[32,0,[\"_expandedPanelId\"]],[32,0,[\"model\"]],[32,0,[\"disabled\"]],[30,[36,0],[[32,0],\"handlePanelExpand\"],null],[30,[36,0],[[32,0],\"handlePanelCollapse\"],null],[30,[36,0],[[32,0],\"registerPanel\"],null],[30,[36,0],[[32,0],\"deregisterPanel\"],null],[30,[36,0],[[32,0],\"handleOpenNextPanel\"],null],[30,[36,0],[[32,0],\"commitChanges\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "flood/components/expansion-panel-container/template.hbs"
    }
  });

  _exports.default = _default;
});