define("flood/components/flood-status-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "txW5iGDb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"icon\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"flud-ui/icon\",[],[[\"@name\",\"@size\"],[[32,0,[\"icon\"]],[32,0,[\"size\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"color\"],[\"inherit\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"flood-spinner\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-status-icon/template.hbs"
    }
  });

  _exports.default = _default;
});