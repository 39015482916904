define("flood/components/global-header/banner/problem/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p/9bNQAH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flud-ui/icon\",[],[[\"@name\",\"@size\"],[\"warning\",\"sm\"]],null],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"banners.problem.message\"],null]],[2,\"\\n  \"],[1,[30,[36,0],[\"banners.problem.remedy.billing\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/global-header/banner/problem/template.hbs"
    }
  });

  _exports.default = _default;
});