define("flood/components/account/members-section/membership-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8XjbaxCr",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[6,[37,2],[[32,1,[\"context\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"pendingInvitations.membershipModal.sub-title\"],[[\"email\",\"count\"],[[32,1,[\"email\"]],[30,[36,2],[[30,[36,1],[[32,1,[\"context\"]],\"org\"],null],1,2],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"orgs.members.membershipModal.sub-title\"],[[\"user\",\"count\"],[[32,1,[\"name\"]],[32,1,[\"roles\",\"orgs\",\"length\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,0,[\"getOrgsAndTeams\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"membership-zone__loader\"],[12],[2,\"\\n    \"],[8,\"flud-ui/loader/logo\",[],[[\"@class\"],[\"flood-logo-loader\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"flood-ui/table\",[],[[\"@dataSource\",\"@columns\",\"@selection\",\"@className\"],[[32,0,[\"getOrgsAndTeams\",\"lastSuccessful\",\"value\"]],[32,0,[\"columns\"]],false,\"membership-table\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"Actions\"],[12],[2,\"\\n  \"],[8,\"flud-ui/button\",[[4,[38,4],[\"click\",[30,[36,3],[[32,0],\"onDoneClick\"],null]],null]],[[\"@style\"],[\"raised\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"orgs.projects.editPermissionModal.doneBtn\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"action\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/account/members-section/membership-zone/template.hbs"
    }
  });

  _exports.default = _default;
});