define("flood/components/stream-editor/design/type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hQJ+OP/F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.type.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control split-pane\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"split-pane__content\"],[12],[2,\"\\n    \"],[8,\"flud-ui/radio\",[],[[\"@groupName\",\"@groupValue\",\"@label\",\"@value\",\"@onChange\",\"@disabled\"],[\"test-type\",[34,1],[30,[36,0],[\"streamEditor.design.fields.type.uploader.label\"],null],\"uploader\",[30,[36,2],[[32,0,[\"setValue\"]]],null],[32,0,[\"disabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.type.uploader.label\"],null]],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body2\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"streamEditor.design.fields.type.uploader.description\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"split-pane__content\"],[12],[2,\"\\n    \"],[8,\"flud-ui/radio\",[],[[\"@groupName\",\"@groupValue\",\"@label\",\"@value\",\"@onChange\",\"@disabled\"],[\"test-type\",[34,1],[30,[36,0],[\"streamEditor.design.fields.type.builder.label\"],null],\"builder\",[30,[36,2],[[32,0,[\"setValue\"]]],null],[32,0,[\"disabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.type.builder.label\"],null]],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body2\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"streamEditor.design.fields.type.builder.description\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"groupValue\",\"fn\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/type/template.hbs"
    }
  });

  _exports.default = _default;
});