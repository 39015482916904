define("flood/templates/account/usage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "msm/nWAJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"account\",\"isTrial\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"flud-ui/alert\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"billing.coupon.trial\"],[[\"periodEnd\",\"htmlSafe\"],[[32,0,[\"account\",\"subscriptionPeriodFinishesAt\"]],true]]]],[2,\"\\n      \"],[8,\"flood-ui/contact-link\",[],[[],[]],null],[2,\"\\n      \"],[1,[30,[36,1],[\"billing.contactSales.trialMessageUsageStart\"],null]],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"account.billing\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"billing.contactSales.trialMessageUsageLink\"],null]]],\"parameters\":[]}]]],[2,\"\\n      \"],[1,[30,[36,1],[\"billing.contactSales.trialMessageUsageEnd\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"account\",\"isVum\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"account/usage/vum\",[],[[\"@data\"],[[32,0,[\"model\",\"invoiceSummary\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"account\",\"isVuh\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"account/usage/vuh\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"account\",\"isVu\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"account/usage/vu\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"account/usage/history\",[],[[\"@vums\",\"@projects\",\"@hasMore\",\"@fetchMoreVums\"],[[32,0,[\"model\",\"vums\"]],[32,0,[\"model\",\"projects\"]],[32,0,[\"hasMore\"]],[30,[36,2],[[32,0],\"fetchMoreVums\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "flood/templates/account/usage.hbs"
    }
  });

  _exports.default = _default;
});