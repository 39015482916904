define("flood/components/flood-ui/meter/health-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bLOKJ8we",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"min\",\"max\",\"value\",\"fill\",\"background\",\"width\",\"height\",\"showLabel\",\"onClick\"],[0,100,[32,0,[\"errorRate\"]],[32,0,[\"passedColor\"]],[32,0,[\"failedColor\"]],[32,0,[\"width\"]],[32,0,[\"height\"]],true,[32,0,[\"onClick\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flood-ui/meter\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/meter/health-bar/template.hbs"
    }
  });

  _exports.default = _default;
});