define("flood/utils/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchParams = searchParams;

  function encodeSearchParam(name, value) {
    if (Ember.typeOf(value) === 'array') {
      return value.map(function (v) {
        return encodeSearchParam("".concat(name, "[]"), v);
      }).join('&');
    }

    return [encodeURIComponent(name), encodeURIComponent(value)].join('=');
  }

  function searchParams(data) {
    var params = Object.keys(data).map(function (key) {
      return encodeSearchParam(key, data[key]);
    });
    return "?".concat(params.join('&'));
  }
});