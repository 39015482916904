define("flood/components/flood-explorer/container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNM0vUKm",
    "block": "{\"symbols\":[\"plot\",\"measured\"],\"statements\":[[6,[37,4],null,[[\"class\",\"standalone\"],[\"flood-explorer-responsive-svg\",false]],[[\"default\"],[{\"statements\":[[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"width\",\"height\",\"data\",\"issues\",\"duration\",\"floods\",\"selectedFlood\",\"scope\",\"measurements\",\"scopeWindow\",\"anchoredScopeWindow\",\"selectionWindow\",\"anchoredSelectionWindow\",\"projectId\",\"changeSelectionWindow\",\"onHighlightFloods\"],[[32,1,[\"width\"]],[32,1,[\"height\"]],[32,0,[\"series\"]],[32,0,[\"overlayIssues\"]],[32,0,[\"duration\"]],[32,0,[\"floods\"]],[32,0,[\"selectedFlood\"]],[32,0,[\"scope\"]],[32,0,[\"measurements\"]],[32,0,[\"scopeWindow\"]],[32,0,[\"anchoredScopeWindow\"]],[32,0,[\"selectionWindow\"]],[32,0,[\"anchoredSelectionWindow\"]],[32,0,[\"project\",\"id\"]],[30,[36,1],[[32,0],\"changeSelectionWindow\"],null],[32,0,[\"setHighlightedFloods\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,0,[\"loading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"loading\"],[12],[2,\"\\n      \"],[1,[30,[36,0],null,[[\"stroke\",\"size\",\"strokeWidth\"],[\"#4285f4\",32,3]]]],[2,\"\\n      \"],[10,\"p\"],[14,0,\"primary\"],[12],[2,\"Loading Data\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[8,\"flood-explorer/chart-control/list-compact\",[],[[\"@measurements\",\"@onUpdateStatus\"],[[32,0,[\"measurements\"]],[30,[36,1],[[32,0],\"setMeasurementPosition\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"simple-spinner\",\"action\",\"flood-explorer/chart\",\"if\",\"responsive-svg\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/container/template.hbs"
    }
  });

  _exports.default = _default;
});