define("flood/components/stream-editor/design/configure/uploader/test-files/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1PVQVzBt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,1],[\"streamEditor.design.fields.testFiles.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body2 script-upload-description\"],[12],[1,[30,[36,1],[\"streamEditor.design.fields.testFiles.description\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n\\n  \"],[1,[30,[36,4],null,[[\"files\",\"selectedFiles\",\"canChangeSelection\",\"deleteFile\",\"fileDidUpload\"],[[32,0,[\"stream\",\"files\"]],[30,[36,3],[\"id\",[32,0,[\"stream\",\"files\"]]],null],false,[30,[36,2],[[32,0],\"removeFile\"],null],[30,[36,2],[[32,0],\"addFile\"],null]]]]],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"detectedTool\",\"length\"]]],[[\"use\"],[\"crossFade\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--caption detected-tool\"],[12],[1,[30,[36,1],[\"streamEditor.design.fields.testFiles.detectedTool\"],[[\"tool\",\"htmlSafe\"],[[30,[36,1],[[30,[36,0],[\"tools.\",[32,0,[\"detectedTool\"]]],null]],null],true]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"action\",\"map-by\",\"flood-file-uploader\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/configure/uploader/test-files/template.hbs"
    }
  });

  _exports.default = _default;
});