define("flood/components/infrastructure-list/grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DBzJxLTZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[15,\"width\",[32,0,[\"width\"]]],[15,\"height\",[32,0,[\"height\"]]],[12],[2,\"\\n  \"],[10,\"g\"],[14,0,\"stage\"],[12],[2,\"\\n    \"],[10,\"g\"],[14,0,\"title-group\"],[12],[2,\"\\n      \"],[10,\"text\"],[14,0,\"title\"],[12],[2,\"\\n        \"],[1,[32,0,[\"grid\",\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/infrastructure-list/grid/template.hbs"
    }
  });

  _exports.default = _default;
});