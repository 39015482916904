define("flood/components/flud-ui/snackbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jc08/uj6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mdc-snackbar__surface\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mdc-snackbar__label\"],[14,\"role\",\"status\"],[14,\"aria-live\",\"polite\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mdc-snackbar__actions\"],[12],[2,\"\\n    \"],[8,\"flud-ui/button\",[[24,0,\"mdc-snackbar__action\"],[4,[38,0],[\"click\",[32,0,[\"dismiss\"]]],null]],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"snackbar.actionButton.dismiss\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"t\"]}",
    "meta": {
      "moduleName": "flood/components/flud-ui/snackbar/template.hbs"
    }
  });

  _exports.default = _default;
});