define("flood/templates/projects/project/flood/grid/timeline/issues", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oYJokav9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-explorer/issues/list\",[],[[\"@flood\",\"@issues\",\"@onSelectIssue\"],[[32,0,[\"model\",\"flood\"]],[32,0,[\"issues\"]],[30,[36,0],[[32,0],\"selectIssue\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/flood/grid/timeline/issues.hbs"
    }
  });

  _exports.default = _default;
});