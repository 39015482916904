define("flood/serializers/user", ["exports", "flood/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      account: {
        serialize: false
      },
      identities: {
        serialize: false,
        deserialize: true
      },
      createdAt: {
        serialize: false
      },
      companyName: {
        serialize: false
      },
      intercomSignedToken: {
        serialize: false
      },
      invitedBy: {
        serialize: false
      },
      currentSignInAt: {
        serialize: false
      },
      authenticationToken: {
        serialize: false
      },
      isActive: {
        serialize: false
      },
      features: {
        serialize: false
      },
      orgJoinDates: {
        serialize: false
      },
      roles: {
        serialize: false
      },
      role: {
        serialize: false
      },
      permissions: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});