define("flood/templates/projects/project/test-plans/editor/design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kozwcB+/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"stream-editor\",[],[[\"@stream\",\"@tools\",\"@currentStepIndex\",\"@inFlight\",\"@enableLaunch\"],[[32,0,[\"model\",\"stream\"]],[32,0,[\"model\",\"tools\"]],[32,0,[\"currentStepIndex\"]],[32,0,[\"inFlight\"]],false]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/test-plans/editor/design.hbs"
    }
  });

  _exports.default = _default;
});