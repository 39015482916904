define("flood/instance-initializers/ember-app-shell", ["exports", "ember-app-shell/instance-initializer"], function (_exports, _instanceInitializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _instanceInitializer.default;
    }
  });
});