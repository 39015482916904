define("flood/templates/page-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2BP8DB36",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"layout\",\"\"],[14,\"vertical\",\"\"],[14,0,\"Container Container--center\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"mdc-typography mdc-typography--headline4\"],[12],[2,\"404 - Page not found\"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body1\"],[12],[2,\"The page you are looking for might have been removed, had its name changed or is temporarily unavailable.\"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body1\"],[12],[2,\"\\n    \"],[8,\"paper-button\",[],[[\"@primary\",\"@raised\",\"@href\"],[true,true,[30,[36,0],[\"index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Return home\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"href-to\"]}",
    "meta": {
      "moduleName": "flood/templates/page-not-found.hbs"
    }
  });

  _exports.default = _default;
});