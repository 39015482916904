define("flood/templates/invitation-link-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kKgKUFh4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"join-team-magic-link-form\",[],[[\"@registrationInfo\",\"@invitedBy\",\"@invitedTeam\",\"@token\",\"@verify\"],[[32,0,[\"model\",\"registrationInfo\"]],[32,0,[\"model\",\"invitedBy\"]],[32,0,[\"model\",\"invitedTeam\"]],[32,0,[\"model\",\"token\"]],false]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/invitation-link-register.hbs"
    }
  });

  _exports.default = _default;
});