define("flood/components/expansion-panel/footer-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XONlEcOV",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,1]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"label\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/expansion-panel/footer-button/template.hbs"
    }
  });

  _exports.default = _default;
});