define("flood/components/flood-explorer/issues/list/item/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NiO9Rvg/",
    "block": "{\"symbols\":[\"dimensions\",\"measured\"],\"statements\":[[6,[37,2],null,[[\"class\",\"standalone\"],[\"explorer-issues-inline-chart\",false]],[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"width\",\"height\",\"issue\",\"flood\"],[[32,1,[\"width\"]],[32,1,[\"height\"]],[32,0,[\"issue\"]],[32,0,[\"flood\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"flood-explorer/issues/inline/area\",\"if\",\"responsive-svg\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/issues/list/item/content/template.hbs"
    }
  });

  _exports.default = _default;
});