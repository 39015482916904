define("flood/components/flood-ui/meter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dK3OORvM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"showLabel\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,0,[\"percentage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[32,0,[\"percentage\"]]],[[\"format\",\"suffix\"],[\"0.2f\",\"%\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,0],null,[[\"value\",\"percentage\"],[[32,0,[\"value\"]],[32,0,[\"percentage\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\",\"format\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/meter/template.hbs"
    }
  });

  _exports.default = _default;
});