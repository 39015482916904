define("flood/components/network-trace-response/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YPtF6JpS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"contentIsHTML\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[32,0,[\"trace\",\"responseBody\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"contentIsJSON\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"pre\"],[12],[1,[30,[36,0],[[32,0,[\"trace\",\"responseBody\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"pre\"],[12],[1,[32,0,[\"trace\",\"responseBody\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pretty-json\",\"if\",\"code-block\"]}",
    "meta": {
      "moduleName": "flood/components/network-trace-response/template.hbs"
    }
  });

  _exports.default = _default;
});