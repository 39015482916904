define("flood/templates/projects/project/test-plans/editor/launch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jXJDiVbA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"stream-editor\",[],[[\"@stream\",\"@grids\",\"@launch\",\"@inFlight\",\"@enableDesign\"],[[32,0,[\"model\",\"stream\"]],[32,0,[\"model\",\"grids\"]],[32,0,[\"launch\"]],[32,0,[\"inFlight\"]],false]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/test-plans/editor/launch.hbs"
    }
  });

  _exports.default = _default;
});