define("flood/helpers/p/scale-invert", ["exports", "ember-primer/helpers/p/scale-invert"], function (_exports, _scaleInvert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scaleInvert.default;
    }
  });
  Object.defineProperty(_exports, "pScaleInvert", {
    enumerable: true,
    get: function get() {
      return _scaleInvert.pScaleInvert;
    }
  });
});