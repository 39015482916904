define("flood/utils/format-price", ["exports", "d3-format"], function (_exports, _d3Format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.format = format;
  _exports.formatPriceText = formatPriceText;
  _exports.formatPriceHTML = formatPriceHTML;
  var defaultSymbol = 'US$';
  var defaultFormat = ',d';

  function format() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultFormat;
    return (0, _d3Format.format)(format)(value);
  }

  function formatPriceText(value) {
    var symbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultSymbol;
    var result = "".concat(symbol).concat(format(value));
    return Ember.String.htmlSafe(result);
  }

  function formatPriceHTML(value) {
    var symbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultSymbol;
    var result = "<span class=\"currency-symbol\">".concat(symbol, "</span>").concat(format(value));
    return Ember.String.htmlSafe(result);
  }
});