define("flood/serializers/account", ["exports", "flood/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      owner: {
        serialize: false
      },
      users: {
        serialize: false
      },
      projects: {
        serialize: false
      },
      teams: {
        serialize: false
      },
      uuid: {
        serialize: false
      },
      status: {
        serialize: false
      },
      customerId: {
        serialize: false
      },
      isSubscribed: {
        serialize: false
      },
      isComplimentary: {
        serialize: false
      },
      isInvoiced: {
        serialize: false
      },
      isTrial: {
        serialize: false
      },
      isVum: {
        serialize: false
      },
      isVuh: {
        serialize: false
      },
      isVu: {
        serialize: false
      },
      plan: {
        serialize: false
      },
      coupon: {
        serialize: false
      },
      subscriptionPeriodStartedAt: {
        serialize: false
      },
      subscriptionPeriodFinishesAt: {
        serialize: false
      },
      virtualUsersIncluded: {
        serialize: false
      },
      virtualUserMinutesMeteredTotal: {
        serialize: false
      },
      virtualUserHoursMeteredTotal: {
        serialize: false
      },
      virtualUserMinutesBilledTotal: {
        serialize: false
      },
      virtualUserHoursBilledTotal: {
        serialize: false
      },
      virtualUserMinutesBilledCents: {
        serialize: false
      },
      virtualUserHoursIncluded: {
        serialize: false
      },
      cardLast4: {
        serialize: false
      },
      cardName: {
        serialize: false
      },
      cardExpYear: {
        serialize: false
      },
      cardExpMonth: {
        serialize: false
      },
      cardType: {
        serialize: false
      },
      limits: {
        serialize: false
      },
      nodesPerGridTotal: {
        serialize: false
      },
      nodesActiveTotal: {
        serialize: false
      },
      aggregationPeriod: {
        serialize: false
      },
      usersCount: {
        serialize: false
      },
      regions: {
        serialize: false
      },
      instanceTypes: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});