define("flood/components/account/usage/vum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qw1LKOsU",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[8,\"flud-ui/app/card\",[[24,0,\"usage-summary-vum\"]],[[\"@title\",\"@subtitle\"],[[30,[36,0],[\"account.usage.summary.vum.title\"],null],[32,0,[\"summarySubtitle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"data\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"flood-ui/table\",[],[[\"@dataSource\",\"@columns\"],[[32,0,[\"rows\"]],[32,0,[\"columns\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mdc-typography empty\"],[12],[1,[30,[36,0],[\"account.usage.summary.vum.empty\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/account/usage/vum/template.hbs"
    }
  });

  _exports.default = _default;
});