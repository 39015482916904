define("flood/templates/projects/project/flood/grid/timeline/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKJClBYK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"hasData\"]],[32,0,[\"model\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"flood-explorer/label-table\",[],[[\"@project\",\"@flood\",\"@gridId\",\"@timelineWindow\",\"@data\",\"@selectedLabel\",\"@sortKey\",\"@sortAscending\"],[[32,0,[\"project\"]],[32,0,[\"flood\"]],[32,0,[\"gridId\"]],[32,0,[\"timelineWindow\"]],[34,0],[32,0,[\"selectedLabel\"]],[32,0,[\"sortKey\"]],[32,0,[\"sortAscending\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/flood/grid/timeline/index.hbs"
    }
  });

  _exports.default = _default;
});