define("flood/components/flood-explorer/tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Kfj8QAz",
    "block": "{\"symbols\":[\"stat\",\"flood\"],\"statements\":[[6,[37,1],[[30,[36,4],[[32,0,[\"chartRules\",\"tooltipTitle\"]],[32,0,[\"floods\",\"length\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h5\"],[12],[2,\"\\n    Flood\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"floods\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"projects.project.flood\",[32,2,[\"uuid\"]]]],[[\"default\"],[{\"statements\":[[2,\"#\"],[1,[32,2,[\"sequenceId\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"time\"],[15,\"data-iso8601\",[32,0,[\"isotime\"]]],[12],[1,[32,0,[\"formattedTime\"]]],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"stats\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"formattedStats\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[15,\"data-chart\",[32,1,[\"chart\"]]],[15,\"data-highlighted\",[30,[36,0],[[32,1,[\"measurement\"]],[32,0,[\"highlightedMeasurement\"]]],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"marker\"],[15,5,[32,1,[\"backgroundColourStyle\"]]],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"label\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"value\"],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"units\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"units\"],[12],[1,[32,1,[\"units\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"-track-array\",\"each\",\"and\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});