define("flood/components/stream-editor/launch/control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SaGwchPd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inputs\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inputs__slider\"],[12],[2,\"\\n    \"],[8,\"flud-ui/slider\",[],[[\"@min\",\"@max\",\"@step\",\"@value\",\"@disabled\",\"@onInput\"],[[32,0,[\"sliderMin\"]],[32,0,[\"sliderMax\"]],[32,0,[\"sliderStep\"]],[32,0,[\"value\"]],[32,0,[\"disabled\"]],[30,[36,1],[[32,0,[\"changeHandler\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inputs__text\"],[12],[2,\"\\n    \"],[8,\"flud-ui/text-field\",[],[[\"@label\",\"@value\",\"@disabled\",\"@onChange\"],[[32,0,[\"label\"]],[32,0,[\"value\"]],[32,0,[\"disabled\"]],[30,[36,1],[[32,0,[\"changeHandler\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"message\"]]],[[\"class\"],[\"message-container\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"message\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"flud-ui/icon\",[],[[\"@name\",\"@size\"],[[32,0,[\"icon\"]],\"sm\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"message__content\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--body2\"],[12],[2,\"\\n        \"],[1,[32,0,[\"message\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/launch/control/template.hbs"
    }
  });

  _exports.default = _default;
});