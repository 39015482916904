define("flood/components/danger-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VW9Bgx7M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClassNames\",\"@translucentOverlay\",\"@hasOverlay\",\"@clickOutsideToClose\",\"@attachment\",\"@renderInPlace\",\"@onClose\"],[[32,0,[\"containerClassNames\"]],[32,0,[\"translucentOverlay\"]],[32,0,[\"hasOverlay\"]],true,[32,0,[\"attachment\"]],[32,0,[\"renderInPlace\"]],[30,[36,0],[[32,0],\"secondaryAction\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,0,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"Title\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[14,\"center-center\",\"\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[1,[34,3]],[13],[2,\"\\n      \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"secondaryAction\"],null],[12],[2,\"×\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"Content\"],[12],[2,\"\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"Actions\"],[14,\"layout\",\"\"],[14,\"horizontal\",\"\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"primary\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"flud-ui/button\",[[24,0,\"danger\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"primaryAction\"],null]],null]],[[\"@style\",\"@label\",\"@disabled\"],[\"raised\",[32,0,[\"primaryLabel\"]],[32,0,[\"primaryDisabled\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"flud-ui/button\",[[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"secondaryAction\"],null]],null]],[[\"@label\"],[[32,0,[\"secondaryLabel\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"on\",\"message\",\"title\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/danger-modal/template.hbs"
    }
  });

  _exports.default = _default;
});