define("flood/components/flood-file-uploader/component", ["exports", "flood/uploaders/flood-file", "flood/config/environment"], function (_exports, _floodFile, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _ENV$APP = _environment.default.APP,
      apiHost = _ENV$APP.apiHost,
      apiNamespace = _ENV$APP.apiNamespace;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    can: Ember.inject.service(),
    multiple: true,
    url: "".concat(apiHost, "/").concat(apiNamespace, "/files/upload"),
    files: null,
    uploads: null,
    selectedFiles: null,
    isDragging: false,
    canChangeSelection: true,
    classNames: ['FilePicker'],
    classNameBindings: ['isDragging'],
    init: function init() {
      this._super.apply(this, arguments);

      var options = (arguments.length <= 0 ? undefined : arguments[0]) || {};
      this.files = options.files || [];
      this.selectedFiles = options.selectedFiles || [];
      this.uploads = options.uploads || Ember.A();
    },
    dragEnter: function dragEnter(event) {
      this.set('isDragging', true);
      event.preventDefault();
      event.stopPropagation();
    },
    dragLeave: function dragLeave(event) {
      this.set('isDragging', false);
      event.preventDefault();
      event.stopPropagation();
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    drop: function drop(event) {
      var _this = this;

      event.preventDefault();
      event.stopPropagation();
      this.set('isDragging', false);
      var files = event.target.files || event.dataTransfer.files;

      _toConsumableArray(files).forEach(function (file) {
        return _this.send('uploadFile', file);
      });
    },
    fileDidUpload: function fileDidUpload() {},
    deleteFile: function deleteFile() {},
    toggleFileSelect: function toggleFileSelect() {},
    toggleFileSelectAll: function toggleFileSelectAll() {},
    disabled: Ember.computed('userSettings.projectId', function () {
      return this.can.cannot('modify stream', null, {
        projectId: Ember.get(this.userSettings, 'projectId')
      });
    }),
    actions: {
      uploadFile: function uploadFile(file) {
        var _this2 = this;

        if (this.disabled) return;
        var url = "".concat(apiHost, "/").concat(apiNamespace, "/files/upload");
        var store = this.store;
        var uploads = this.uploads;

        var uploader = _floodFile.default.create({
          url: url
        }, Ember.getOwner(this).ownerInjection());

        var filePromise = Ember.RSVP.defer();
        var upload = {
          progress: 0,
          name: file.name
        };
        uploads.pushObject(upload);
        uploader.on('progress', function (event) {
          Ember.set(upload, 'progress', event.percent);
        });
        uploader.on('didUpload', function (payload) {
          Ember.run.next(_this2, function (payload) {
            uploads.removeObject(upload);
            store.pushPayload(payload);
            var id = payload.data.id;
            this.fileDidUpload(id);
          }, payload);
        });
        uploader.on('didError', function () {
          Ember.set(upload, 'error', 'Upload failed, please try again');
          Ember.run.later(_this2, function () {
            uploads.removeObject(upload);
          }, 5e3);
        });

        if (Ember.isPresent(file)) {
          uploader.upload(file);
        }

        return filePromise.promise;
      },
      deleteFile: function deleteFile(model) {
        var form = document.getElementById('file-uploader-form');
        if (form) form.reset();
        return this.deleteFile(model);
      },
      toggleFileSelect: function toggleFileSelect(file) {
        if (this.canChangeSelection) {
          this.toggleFileSelect(file);
        }
      },
      toggleFileSelectAll: function toggleFileSelectAll() {
        if (this.canChangeSelection) {
          this.toggleFileSelectAll();
        }
      }
    }
  });

  _exports.default = _default;
});