define("flood/components/invitation-link-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yalbbA30",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"invitation-box\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"title\"],[12],[1,[30,[36,2],[\"orgs.members.inviteForm.team.invitationConfirm.title\"],null]],[13],[2,\"\\n  \"],[1,[30,[36,3],[[32,0,[\"showExpiredDlg\"]],[30,[36,2],[\"orgs.members.inviteForm.team.invitationConfirm.expiredMsg\"],null],[30,[36,2],[\"orgs.members.inviteForm.team.invitationConfirm.diffAccountMsg\"],null]],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"button-group\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"showExpiredDlg\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"flud-ui/button\",[[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"done\"],null]],null]],[[\"@style\"],[\"outlined\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"orgs.members.inviteForm.team.invitationConfirm.done\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"showConfirmDlg\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"flud-ui/button\",[[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"done\"],null]],null]],[[\"@style\"],[\"outlined\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"orgs.members.inviteForm.team.invitationConfirm.stayInAccount\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"flud-ui/button\",[[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"logoutAndSignUp\"],null]],null]],[[\"@style\"],[\"outlined\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"orgs.members.inviteForm.team.invitationConfirm.logOutAndSignUp\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"on\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/invitation-link-popup/template.hbs"
    }
  });

  _exports.default = _default;
});