define("flood/components/simple-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5xDgPZKE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"g\"],[14,\"fill\",\"none\"],[14,\"fill-rule\",\"evenodd\"],[12],[2,\"\\n  \"],[10,\"g\"],[14,\"transform\",\"translate(1 1)\"],[15,\"stroke-width\",[32,0,[\"strokeWidth\"]]],[12],[2,\"\\n    \"],[10,\"circle\"],[14,\"stroke-opacity\",\".5\"],[14,\"cx\",\"18\"],[14,\"cy\",\"18\"],[14,\"r\",\"18\"],[12],[13],[2,\"\\n    \"],[10,\"path\"],[14,\"d\",\"M36 18c0-9.94-8.06-18-18-18\"],[12],[2,\"\\n      \"],[10,\"animateTransform\"],[14,\"attributeName\",\"transform\"],[14,\"from\",\"0 18 18\"],[14,\"to\",\"360 18 18\"],[14,\"dur\",\"1s\"],[14,\"repeatCount\",\"indefinite\"],[14,4,\"rotate\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/simple-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});