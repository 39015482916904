define("flood/components/flood-ui/variable-suggestion-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vpDcGtpP",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"variable-suggestion-input \",[34,4]]]],[12],[2,\"\\n\"],[6,[37,5],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[15,\"for\",[32,0,[\"id\"]]],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"input\"],[14,\"autocomplete\",\"off\"],[15,1,[32,0,[\"id\"]]],[15,\"placeholder\",[32,0,[\"placeholder\"]]],[15,3,[32,0,[\"name\"]]],[15,\"disabled\",[32,0,[\"disabled\"]]],[15,2,[34,6]],[15,\"onblur\",[30,[36,0],[[32,0],\"onBlur\"],null]],[15,\"onfocus\",[30,[36,0],[[32,0],\"onFocus\"],null]],[15,\"oninput\",[30,[36,0],[[32,0],[32,0,[\"onValueChanged\"]]],[[\"value\"],[\"target.value\"]]]],[15,\"onkeydown\",[30,[36,0],[[32,0],\"onKeyDown\"],null]],[15,4,[34,7]],[12],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,8],[[32,0,[\"filteredVariables\",\"length\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,1,\"variable-suggestion-list\"],[14,0,\"variable-suggestion-items\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"filteredVariables\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,\"role\",\"button\"],[15,\"onmousedown\",[30,[36,0],[[32,0],\"onItemClick\",[32,1]],null]],[12],[2,\"\\n          \"],[10,\"strong\"],[12],[2,\"${\"],[1,[32,0,[\"incompleteVarName\"]]],[13],[2,\"\\n          \"],[1,[32,1,[\"missingChars\"]]],[2,\"\\n          \"],[10,\"span\"],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\",\"label\",\"classes\",\"if\",\"value\",\"type\",\"gt\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/variable-suggestion-input/template.hbs"
    }
  });

  _exports.default = _default;
});