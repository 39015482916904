define("flood/templates/projects/project/flood/grid/logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L11ahmUG",
    "block": "{\"symbols\":[\"grid\"],\"statements\":[[6,[37,5],[[30,[36,8],[[32,0,[\"grid\"]],[30,[36,7],[[32,0,[\"grids\",\"length\"]],2],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"log-file-viewer\",[],[[\"@entries\",\"@headFirst\",\"@loadNewer\",\"@loadOlder\",\"@toggleHeadFirst\",\"@inFlight\"],[[32,0,[\"entries\"]],[32,0,[\"headFirst\"]],[30,[36,6],[[32,0],\"loadNewEvents\"],null],[30,[36,6],[[32,0],\"loadOlderEvents\"],null],[30,[36,6],[[32,0],\"toggleHeadFirst\"],null],[32,0,[\"inFlight\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"flud-ui/alert\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,3],[\"logViewer.selectGridMessage\"],null]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,0,[\"grids\",\"length\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"ul\"],[14,0,\"mdc-typography mdc-typography--body1 margin-v-1\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"grids\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[12],[2,\"\\n            \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"projects.project.flood.grid.logs\",[32,1,[\"uuid\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[30,[36,0],[[30,[36,0],[[32,0,[\"regions\"]],[32,1,[\"provider\"]]],null],[32,1,[\"region\"]]],null]],[2,\"\\n              \"],[1,[32,1,[\"name\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"-track-array\",\"each\",\"t\",\"gt\",\"if\",\"action\",\"lt\",\"or\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/flood/grid/logs.hbs"
    }
  });

  _exports.default = _default;
});