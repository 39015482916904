define("flood/components/flood-file-uploader/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Ks32VJz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[15,2,[34,0]],[15,\"onchange\",[30,[36,1],[[32,0],\"uploadFiles\"],null]],[15,\"accept\",[32,0,[\"accept\"]]],[15,\"multiple\",[32,0,[\"multiple\"]]],[14,4,\"file\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"action\"]}",
    "meta": {
      "moduleName": "flood/components/flood-file-uploader/input/template.hbs"
    }
  });

  _exports.default = _default;
});