define("flood/components/nine-box/apps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+aPMU9Jv",
    "block": "{\"symbols\":[\"buttonData\"],\"statements\":[[6,[37,2],[[32,0,[\"qTestIdentity\",\"qtestTenantURL\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"tenant mdc-typography mdc-typography--body2\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"integrations.qtest.userConnected\"],null]],[2,\"\\n    \"],[10,\"a\"],[15,6,[32,0,[\"qTestIdentity\",\"qtestTenantURL\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[32,0,[\"qTestIdentity\",\"qtestTenantName\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"app-buttons\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"qTestAppButtons\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"data\",\"close\",\"switchShowingAppMessage\"],[[32,1],[32,0,[\"close\"]],[32,0,[\"switchShowingAppMessage\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"nine-box/nine-box-button\",\"t\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/nine-box/apps/template.hbs"
    }
  });

  _exports.default = _default;
});