define("flood/initializers/moment-lang", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _moment.default.locale('precise-en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: '%d seconds',
        // see https://github.com/timrwood/moment/pull/232#issuecomment-4699806
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
      }
    }); // moment.setLocale('precise-en');

  }

  var _default = {
    name: 'moment-lang',
    initialize: initialize
  };
  _exports.default = _default;
});