define("flood/components/screenshot-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RQ1tjoRa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"Screenshot\"],[12],[2,\"\\n  \"],[10,\"a\"],[15,6,[32,0,[\"src\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[32,0,[\"src\"]]],[15,\"alt\",[31,[\"Screenshot \",[32,0,[\"label\"]]]]],[14,\"width\",\"800\"],[14,\"height\",\"600\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/screenshot-detail/template.hbs"
    }
  });

  _exports.default = _default;
});