define("flood/templates/account/cloud-configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mz0mx7n4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mdc-layout-grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mdc-layout-grid__inner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mdc-layout-grid__cell--span-6\"],[12],[2,\"\\n      \"],[8,\"account/cloud-configuration/grid-hosting\",[],[[\"@credentials\"],[[34,0,[\"credentials\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mdc-layout-grid__cell--span-6\"],[12],[2,\"\\n      \"],[8,\"account/cloud-configuration/storage\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "flood/templates/account/cloud-configuration.hbs"
    }
  });

  _exports.default = _default;
});