define("flood/components/flood-ui/messages/permissions/grids/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GyMOWExS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"showWhen\"],[[32,0,[\"showWhen\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"permissions.grids.noAccess.messageStart\"],null]],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.billing\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"permissions.grids.noAccess.billingLink\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,0],[\"permissions.grids.noAccess.messageEnd\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"flood-ui/messages\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/messages/permissions/grids/template.hbs"
    }
  });

  _exports.default = _default;
});