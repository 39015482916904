define("flood/components/flood-explorer/flood-duration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zjmiIO8f",
    "block": "{\"symbols\":[],\"statements\":[[10,\"time\"],[15,\"datetime\",[32,0,[\"leftDateTime\"]]],[12],[1,[32,0,[\"start\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"bar\"],[15,5,[32,0,[\"barStyle\"]]],[12],[13],[2,\"\\n\"],[10,\"time\"],[15,\"datetime\",[32,0,[\"rightDateTime\"]]],[12],[1,[32,0,[\"finish\"]]],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/flood-duration/template.hbs"
    }
  });

  _exports.default = _default;
});