define("flood/utils/plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PLANS = _exports.PLAN_INTERVALS = void 0;
  var PLAN_INTERVALS = ['month', 'year'];
  _exports.PLAN_INTERVALS = PLAN_INTERVALS;
  var PLANS = [{
    id: 'plan_demand_year_v2018',
    currency: 'usd',
    interval: 'year',
    product: 'product_demand',
    billingScheme: 'tiered',
    intervalCount: 1,
    tiers: [{
      upTo: 20,
      amount: 495
    }, {
      upTo: 50,
      amount: 480
    }, {
      upTo: 100,
      amount: 450
    }, {
      upTo: 200,
      amount: 300
    }, {
      upTo: 300,
      amount: 300
    }, {
      upTo: 400,
      amount: 300
    }, {
      upTo: 500,
      amount: 250
    }, // { upTo: 600, amount: 250 },
    {
      upTo: 700,
      amount: 250
    }, // { upTo: 800, amount: 250 },
    // { upTo: 900, amount: 250 },
    {
      upTo: 1000,
      amount: 200
    }],
    tiersMode: 'volume',
    trialPeriodDays: 0
  }, {
    id: 'plan_demand_month_v2018',
    currency: 'usd',
    interval: 'month',
    product: 'product_demand',
    billingScheme: 'tiered',
    intervalCount: 1,
    tiers: [{
      upTo: 20,
      amount: 745
    }, {
      upTo: 50,
      amount: 598
    }, {
      upTo: 100,
      amount: 499
    }, {
      upTo: 200,
      amount: 350
    }, {
      upTo: 300,
      amount: 333
    }, {
      upTo: 400,
      amount: 333
    }, {
      upTo: 500,
      amount: 300
    }, // { upTo: 600, amount: 300 },
    {
      upTo: 700,
      amount: 300
    }, // { upTo: 800, amount: 300 },
    // { upTo: 900, amount: 300 },
    {
      upTo: 1000,
      amount: 225
    }],
    tiersMode: 'volume',
    trialPeriodDays: 0
  }];
  _exports.PLANS = PLANS;
});