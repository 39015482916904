define("flood/components/console-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W0GMQDF0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"consolePath\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"iframe\"],[14,\"title\",\"Flood Console\"],[15,\"src\",[32,0,[\"src\"]]],[14,\"allowtransparency\",\"\"],[12],[2,\"\\n    \"],[8,\"flud-ui/loader/logo\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/components/console-page/template.hbs"
    }
  });

  _exports.default = _default;
});