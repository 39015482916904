define("flood/controllers/projects/project/infrastructure/grids/regions/grid", ["exports", "ember-changeset-validations/validators", "luxon"], function (_exports, _validators, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    segment: Ember.inject.service(),
    router: Ember.inject.service(),
    GridLifecycleValidations: null,
    infrastructureController: null,
    grid: null,
    currentTab: 1,
    isConfirmingStopGrid: false,
    queryParams: [{
      currentTab: {
        scope: 'controller'
      }
    }],
    init: function init() {
      this._super.apply(this, arguments);

      var options = (arguments.length <= 0 ? undefined : arguments[0]) || {};
      this.GridLifecycleValidations = options.GridLifecycleValidations || {
        stopAfter: [(0, _validators.validateNumber)({
          allowBlank: false,
          gte: 0.2 * 60,
          lte: 48 * 60,
          message: 'Total grid lifetime must be within 0.2 and 48 hours'
        })]
      };
    },
    nodeIPs: Ember.computed('grid.nodes.[]', {
      get: function get() {
        return this.grid.nodes.map(function (node) {
          return node.publicIpv4 || node.localIpv4;
        }).join(',');
      }
    }),
    // Stores current timestamp, used to regularly invalid computed properties on an interval
    _timeTicker: null,
    gridHasLifespan: Ember.computed('uptime', 'grid.stopAfter', {
      get: function get() {
        var grid = this.grid;
        return !grid.isStandalone && grid.status === 'started';
      }
    }),
    uptime: Ember.computed('_timeTicker', 'grid.startedAt', {
      get: function get() {
        var startedAt = this.grid.startedAt;

        var start = _luxon.DateTime.fromJSDate(startedAt);

        var now = _luxon.DateTime.local();

        var diff = now.diff(start, ['hours', 'minutes']);
        return this.formatTimeDiff(diff);
      }
    }),
    stoppingIn: Ember.computed('_timeTicker', 'grid.{startedAt,stopAfter}', {
      get: function get() {
        var _this$grid = this.grid,
            startedAt = _this$grid.startedAt,
            stopAfter = _this$grid.stopAfter;

        var start = _luxon.DateTime.fromJSDate(startedAt);

        var end = start.plus({
          minutes: stopAfter
        });

        var now = _luxon.DateTime.local();

        var diff = end.diff(now, ['hours', 'minutes']);
        return this.formatTimeDiff(diff);
      }
    }),
    hydrometerIsDisabled: Ember.computed('grid.{additionalMetadata,hasNodeWithHydrometerData,isStandalone}', {
      get: function get() {
        return this.grid.isStandalone && !this.grid.hasNodeWithHydrometerData;
      }
    }),
    formatTimeDiff: function formatTimeDiff(diff) {
      var hours = diff.hours && Math.trunc(diff.hours);
      var minutes = diff.minutes && Math.ceil(diff.minutes);

      if (hours && minutes) {
        return "".concat(hours, "h ").concat(minutes, "m");
      } else if (hours) {
        return "".concat(hours, "h ").concat(minutes, "m");
      } else {
        return "".concat(minutes, "m");
      }
    },
    actions: {
      toggleLaunchGridModal: function toggleLaunchGridModal(region) {
        this.infrastructureController.send('toggleLaunchGridModal', region);
        this.segment.trackEvent('Grid Toggle Launch Modal', {
          region: region
        });
      },
      selectTab: function selectTab(tabIndex) {
        this.set('currentTab', tabIndex);
        this.segment.trackEvent('Grid Active Tab Changed', {
          tabIndex: tabIndex
        });
      },
      cancelStopGridModal: function cancelStopGridModal() {
        this.set('isConfirmingStopGrid', false);
      },
      confirmStopGrid: function confirmStopGrid() {
        this.set('isConfirmingStopGrid', true);
      },
      openExtender: function openExtender() {
        var _this$grid2;

        if (!((_this$grid2 = this.grid) === null || _this$grid2 === void 0 ? void 0 : _this$grid2.isHosted)) return;
        this.toggleProperty('isExtenderPanelOpen');
      },
      cancelExtendingGrid: function cancelExtendingGrid() {
        this.set('isExtenderPanelOpen', false);
      },
      stopGrid: function stopGrid() {
        var _this = this;

        var grid = this.grid;
        this.segment.trackEvent('Grid Stop', {
          grid: grid.id,
          gridName: grid.name
        });
        return grid.shutdown().then(function () {
          _this.segment.trackEvent('Grid Stop Completed', {
            grid: grid.id,
            gridName: grid.name
          });

          _this.send('cancelStopGridModal');

          if (grid.get('isDestroyed') || grid.get('isDestroying') || grid.get('isDeleted')) {
            return;
          }

          grid.set('status', 'stopped');
          return grid.reload();
        }).then(function () {
          return _this.router.transitionTo('projects.project.infrastructure.grids');
        });
      }
    }
  });

  _exports.default = _default;
});