define("flood/components/account/organizations-section/cell-items/projects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D0/K8kLF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[[24,0,\"count-container\"]],[[\"@route\",\"@model\"],[\"account.orgs.org.projects\",[32,0,[\"data\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[\"orgs.tables.counts.projects\"],[[\"count\",\"htmlSafe\"],[[32,0,[\"data\",\"projectCount\"]],true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/account/organizations-section/cell-items/projects/template.hbs"
    }
  });

  _exports.default = _default;
});