define("flood/utils/interpolation", ["exports", "ember-primer/utils/interpolation"], function (_exports, _interpolation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _interpolation.default;
    }
  });
});