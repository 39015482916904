define("flood/components/stream-editor/design/configure/uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u/7ZxyvJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"stream-editor/design/configure/uploader/test-files\",[],[[\"@stream\",\"@tools\"],[[32,0,[\"stream\"]],[32,0,[\"tools\"]]]],null],[2,\"\\n\"],[8,\"stream-editor/design/configure/uploader/tool\",[],[[\"@stream\",\"@tools\"],[[32,0,[\"stream\"]],[32,0,[\"tools\"]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,0,[\"stream\",\"tool\"]],\"flood-chrome\"],null]],null]],[[\"use\"],[\"crossFade\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"stream-editor/design/configure/uploader/options\",[],[[\"@stream\"],[[32,0,[\"stream\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"not\",\"liquid-if\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/configure/uploader/template.hbs"
    }
  });

  _exports.default = _default;
});