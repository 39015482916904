define("flood/components/global-header/banner/admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AnezAWfN",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flud-ui/icon\",[],[[\"@name\",\"@size\"],[\"warning\",\"sm\"]],null],[2,\"\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"banners.admin.message\"],[[\"email\",\"htmlSafe\"],[[32,0,[\"email\"]],true]]]],[13],[2,\"\\n\"],[8,\"flud-ui/button\",[[4,[38,1],[\"click\",[32,0,[\"signOut\"]]],null]],[[\"@style\",\"@label\"],[\"outlined\",[30,[36,0],[\"banners.admin.action\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/global-header/banner/admin/template.hbs"
    }
  });

  _exports.default = _default;
});