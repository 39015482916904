define("flood/utils/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INVITATION_ROUTE = _exports.USER_ACTIVATION = _exports.SOCIAL_ACCEPT_INVITATION = _exports.NG_INVITATION_TOKEN = _exports.USER_INVITATION_TOKEN = _exports.INVITE_TO_TEAM = _exports.INVITE_TO_ORG = _exports.INVITE_TO_ACCOUNT = void 0;
  var INVITE_TO_ACCOUNT = 'invite-to-account';
  _exports.INVITE_TO_ACCOUNT = INVITE_TO_ACCOUNT;
  var INVITE_TO_ORG = 'invite-to-org';
  _exports.INVITE_TO_ORG = INVITE_TO_ORG;
  var INVITE_TO_TEAM = 'invite-to-team';
  _exports.INVITE_TO_TEAM = INVITE_TO_TEAM;
  var USER_INVITATION_TOKEN = 'user-invitation';
  _exports.USER_INVITATION_TOKEN = USER_INVITATION_TOKEN;
  var NG_INVITATION_TOKEN = 'ng-invitation';
  _exports.NG_INVITATION_TOKEN = NG_INVITATION_TOKEN;
  var SOCIAL_ACCEPT_INVITATION = 'social-accept-invitation';
  _exports.SOCIAL_ACCEPT_INVITATION = SOCIAL_ACCEPT_INVITATION;
  var USER_ACTIVATION = 'user-activation';
  _exports.USER_ACTIVATION = USER_ACTIVATION;
  var INVITATION_ROUTE = {
    'invite-to-account': {
      getRoute: function getRoute() {
        return '/account/orgs';
      }
    },
    'invite-to-org': {
      getRoute: function getRoute(payload) {
        return "/account/orgs/".concat(payload.organizationUser.organization.uuid, "/teams");
      }
    },
    'invite-to-team': {
      getRoute: function getRoute(payload) {
        return "/account/orgs/".concat(payload.teamUser.team.organization.uuid, "/teams/").concat(payload.teamUser.team.uuid);
      }
    },
    'ng-invitation': {
      getRoute: function getRoute(payload) {
        if (payload.team) {
          return "/account/orgs/".concat(payload.organization.uuid, "/teams/").concat(payload.team.uuid);
        }

        return "/account/orgs/".concat(payload.organization.uuid);
      }
    }
  };
  _exports.INVITATION_ROUTE = INVITATION_ROUTE;
});