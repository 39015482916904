define("flood/components/flood-explorer/flood-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CNv2uVkd",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"floodHasData\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,0,[\"stringTimelineWindow\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"projects.project.flood.grid.timeline\",[30,[36,0],[[32,0,[\"project\"]],[32,0,[\"flood\"]],[32,0,[\"gridId\"]],[32,0,[\"stringTimelineWindow\",\"start\"]],[32,0,[\"stringTimelineWindow\",\"end\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"explorer.controls.timeline\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"projects.project.flood.grid.index\",[30,[36,0],[[32,0,[\"project\"]],[32,0,[\"flood\"]],[32,0,[\"gridId\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"explorer.controls.timeline\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,0,[\"floodIsOver\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"projects.project.flood.grid.timeline\",[30,[36,0],[[32,0,[\"project\"]],[32,0,[\"flood\"]],[32,0,[\"gridId\"]],[32,0,[\"flood\",\"startedAt\"]],[32,0,[\"flood\",\"stoppedAt\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"explorer.controls.timeline\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"disabled\"],[12],[2,\"Timeline\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"projects.project.flood.grid.logs\",[30,[36,0],[[32,0,[\"project\"]],[32,0,[\"flood\"]],[32,0,[\"gridId\"]]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"explorer.controls.logs\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/flood-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});