define("flood/components/flood-ui/form/reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8mqOc0s",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[8,\"flud-ui/button\",[[17,2],[16,4,[34,1]]],[[\"@style\",\"@disabled\"],[[32,0,[\"style\"]],[32,0,[\"isDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"type\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/form/reset/template.hbs"
    }
  });

  _exports.default = _default;
});