define("flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/json", ["exports", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/const", "flood/components/stream-editor/design/configure/builder/steps/step-editor/request-builder/handler/divert"], function (_exports, _const, _divert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initEventClickForJson = initEventClickForJson;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function extractJsonKeys(keys, jsonObj, path) {
    if (jsonObj instanceof Array) {
      jsonObj.forEach(function (item, index) {
        if (_typeof(item) === 'object') {
          extractJsonKeys(keys, item, "".concat(path, ".[").concat(index, "]"));
        }
      });
    } else {
      var _loop = function _loop(key) {
        var currentPath = "".concat(path, ".").concat(key);
        keys.push([key, currentPath.substring(1), jsonObj[key]]);

        if (_typeof(jsonObj[key]) === 'object') {
          if (!(jsonObj[key] instanceof Array)) {
            extractJsonKeys(keys, jsonObj[key], currentPath);
          } else {
            jsonObj[key].forEach(function (item, index) {
              if (_typeof(item) === 'object') {
                extractJsonKeys(keys, item, "".concat(currentPath, "[").concat(index, "]"));
              }
            });
          }
        }
      };

      for (var key in jsonObj) {
        _loop(key);
      }
    }
  }

  function mapJsonKeys(parsedKeys, markupKeys) {
    parsedKeys.map(function (item, index) {
      var elm = markupKeys[index];
      elm.setAttribute(_const.JSON_PATH, item[1]);
      elm.setAttribute(_const.JSON_VALUE, item[2]);
      var elmClass = elm.getAttribute(_const.CLASS_NAME);
      elm.setAttribute(_const.CLASS_NAME, "".concat(elmClass, " highlight-key"));
    });
  }

  function onClick(element, context) {
    var key = element.getAttribute(_const.JSON_PATH);
    var value = element.getAttribute(_const.JSON_VALUE);
    context.set(_const.CURRENT_KEY, key);
    context.set(_const.CURRENT_VALUE, value);
    context.set(_const.CURRENT_NODE_DATA, "<span class=\"modal-key\">".concat(key, "</span> = <span class=\"modal-value\">").concat((0, _divert.truncate)(value), "</span>"));
    context.set(_const.SELECTED_VARIABLES, {
      name: value
    });
    context.set(_const.ASSERT_DISABLE_FLAG, false);
    context.set(_const.SHOW_MODAL, true);
  }

  function initEventClickForJson(data, context) {
    var parsedKeys = [];
    extractJsonKeys(parsedKeys, JSON.parse(data), '/$');
    setTimeout(function () {
      var markupKeys = document.getElementsByClassName(_const.MARKUP_JSON);

      if (markupKeys && markupKeys.length === parsedKeys.length) {
        var _iterator = _createForOfIteratorHelper(markupKeys),
            _step;

        try {
          var _loop2 = function _loop2() {
            var item = _step.value;
            item.addEventListener('click', function () {
              onClick(item, context);
            });
          };

          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop2();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        mapJsonKeys(parsedKeys, markupKeys);
      } else {
        Ember.debug('Json elements does not render on screen');
      }

      (0, _divert.setLoadingMask)(false);
    }, 200);
  }
});