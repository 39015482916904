define("flood/components/stream-editor/design/configure/builder/host/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H4VoJu57",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stream-input__title\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"mdc-typography--subtitle1\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.host.title\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"stream-input__control\"],[12],[2,\"\\n  \"],[8,\"flud-ui/text-field\",[],[[\"@label\",\"@value\",\"@onChange\",\"@disabled\"],[[30,[36,0],[\"streamEditor.design.fields.host.label\"],null],[32,0,[\"stream\",\"baseURL\"]],[30,[36,1],[[32,0,[\"setValue\"]]],null],[30,[36,2],[\"modify stream\"],[[\"projectId\"],[[32,0,[\"stream\",\"project\",\"id\"]]]]]]],null],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography mdc-typography--caption\"],[12],[1,[30,[36,0],[\"streamEditor.design.fields.host.hint\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"cannot\"]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/configure/builder/host/template.hbs"
    }
  });

  _exports.default = _default;
});