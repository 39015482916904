define("flood/components/flood-ui/content-pane/empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iNg4z0AE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"p\"],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"message\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    empty\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"message\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/content-pane/empty/template.hbs"
    }
  });

  _exports.default = _default;
});