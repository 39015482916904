define("flood/templates/projects/project/test-plans/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ek/gCUum",
    "block": "{\"symbols\":[\"pane\"],\"statements\":[[8,\"flud-ui/app/page\",[[24,0,\"stream-editor\"]],[[\"@compactHeader\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"stream-editor/header\",[],[[\"@stream\",\"@deleteAction\"],[[32,0,[\"model\",\"stream\"]],[30,[36,0],[[32,0],\"deleteStream\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"stream-editor/delete\",[],[[\"@stream\",\"@showWhen\"],[[32,0,[\"model\",\"stream\"]],[32,0,[\"isConfirmingDelete\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/test-plans/editor.hbs"
    }
  });

  _exports.default = _default;
});