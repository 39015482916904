define("flood/helpers/relative-time-range", ["exports", "luxon", "flood/utils/time-window"], function (_exports, _luxon, _timeWindow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relativeTimeRange = relativeTimeRange;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function relativeTimeRange(_params, options) {
    var _options$unit = options.unit,
        unit = _options$unit === void 0 ? 's' : _options$unit,
        _options$value = options.value,
        value = _options$value === void 0 ? 0 : _options$value,
        _options$rangeId = options.rangeId,
        rangeId = _options$rangeId === void 0 ? '' : _options$rangeId;

    var initial = options.initial || _luxon.DateTime.local();

    if (typeof initial === 'string') initial = _luxon.DateTime.fromISO(initial);

    if (rangeId) {
      var _rangeId$split = rangeId.split('-'),
          _rangeId$split2 = _slicedToArray(_rangeId$split, 2),
          rangeValue = _rangeId$split2[0],
          rangeUnit = _rangeId$split2[1];

      unit = rangeUnit;
      value = rangeValue;
    }

    try {
      var _getRelativeTimeRange = (0, _timeWindow.relativeTimeRange)(value, unit, initial),
          startDate = _getRelativeTimeRange.start,
          endDate = _getRelativeTimeRange.end;

      var start = startDate.toLocaleString(_luxon.DateTime.DATE_MED);
      var end = endDate.toLocaleString(_luxon.DateTime.DATE_MED);
      return "".concat(start, "\u2013").concat(end);
    } catch (e) {
      return '';
    }
  }

  var _default = Ember.Helper.helper(relativeTimeRange);

  _exports.default = _default;
});