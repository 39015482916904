define("flood/mixins/chart", ["exports", "flood/mixins/global-resize", "d3-shape", "d3-selection", "flood/utils/idle-callback", "flood/config/environment"], function (_exports, _globalResize, _d3Shape, _d3Selection, _idleCallback, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_globalResize.default, {
    /**
     * If we’re able to render, this will be set to a d3 selection of our chart
     * element.
     *
     * @type {D3[Element]}
     */
    chart: null,

    /**
     * Initial width
     *
     * @type {Number}
     */
    width: 1024,

    /**
     * Initial height
     *
     * @type {Number}
     */
    height: 400,
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    lineFn: function lineFn() {
      var xScale = this.xScale;
      var yScale = this.yScale;
      return (0, _d3Shape.line)().x(function (d) {
        return xScale(d[0]);
      }).y(function (d) {
        return yScale(d[1]);
      }).defined(function (d) {
        return d && d[1] > 0 && !isNaN(d[0]);
      }).curve(_d3Shape.curveNatural);
    },

    /**
     * Set to true when mouse enters the chart.
     *
     * @type {Boolean}
     */
    mouseInElement: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, function () {
        _this.selectChartElement();

        _this.measureDimensions();

        _this.redraw(true);
      });
    },
    selectChartElement: function selectChartElement() {
      this.chart = (0, _d3Selection.select)(this.chartElement());
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.chart = null;
    },

    /**
     * Override this method if not using a default svg tag in the template.
     *
     * @return {Element} The SVG element
     */
    chartElement: function chartElement() {
      if (!this.element) {
        return;
      }

      return this.element.querySelector('svg');
    },
    scheduleRedraw: function scheduleRedraw() {
      Ember.run.scheduleOnce('afterRender', this, this.redraw, false);
    },

    /**
     * Override this.
     *
     * @type {Function(firstDraw)}
     */
    redraw: function redraw() {},
    didResize: function didResize() {
      var _this2 = this;

      this._super.apply(this, arguments);

      (0, _idleCallback.default)(function () {
        return _this2.measureDimensions();
      });
    },
    mouseEnter: function mouseEnter() {
      if (!this.element) {
        return;
      }

      this.set('mouseInElement', true);
    },
    mouseLeave: function mouseLeave() {
      if (!this.element) {
        return;
      }

      this.set('mouseInElement', false);
    },

    /**
     * Computed dimensions for the actual chart.
     *
     * @return {Object}
     */
    chartArea: Ember.computed('height', 'margin', 'width', {
      get: function get() {
        var height = this.height;
        var width = this.width;
        var margin = this.margin;
        return {
          top: margin.top,
          left: margin.left,
          bottom: height - margin.top,
          right: width - margin.right,
          height: height - margin.top - margin.bottom,
          width: width - margin.left - margin.right,
          outerWidth: width,
          outerHeight: height
        };
      }
    }),

    /**
     * Measures our dimensions so we can size the internal SVG tag
     */
    measureDimensions: function measureDimensions() {
      if (_environment.default.environment === 'test' || !this.element) return;

      var _this$element$getBoun = this.element.getBoundingClientRect(),
          width = _this$element$getBoun.width,
          height = _this$element$getBoun.height;

      this.setProperties({
        width: width,
        height: height
      });
    }
  });

  _exports.default = _default;
});