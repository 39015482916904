define("flood/components/account/members-section/cell-items/membership/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3s4mdo5B",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[10,\"span\"],[14,0,\"membership\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"context\"]],\"org\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],[\"orgs.teams.count.non-subteam\"],[[\"count\"],[[32,0,[\"numOfTeams\"]]]]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"orgs.organizations.shortTitle\"],[[\"count\"],[[32,0,[\"numOfOrgs\"]]]]]],[2,\",\\n    \"],[1,[30,[36,0],[\"orgs.teams.count.non-subteam\"],[[\"count\"],[[32,0,[\"numOfTeams\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"capitalize\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/account/members-section/cell-items/membership/template.hbs"
    }
  });

  _exports.default = _default;
});