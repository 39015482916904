define("flood/components/billing/invoices/cells/download-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W8/rttf2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[32,0,[\"data\",\"pdf\"]]],[12],[1,[30,[36,0],[\"billing.invoices.rows.download\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "flood/components/billing/invoices/cells/download-link/template.hbs"
    }
  });

  _exports.default = _default;
});