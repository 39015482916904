define("flood/utils/idle-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = idleCallback;

  function idleCallback(fn) {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(fn);
    } else {
      Ember.run.debounce(fn, 50, false);
    }
  }
});