define("flood/helpers/is-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPresentHelper = isPresentHelper;
  _exports.default = void 0;

  function isPresentHelper(params) {
    return params.every(function (i) {
      return Ember.isPresent(i);
    });
  }

  var _default = Ember.Helper.helper(isPresentHelper);

  _exports.default = _default;
});