define("flood/components/account/change-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VA4rMOU/",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[8,\"flud-ui/app/card\",[],[[\"@title\"],[[30,[36,0],[\"account.user.password.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[14,0,\"mdc-typography margin-t-0\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"account.user.password.currentUser\"],[[\"name\",\"email\",\"htmlSafe\"],[[32,0,[\"user\",\"fullName\"]],[32,0,[\"user\",\"email\"]],true]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"sentEmail\"]]],[[\"use\"],[\"crossFade\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mdc-typography margin-t-0 success\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"account.user.password.resetEmailSentMessage\"],[[\"email\",\"htmlSafe\"],[[32,0,[\"user\",\"email\"]],true]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"error\"]]],[[\"use\"],[\"crossFade\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mdc-typography margin-t-0 error\"],[12],[1,[32,0,[\"error\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"flud-ui/button\",[[4,[38,3],[\"click\",[30,[36,2],[[32,0],\"changePassword\"],null]],null]],[[\"@style\",\"@disabled\"],[\"raised\",[32,0,[\"inFlight\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"account.user.password.buttonLabel\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"liquid-if\",\"action\",\"on\"]}",
    "meta": {
      "moduleName": "flood/components/account/change-password/template.hbs"
    }
  });

  _exports.default = _default;
});