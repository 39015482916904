define("flood/components/flood-ui/preloader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3hWxJ+eu",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[10,\"div\"],[14,0,\"wrapper\"],[12],[2,\"\\n  \"],[8,\"flud-ui/app/card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"flud-ui/loader/logo\",[[24,0,\"flood-logo-loader\"]],[[],[]],null],[2,\"\\n      \"],[10,\"p\"],[14,1,\"preloader-message\"],[14,0,\"mdc-typography mdc-typography--body1 message\"],[12],[2,\"Loading…\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"script\"],[14,1,\"app-shell-messages\"],[15,\"data-loading-messages\",[32,0,[\"messages\"]]],[12],[13],[2,\"\\n\"],[10,\"script\"],[14,1,\"app-shell\"],[12],[2,\"\\n  try {\\n    function renderRandomMessage() {\\n      // Clear yourself when removed from DOM\\n      if (!loadingMessages || !document.querySelector(messageElSelector)) {\\n        clearInterval(interval)\\n        return\\n      }\\n\\n      var loadingMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)]\\n      messageEl.textContent = `${loadingMessage}…`\\n    }\\n\\n    var loadingMessagesEl = document.getElementById('app-shell-messages')\\n    var loadingMessages = JSON.parse(loadingMessagesEl.getAttribute('data-loading-messages'))\\n    var messageElSelector = '#preloader-message'\\n    var messageEl = document.querySelector(messageElSelector)\\n    var interval = setInterval(renderRandomMessage, 3e3)\\n\\n    renderRandomMessage()\\n  } catch(err) {\\n    clearInterval(interval)\\n  }\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/preloader/template.hbs"
    }
  });

  _exports.default = _default;
});