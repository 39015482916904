define("flood/components/flood-ui/table/actions-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0o0UvXGG",
    "block": "{\"symbols\":[\"tableAction\"],\"statements\":[[6,[37,6],[[32,0,[\"actionsVisible\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[32,0,[\"tableActions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,4],[[32,1],[30,[36,5],[[30,[36,4],[[32,1,[\"singleSelection\"]],[30,[36,3],[[32,0,[\"selectionCount\"]],1],null]],null],[30,[36,2],[[32,1,[\"singleSelection\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"flud-ui/button\",[[4,[38,1],[\"click\",[30,[36,0],[[32,1,[\"action\"]]],null]],null]],[[\"@label\",\"@icon\"],[[32,1,[\"name\"]],[32,1,[\"icon\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"not\",\"eq\",\"and\",\"or\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/table/actions-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});