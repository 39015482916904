define("flood/utils/series-to-stack", ["exports", "d3-array", "d3-shape"], function (_exports, _d3Array, _d3Shape) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = seriesToSack;
  _exports.normalizeData = normalizeData;

  function seriesToSack(data) {
    var step = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 15e3;
    var keys = Object.keys(data).sort(function (a, b) {
      return (0, _d3Array.descending)((0, _d3Array.max)(data[a], function (d) {
        return d[1];
      }), (0, _d3Array.max)(data[b], function (d) {
        return d[1];
      }));
    });
    var unstackedData = normalizeData(data, keys, step);
    var stackGenerator = (0, _d3Shape.stack)().keys(keys).offset(_d3Shape.stackOffsetNone).order(_d3Shape.stackOrderAscending);
    return stackGenerator(unstackedData);
  }

  function ascendingTimestamp(a, b) {
    return (0, _d3Array.ascending)(a[0], b[0]);
  }

  function normalizeData(data, keys, step) {
    /**
     * Array of rows representing each results by {timestamp, key1, key2, ...}
     *
     * @type {Array}
     */
    var results = [];
    var xMin = (0, _d3Array.min)(keys, function (k) {
      return (0, _d3Array.min)(data[k], function (d) {
        return d[0];
      });
    });
    var xMax = (0, _d3Array.max)(keys, function (k) {
      return (0, _d3Array.max)(data[k], function (d) {
        return d[0];
      });
    });
    var timestampsRange = (0, _d3Array.range)(xMin, xMax + step, step);
    var index = -1;
    var timestamp;

    while (++index < timestampsRange.length) {
      timestamp = timestampsRange[index];
      var i = -1;
      var key = void 0;

      while (++i < keys.length) {
        key = keys[i];
        var keyData = data[key].sort(ascendingTimestamp);
        var timestamps = keyData.map(function (d) {
          return d[0];
        });

        if (!results[index]) {
          results[index] = {
            timestamp: timestamp
          };
        }

        if (timestamps.indexOf(timestamp) === -1) {
          results[index][key] = 0;
        } else {
          results[index][key] = keyData.find(function (d) {
            return d[0] === timestamp;
          })[1];
        }
      }
    }

    return results;
  }
});