define("flood/components/flood-ui/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fquB14G8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"data\",\"inFlight\",\"disabled\",\"errors\",\"input\",\"textarea\",\"select\",\"checkbox\",\"reset\",\"submit\"],[[32,0,[\"data\"]],[32,0,[\"inFlight\"]],[32,0,[\"isDisabled\"]],[32,0,[\"errors\"]],[30,[36,0],[\"flood-ui/form/input\"],[[\"data\",\"errors\"],[[32,0,[\"data\"]],[32,0,[\"errors\"]]]]],[30,[36,0],[\"flood-ui/form/textarea\"],[[\"data\",\"errors\"],[[32,0,[\"data\"]],[32,0,[\"errors\"]]]]],[30,[36,0],[\"flood-ui/form/select\"],[[\"data\",\"errors\"],[[32,0,[\"data\"]],[32,0,[\"errors\"]]]]],[30,[36,0],[\"flood-ui/form/checkbox\"],[[\"data\",\"errors\"],[[32,0,[\"data\"]],[32,0,[\"errors\"]]]]],[30,[36,0],[\"flood-ui/form/reset\"],[[\"inFlight\",\"disabled\"],[[32,0,[\"inFlight\"]],[32,0,[\"isDisabled\"]]]]],[30,[36,0],[\"flood-ui/form/submit\"],[[\"inFlight\",\"disabled\"],[[32,0,[\"inFlight\"]],[32,0,[\"isDisabled\"]]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "flood/components/flood-ui/form/template.hbs"
    }
  });

  _exports.default = _default;
});