define("flood/utils/account-menu-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AccountMenuOptions = [{
    icon: 'person',
    title: 'header.account.menu.items.personalDetails',
    route: 'account.user'
  }, {
    icon: 'language',
    title: 'header.account.menu.items.orgs',
    route: 'account.orgs'
  }, {
    icon: 'payment',
    title: 'header.account.menu.items.billing',
    route: 'account.billing',
    permission: 'manage subscriptions'
  }, {
    icon: 'timelapse',
    title: 'header.account.menu.items.usage',
    route: 'account.usage'
  }, {
    icon: 'widgets',
    title: 'header.account.menu.items.integrations',
    route: 'account.integrations',
    permission: 'manage integrations'
  }, {
    icon: 'cloud',
    title: 'header.account.menu.items.cloud-configuration',
    route: 'account.cloud-configuration',
    permission: 'manage integrations'
  }, {
    icon: 'security',
    title: 'header.account.menu.items.api',
    route: 'account.api'
  }, {
    icon: 'exit_to_app',
    title: 'header.account.menu.items.signOut',
    action: 'signOut'
  }];
  var _default = AccountMenuOptions;
  _exports.default = _default;
});