define("flood/components/account/multiple-creating-and-selecting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u0+aZz+e",
    "block": "{\"symbols\":[\"conditionError\",\"itemError\",\"data\",\"&default\"],\"statements\":[[10,\"label\"],[14,\"for\",\"comp-mul-creating-selecting\"],[12],[1,[34,3]],[13],[2,\"\\n\"],[8,\"power-select-multiple-with-create\",[],[[\"@triggerClass\",\"@triggerId\",\"@placeholder\",\"@selected\",\"@renderInPlace\",\"@searchEnabled\",\"@search\",\"@searchField\",\"@onChange\",\"@onCreate\",\"@onInput\"],[\"mul-creating-selecting\",\"comp-mul-creating-selecting\",[32,0,[\"placeholder\"]],[32,0,[\"selectedData\"]],true,true,[30,[36,4],[[32,0,[\"searchInDataSource\"]]],null],[32,0,[\"searchKey\"]],[30,[36,5],[[32,0],\"update\"],null],[30,[36,5],[[32,0],\"add\"],null],[30,[36,5],[[32,0],\"onInput\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,4,[[32,3]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[32,3],[32,0,[\"showKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[3]}]]],[2,\"\\n\"],[6,[37,6],[[32,0,[\"duplicatedErrors\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"duplicated-message\"],[12],[1,[32,0,[\"removedDuplicatesMessage\"]]],[13],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"duplicatedErrors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"duplicated-message\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"conditionErrors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"error\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"get\",\"label\",\"perform\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/account/multiple-creating-and-selecting/template.hbs"
    }
  });

  _exports.default = _default;
});