define("flood/utils/string-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.collapseWhitespace = collapseWhitespace;
  _exports.customMultiEmailWithComma = customMultiEmailWithComma;

  function collapseWhitespace(text) {
    if (text) {
      return text.trim().replace(/\s\s+/g, ' ');
    }

    return text;
  }

  function customMultiEmailWithComma(emails) {
    if (emails) {
      emails = emails.trim().replace(/[\s;]/g, ',');
      return emails.replace(/,+/g, ',').toLowerCase();
    }

    return emails;
  }
});