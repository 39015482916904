define("flood/utils/all-grids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allGridSelection = _exports.withAllGrids = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var withAllGrids = function withAllGrids(grids) {
    return Ember.A([allGridSelection(grids)].concat(_toConsumableArray(grids.toArray())));
  };

  _exports.withAllGrids = withAllGrids;

  var allGridSelection = function allGridSelection(grids) {
    var name = "".concat(grids.length, " grids");
    var instanceQuantity = grids.mapBy('instanceQuantity').reduce(function (a, b) {
      return a += b;
    });
    return {
      name: name,
      instanceQuantity: instanceQuantity,
      id: 'all',
      uuid: 'all',
      sequenceId: 'all-grids',
      region: 'grids.all',
      status: 'stopped',
      isStarted: true,
      percentComplete: 100
    };
  };

  _exports.allGridSelection = allGridSelection;
});