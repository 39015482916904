define("flood/components/stream-editor/design/configure/builder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3PS+Stt+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"stream-editor/design/configure/builder/host\",[],[[\"@stream\"],[[32,0,[\"stream\"]]]],null],[2,\"\\n\"],[8,\"stream-editor/design/configure/builder/steps\",[],[[\"@stream\",\"@currentStepIndex\"],[[32,0,[\"stream\"]],[32,0,[\"currentStepIndex\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/components/stream-editor/design/configure/builder/template.hbs"
    }
  });

  _exports.default = _default;
});