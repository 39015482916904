define("flood/templates/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N/jo6DGS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-ui/landing\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"activationError\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"invitation-result\",[],[[\"@isExpired\",\"@type\"],[true,\"verification\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"welcome-form\",[],[[\"@activationError\",\"@registrationInfo\",\"@email\",\"@token\"],[[32,0,[\"model\",\"activationError\"]],[32,0,[\"model\",\"registrationInfo\"]],[32,0,[\"model\",\"email\"]],[32,0,[\"model\",\"token\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "flood/templates/welcome.hbs"
    }
  });

  _exports.default = _default;
});