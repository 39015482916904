define("flood/components/flood-explorer/controls/relative-time-window/option-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gq/nwpoq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[1,[30,[36,3],[[30,[36,2],[\"explorer.controls.relativeTimeWindows.\",[32,0,[\"option\"]]],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,0,[\"option\"]],\"project\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,\"secondary\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"start\",\"end\"],[[32,0,[\"project\",\"firstResultAt\"]],[32,0,[\"project\",\"lastResultAt\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,\"secondary\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"rangeId\",\"initial\"],[[32,0,[\"option\"]],[32,0,[\"project\",\"lastResultAt\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"relative-time-range\",\"time-range\",\"concat\",\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/controls/relative-time-window/option-item/template.hbs"
    }
  });

  _exports.default = _default;
});