define("flood/utils/resolution-for-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = resolutionForDuration;
  // duration: minutes
  // resolution: seconds
  var durationMapping = [{
    duration: 1080,
    resolution: 1800
  }, {
    duration: 720,
    resolution: 900
  }, {
    duration: 540,
    resolution: 600
  }, {
    duration: 270,
    resolution: 300
  }, {
    duration: 90,
    resolution: 120
  }, {
    duration: 30,
    resolution: 60
  }];

  function resolutionForDuration(_ref) {
    var duration = _ref.duration,
        _ref$maxResolution = _ref.maxResolution,
        maxResolution = _ref$maxResolution === void 0 ? 15 : _ref$maxResolution,
        _ref$defaultResolutio = _ref.defaultResolution,
        defaultResolution = _ref$defaultResolutio === void 0 ? 15 : _ref$defaultResolutio;
    if (!duration) return;
    if (duration < 6 * 60e3 && maxResolution) return maxResolution;
    var defaultOption = {
      duration: 0,
      resolution: defaultResolution
    };
    return [].concat(durationMapping, [defaultOption]).filter(function (_ref2) {
      var threshold = _ref2.duration;
      return duration >= threshold * 60e3;
    })[0].resolution;
  }
});