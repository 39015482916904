define("flood/components/account/integration-form/saml/provider-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FrRHfJ0h",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"flood-ui/form\",[],[[\"@data\",\"@onSubmit\"],[[32,0,[\"formData\"]],[30,[36,0],[[32,0],\"closePopup\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[14,0,\"mdc-typography\"],[12],[1,[30,[36,1],[\"integrations.samlp.secondPopup.description\"],null]],[13],[2,\"\\n  \"],[10,\"label\"],[12],[1,[30,[36,1],[\"integrations.samlp.secondPopup.callbackURL\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"data-field\"],[12],[2,\"\\n    \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@disabled\"],[\"callbackURL\",true]],null],[2,\"\\n    \"],[8,\"copy-button\",[[24,0,\"copy-btn\"]],[[\"@clipboardText\",\"@title\"],[[32,0,[\"callbackURL\"]],\"Copy to clipboard\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"material-icons md-32\"],[12],[2,\"file_copy\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"label\"],[12],[1,[30,[36,1],[\"integrations.samlp.secondPopup.entityID\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"data-field\"],[12],[2,\"\\n    \"],[8,[32,1,[\"input\"]],[],[[\"@propertyName\",\"@disabled\"],[\"entityID\",true]],null],[2,\"\\n    \"],[8,\"copy-button\",[[24,0,\"copy-btn\"]],[[\"@clipboardText\",\"@title\"],[[32,0,[\"entityID\"]],\"Copy to clipboard\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"material-icons md-32\"],[12],[2,\"file_copy\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"Actions\"],[12],[2,\"\\n    \"],[8,[32,1,[\"submit\"]],[],[[\"@label\"],[[30,[36,1],[\"integrations.samlp.secondPopup.done\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "flood/components/account/integration-form/saml/provider-form/template.hbs"
    }
  });

  _exports.default = _default;
});