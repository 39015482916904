define("flood/templates/account/orgs/org/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CDrsh1Tm",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flud-ui/loader/logo\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "flood/templates/account/orgs/org/loading.hbs"
    }
  });

  _exports.default = _default;
});