define("flood/validators/max-grid-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountLimitValidation = accountLimitValidation;

  function accountLimitValidation() {
    var _this = this;

    var accountPredicate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
    return function (key, newValue, oldValue, changes, content) {
      var account = content.get('account');
      return accountPredicate.apply(_this, [account, newValue, changes, content]);
    };
  }
});