define("flood/templates/projects/project/flood/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "08JmGG/j",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flood-explorer/flood-status\",[],[[\"@flood\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "flood/templates/projects/project/flood/index.hbs"
    }
  });

  _exports.default = _default;
});