define("flood/serializers/grid", ["exports", "flood/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      status: {
        serialize: false
      },
      name: {
        serialize: false
      },
      nodes: {
        serialize: false
      },
      remoteErrors: {
        serialize: false
      },
      checks: {
        serialize: false,
        deserialize: true,
        key: 'status-checks'
      },
      streamId: {
        serialize: false
      },
      awsVpcSecurityGroup: {
        serialize: true,
        key: 'aws-vpc-security-groups'
      },
      account: {
        serialize: false
      },
      user: {
        serialize: false
      },
      floods: {
        serialize: false
      },
      percentComplete: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});