define("flood/components/flood-explorer/controls/grid-selector/selected-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F2xyZZgf",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"option\",\"uuid\"]],\"all\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,0,[\"option\",\"region\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[30,[36,0],[[32,0,[\"regions\"]],[32,0,[\"option\",\"provider\"]]],null],[32,0,[\"option\",\"region\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "flood/components/flood-explorer/controls/grid-selector/selected-item/template.hbs"
    }
  });

  _exports.default = _default;
});